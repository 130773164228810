<div class="Unity-body"  *ngIf="unityForm.productForm.adhereToTheSectoralAgreement">
  <!-- FORM -->
  <div class="Unity-form">
    <!-- spirits type section -->
    <div class="Unity-form-section row" *ngIf="isSpi">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Spiritueux</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <div class="Unity-form-input">
              <label for="typeOfSpirits">Type de spiritueux <span
                class="Unity-form-input--required">(obligatoire)</span></label>
              <div class="row">
                <div class="Unity-form-input-gtin--prefix col-xs-3 text-center ">
                  <select name="typeOfSpirits" id="typeOfSpirits"
                          required
                          [(ngModel)]="unityForm.productForm.spiritCodeGpc">
                    <option value=""></option>
                    <option *ngFor="let spirit of unityForm.spirits"
                            [ngValue]="spirit.code">
                      {{spirit.wording}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="Unity-form-input mt-3 col-md-12 col-xs-122">
            <div class="Unity-form-input">
              <label>Style de spiritueux </label>
              <div class="row">
                <div class="Unity-form-input-gtin--prefix col-xs-3 text-center ">
                  <select name="spiritStyle" id="spiritStyle"
                          [(ngModel)]="unityForm.productForm.spiritStyle">
                    <option value=""></option>
                    <option *ngFor="let spiritStyle of unityForm.spiritStyles"
                            [ngValue]="spiritStyle.code">
                      {{spiritStyle.wording}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Product -->
    <div class="Unity-form-section row" *ngIf="isWine">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Produit</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-6 col-xs-12">

            <label for="vintageName">Nom de cuvée</label>
            <input id="vintageName" maxlength="200"
                   [(ngModel)]="unityForm.productForm.vintageName"
                   name="vintageName" #name="ngModel"
                   type="text"
                   placeholder="{{unityForm.constants.createForm.placeholders.vintageName}}"
                   cleanInput>
            <div class="Unity-form-infoText">{{200 - unityForm.productForm.vintageName.length}}/200
              {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Origin Country -->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Origine</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-6 col-xs-12">
            <label for="country">Pays d'origine <span
              class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.country.title}}"
                 popover="{{unityForm.constants.inputsInfo.country.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-xs-6 text-center ">
                <select name="country" id="country" required
                        [(ngModel)]="unityForm.productForm.productCountry">
                  <option *ngFor="let country of unityForm.countries"
                          [ngValue]="country.code">{{country.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--wine color-->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12" *ngIf="showIG()">
            <label>Signe d'indication géographique
              <span class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-4 col-xs-6 text-center ">
                <select name="gis" id="gis"
                        [required]="showIG()"
                        [(ngModel)]="unityForm.productForm.gis">
                  <option value=""></option>
                  <option *ngFor="let gis of gisCodes"
                          [ngValue]="gis.code">{{gis.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- AOC -->
          <div class="Unity-form-input  mt-3 col-md-6 col-xs-12"
               *ngIf="showAOC()">
            <label>Indication géographique
              <span
                class="Unity-form-input--required"
                *ngIf="showAOC()">(obligatoire)</span></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.aoc.title}}"
                 popover="{{unityForm.constants.inputsInfo.aoc.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-12 col-xs-12 text-center ">
                <ng-selectize name="aoc" [config]="aocSelectConfig" style="text-transform: uppercase;"
                              [options]="aocSelectOptions"
                              [(ngModel)]="unityForm.productForm.aoc"
                              ngDefaultControl></ng-selectize>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Specific section -->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Sp&eacute;cificit&eacute;s</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <!--Main-->
          <!--age of the spirit-->
          <div class="Unity-form-input col-md-12 col-xs-12"
               *ngIf="isSpi">
            <label>&Acirc;ge du spiritueux</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.ageOfSpirit.title}}"
                 popover="{{unityForm.constants.inputsInfo.ageOfSpirit.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="Unity-form-input-vcs row">
              <div class="col-md-2 col-xs-5">
                <select name="ageOfSpirit" id="ageOfSpirit"
                        [(ngModel)]="unityForm.productForm.ageOfSpirit">
                  <option value=""></option>
                  <option *ngFor="let nbYear of nbYears"
                          [ngValue]="nbYear">{{nbYear}}
                  </option>
                </select>
              </div>
              <div class="col-md-2 col-xs-2">Ans</div>
            </div>
          </div>
          <!--vintage section-->
          <div [class.mt-3]="isSpi"
               class="Unity-form-input col-md-12 col-xs-12">
            <label for="vintage">Millésime <span
              class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="vintage" id="vintage"
                        [required]="true"
                        [(ngModel)]="unityForm.productForm.vintage">
                  <option value=""></option>
                  <option *ngFor="let vintage of vintageSvc.vintages"
                          [ngValue]="vintage.code">{{vintage.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--wine color-->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
               *ngIf="isWine">
            <label>Couleur du vin
              <span class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="wineColor" id="wineColor"
                        [required]="isWine"
                        [(ngModel)]="unityForm.productForm.wineColor">
                  <option value=""></option>
                  <option *ngFor="let wineColor of unityForm.wineColorCodes"
                          [ngValue]="wineColor.code">{{wineColor.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
               [ngClass]="'formError-' + unityForm.productForm.alcoholDegreeError.status">
            <label for="specific">Quantit&eacute; d'alcool <span
              class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.alcoholDegree.title}}"
                 popover="{{unityForm.constants.inputsInfo.alcoholDegree.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="Unity-form-input-vcs row">
              <div class="col-md-2 col-xs-5">
                <input id="specific" type="text" maxlength="4"
                       [(ngModel)]="unityForm.productForm.alcoholDegree"
                       (ngModelChange)="unityForm.onAlcoholDegreeChange($event)"
                       pattern="{{unityForm.DECIMAL_PATTERN}}"
                       placeholder="{{unityForm.constants.createForm.placeholders.alcoholDegree}}"
                       name="specific"
                       required>
                <div class="Unity-form-input-vcs-valid">
                  <img src="assets/icons/error.svg" alt="X"
                       [hidden]="unityForm.productForm.alcoholDegreeError.status !== 'warning'">
                  <img src="assets/icons/ok.svg" alt="OK"
                       [hidden]="unityForm.productForm.alcoholDegreeError.status !== 'valid'">
                </div>
              </div>
              <div class="col-md-2 col-xs-2 label-volume-unit">% Vol</div>
            </div>
          </div>
          <!--container-->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12">
            <label for="container">Format de contenant
              <span class="Unity-form-input--required">(obligatoire)</span></label>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="container" id="container"
                        [(ngModel)]="unityForm.productForm.container"
                        required>
                  <option value=""></option>
                  <option *ngFor="let container of containerCodes"
                          [ngValue]="container.code">{{container.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!--sugar content -->
          <div class="Unity-form-input mt-3 col-md-12 col-xs-12"
               *ngIf="unityForm.isProductSparklingOrStillWine()">
            <label>Mention relative à la teneur en sucre
              <span
                class="Unity-form-input--required"
                *ngIf="unityForm.isProductSparklingWine()">(obligatoire)</span></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.sugarContent.title}}"
                 popover="{{unityForm.constants.inputsInfo.sugarContent.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="row">
              <div class="Unity-form-input-gtin--prefix col-md-2 col-xs-6 text-center ">
                <select name="sugarContent" id="sugarContent"
                        [(ngModel)]="unityForm.productForm.sugarContent"
                        [required]="unityForm.isProductSparklingWine()">
                  <option value=""></option>
                  <option *ngFor="let item of unityForm.sugarContentConstants"
                          [ngValue]="item.code">{{item.wording}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- grapes-->
    <div class="Unity-form-section row" *ngIf="isWine">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Cépages</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Cépages</label>
            <ng-selectize name="grapes" [config]="multiSelectConfig" [options]="multiSelectOptions"
                          [placeholder]=unityForm.constants.createForm.placeholders.grapes
                          [(ngModel)]="unityForm.productForm.varietals"
                          ngDefaultControl></ng-selectize>
          </div>
        </div>
      </div>
    </div>

    <!--Allergen section-->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Allergènes</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <!--subsection VCS-->
        <div class="Unity-form-subSection row">
          <!--sulfit-->
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Contient des sulfites</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.allergen.AU.title}}"
                 popover="{{unityForm.constants.inputsInfo.allergen.AU.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="radio">
              <label><input type="radio" name="sulfit" [value]=true
                            [(ngModel)]="unityForm.productForm.allergen.AU" required>Oui</label>
            </div>
            <div class="radio">
              <label><input type="radio" name="sulfit"
                            [(ngModel)]="unityForm.productForm.allergen.AU"
                            [value]=false required>Non</label>
            </div>
          </div>
          <!--oeuf-->
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Contient de l'œuf ou dérivé</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.allergen.AE.title}}"
                 popover="{{unityForm.constants.inputsInfo.allergen.AE.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="radio">
              <label><input type="radio" name="oeuf"
                            [value]=true [(ngModel)]="unityForm.productForm.allergen.AE"
                            required>Oui</label>
            </div>
            <div class="radio">
              <label><input type="radio" name="oeuf"
                            [value]=false [(ngModel)]="unityForm.productForm.allergen.AE"
                            required>Non</label>
            </div>
          </div>
          <!--lait-->
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Contient du lait ou dérivé></label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.allergen.AM.title}}"
                 popover="{{unityForm.constants.inputsInfo.allergen.AM.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="radio">
              <label><input type="radio" name="lait"
                            [value]=true [(ngModel)]="unityForm.productForm.allergen.AM"
                            required>Oui</label>
            </div>
            <div class="radio">
              <label><input type="radio" name="lait"
                            [value]=false [(ngModel)]="unityForm.productForm.allergen.AM"
                            required>Non</label>
            </div>
          </div>
        </div>
        <!--subsection SPI-->
        <div *ngIf="isSpi"
             class="Unity-form-subSection row">
          <!--gluten-->
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Contient Céréale contenant du gluten</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.allergen.AW.title}}"
                 popover="{{unityForm.constants.inputsInfo.allergen.AW.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="radio">
              <label><input type="radio" name="gluten" [value]=true
                            [(ngModel)]="unityForm.productForm.allergen.AW" required>Oui</label>
            </div>
            <div class="radio">
              <label><input type="radio" name="gluten"
                            [(ngModel)]="unityForm.productForm.allergen.AW"
                            [value]=false required>Non</label>
            </div>
          </div>
          <!--crustacé-->
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Contient des crustacés</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.allergen.UN.title}}"
                 popover="{{unityForm.constants.inputsInfo.allergen.UN.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="radio">
              <label><input type="radio" name="crustace"
                            [value]=true [(ngModel)]="unityForm.productForm.allergen.UN"
                            required>Oui</label>
            </div>
            <div class="radio">
              <label><input type="radio" name="crustace"
                            [value]=false [(ngModel)]="unityForm.productForm.allergen.UN"
                            required>Non</label>
            </div>
          </div>
          <!--arachide-->
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Contient des arachides et produits à base d'arachides</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.allergen.AP.title}}"
                 popover="{{unityForm.constants.inputsInfo.allergen.AP.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="radio">
              <label><input type="radio" name="arachide"
                            [value]=true [(ngModel)]="unityForm.productForm.allergen.AP"
                            required>Oui</label>
            </div>
            <div class="radio">
              <label><input type="radio" name="arachide"
                            [value]=false [(ngModel)]="unityForm.productForm.allergen.AP"
                            required>Non</label>
            </div>
          </div>
          <!--fruit à coque-->
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Contient des fruits à coque</label>
            <div class="icon-help"
                 popoverTitle="{{unityForm.constants.inputsInfo.allergen.AN.title}}"
                 popover="{{unityForm.constants.inputsInfo.allergen.AN.content}}"
                 placement="right"
                 triggers="mouseenter:mouseleave">
            </div>
            <div class="radio">
              <label><input type="radio" name="fruitCoque"
                            [value]=true [(ngModel)]="unityForm.productForm.allergen.AN"
                            required>Oui</label>
            </div>
            <div class="radio">
              <label><input type="radio" name="fruitCoque"
                            [value]=false [(ngModel)]="unityForm.productForm.allergen.AN"
                            required>Non</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Certification et Labels section-->
    <div class="Unity-form-section row">
      <div class="Unity-form-section-name col-md-12 col-xs-14">Labels et Certifications</div>
      <div class="Unity-form-section-content col-md-10 col-xs-8">
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Certifications</label>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="AGRICULTURE_BIOLOGIQUE" value=true
                       [(ngModel)]="unityForm.productForm.certifications.AGRICULTURE_BIOLOGIQUE">
                Agriculture biologique
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="HAUTE_VALEUR_ENVIRONNEMENTALE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.HAUTE_VALEUR_ENVIRONNEMENTALE">
                Haute Valeur Environnementale
              </label>
            </div>

          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="DEMETER_LABEL"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.DEMETER_LABEL">
                Demeter
              </label>
            </div>

          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="EUROFEUILLE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.EUROFEUILLE">
                Eurofeuille
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="TERRA_VITIS"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.TERRA_VITIS">
                Terra Vitis
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="VITICULTURE_DURABLE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.VITICULTURE_DURABLE">
                Viticulture durable
              </label>
            </div>
          </div>
          <div class="mt-3 col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9  mt-1">
                            <span class="fw-normal">Autre(s)
                               <input type="text" name="AUTRE_CERTIF"
                                      value=true [(ngModel)]="unityForm.productForm.certifications.AUTRE_CERTIF">
                            </span>
          </div>

        </div>
        <div class="Unity-form-subSection row">
          <div class="Unity-form-input col-md-12 col-xs-12">
            <label>Labels</label>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="BIODYVIN"
                       value=true [(ngModel)]="unityForm.productForm.certifications.BIODYVIN">
                Biodyvin
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="ECOCERT_CERTIFICATE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.ECOCERT_CERTIFICATE">
                Ecocert
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="NATURE_ET_PROGRES"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.NATURE_ET_PROGRES">
                Nature et Progrès
              </label>
            </div>

          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="ORIGINE_FRANCE_GARANTIE"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.ORIGINE_FRANCE_GARANTIE">
                Origine France Garantie
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="VIN_NATUREL"
                       value=true
                       [(ngModel)]="unityForm.productForm.certifications.VIN_NATUREL">
                Vin naturel
              </label>
            </div>
          </div>
          <div class="col-md-1 col-xs-3"></div>
          <div class="col-md-11 col-xs-9 mt-1">
                            <span class="fw-normal">Autre(s)
                              <input type="text" name="AUTRE_LABEL"
                                     value=true [(ngModel)]="unityForm.productForm.certifications.AUTRE_LABEL">
                            </span>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
