<div class="col-sm-12 warning-box" id="warningBox">
    <div class="title">
        <img src="assets/icons/info-circle.svg" alt="titre">
        <span>{{this.title}}</span>
    </div>

        <div class="body" >
            <ng-content></ng-content>
        </div>

</div>
