<div *ngIf="featureToggleSvc.isWhalesUser()" class="header">
  <div class="modal-title"></div>
  <div class="header-menu-links">
    <div class="col-md-12 headerButtonsContainer centerBlock">
      <button *ngIf="!isGtin8()" type="button" class="header-menu-button barcode-btn" (click)="generateBarcode()">
        <img src="assets/icons/upc.svg" alt="Créer code-barres"
             tooltip="Créer le codes-barres"><span>Créer le code-barres</span>
      </button>
      <!--        <button type="button" class="header-menu-button qrcode-btn" (click)="openQRcodeModal()">-->
      <!--          <img src="assets/icons/qr-code.svg" alt="Créer QR Code"-->
      <!--               tooltip="Créer le QR Code"><span>Créer le QR Code</span>-->
      <!--        </button>
      <qr-code-creation></qr-code-creation>
      -->

    </div>

    <div class="col-md-3 visibilityContainer"
         *ngIf="featureToggleSvc.canSeeChangeVisibility() && !productsSvc.isRiskyProduct(productForm.productResellerType)">
      <div class="icon-help pull-right"
           popoverTitle="{{constants.inputsInfo.productVisibilityView.title}}"
           popover="{{constants.inputsInfo.productVisibilityView.content}}"
           placement="left"
           triggers="mouseenter:mouseleave">
      </div>
      <ui-switch class="pull-right" name="productVisibility" [checked]="!productForm.isPrivate"
                 (valueChange)="onVisibilityChange($event)">
      </ui-switch>

      <div *ngIf="!productForm.isPrivate" class="pull-right visibilityState">GTIN publié</div>
      <div *ngIf="productForm.isPrivate" class="pull-right visibilityState">GTIN non publié</div>
    </div>
  </div>
</div>
<div *ngIf="featureToggleSvc.isWhalesUser()" class="defineScrollbar">
  <div class="container col-md-11 col-md-offset-1">
    <div class="contentHeaderContainer col-md-12 negatePaddingLeft"
         [className]="imagesToDisplay[0] ? 'contentHeaderContainer col-md-12 negatePaddingLeft hasImg' : 'contentHeaderContainer col-md-12 negatePaddingLeft'">
      <div class="leftContainer col-md-5">
        <div class="product-buttons">
          <button type="button" class="product-button" (click)="editProduct()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
              <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>
            <span>Modifier</span>
          </button>
          <button type="button" class="product-button" (click)="shareCIP()" [disabled]="productForm.isPrivate">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
              <path
                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
            </svg>
            <span>Partager</span>
          </button>
          <button type="button" class="product-button" (click)="confirmationModal.openModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
            <span>Supprimer</span>
          </button>
        </div>
        <div class="imgContainer" *ngIf="showSubSection(extractUrlFromImage())">
          <div class="gradientImgContainer">
            <div class="imgCarousel" *ngIf="showSubSection(extractUrlFromImage())">
              <carousel>
                <slide *ngFor="let image of imagesToDisplay">
                  <div class="carousel-image">
                    <img src="{{image['url']}}">
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
        <div class="imgContainer" *ngIf="!showSubSection(extractUrlFromImage())">
          <div class="gradientImgContainer noPictureProduct">
            <img src="/assets/img/nopicture.png"/>
          </div>
        </div>
      </div>
      <div class="rightContainer col-md-6">
        <div class="titleContainer">
          <div class="textTitle">
            {{productForm.getProductName(languageProductName)}}
          </div>
          <div>
                        <span *ngFor="let lang of productForm.getProductNamesLanguage();index as index;">
                            <span [className]="languageProductName === lang ? 'lang-section selected-lang' :
                            'lang-section'" (click)="languageProductName = lang">
                                {{lang}}</span>
                            <span *ngIf="productForm.getProductNamesLanguage().length > 1 &&
                            index !== productForm.getProductNamesLanguage().length - 1"> | </span>
                        </span>

          </div>
        </div>
        <div class="categoryContainer">
          <div class="textInfo">
            Catégorie
          </div>
          <div class="textCategory textValue">
            {{productForm.category}}
          </div>
        </div>
        <div class="gtinContainer valueContainer">
          <div class="textInfo gtinLabel">
            GTIN (Global Trade Item Number)
          </div>
          <div class="textCategory textValue">
            {{productForm.gtin.toString()}}
            <button
              class="copyGtinBtn"
              #btn
              (click)="clickCopyGtin($event, btn)"
              type="button"
              ngxClipboard
              [cbContent]="productForm.gtin.toString()"
              tooltip="{{ copyGtinToolTip }}"
              placement="right">
              <img src="assets/icons/icon-copy.png"/>
            </button>
          </div>
        </div>
        <div class="valueContainer">
          <div class="textInfo gtinInfo">
            PAYS DE VENTE
          </div>
          <div class="textCategory textValue">
            <span class="gtinValue">{{constructTargetMarketWording()}}</span>
          </div>
        </div>
        <div class="brandContainer valueContainer">
          <div class="textInfo">
            Marque(s)
          </div>
          <div class="textBrand textValue" *ngIf="!cannotSeeBrand(productForm)">
            {{productForm.brand}} {{productForm.subBrand}}
          </div>

          <div class="textBrand textValue" *ngIf="cannotSeeBrand(productForm)">
            SANS MARQUE
          </div>
        </div>
        <div class="legalNameContainer valueContainer">
          <div class="textInfo">
            Dénomination légale de vente
          </div>
          <div class="textLegalName textValue">
            {{productForm.regulatedProductName}}
          </div>
        </div>
        <div class="netContentContainer valueContainer">
          <div class="textInfo">
            Contenu net
          </div>
          <div class="textNetContent textValue">
            {{constructNetContentWording()}}
          </div>
        </div>
        <div class="dryWeightContainer valueContainer" *ngIf="constructDrainedWeightWording() !== ''">
          <div class="textInfo">
            Poids net égouté
          </div>
          <div class="textValue">
            {{constructDrainedWeightWording()}}
          </div>
        </div>
        <div class="alcoholContainer valueContainer" *ngIf="productForm.alcoholDegree !== null">
          <div class="textInfo">
            % d'alcool par volume
          </div>
          <div class="textValue">
            {{productForm.alcoholDegree}} %
          </div>
        </div>
      </div>
    </div>
    <div class="compositionContainer col-md-12 negatePaddingLeft">
      <div class="leftContainer col-md-4" *ngIf="productForm.ingredientStatement.length !== 0">
        <div class="rowTitle">
          Ingrédients
        </div>
        <div class="ingredientsList">
          {{productForm.ingredientStatement}}
        </div>
      </div>
      <div class="middleContainer col-md-4">
        <div class="rowTitle">
          Régimes & allergènes
        </div>
        <div class="preAllergenList" *ngIf="productDiet.length !== 0">
          Produit <span class="dietType" *ngFor="let diet of productDiet">{{diet.wording}}</span>
        </div>
        <div class="stateContainer" *ngIf="constructContainsAllergenWording().length !== 0">
          <strong>Contient:</strong>
          <span class="allergenText" *ngFor="let allergen of constructContainsAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
        <div class="stateContainer" *ngIf="constructMayContainsAllergenWording().length !== 0">
          <strong>Peut contenir:</strong>
          <span class="allergenText" *ngFor="let allergen of constructMayContainsAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
        <div class="stateContainer" *ngIf="constructFreeFromAllergenWording().length !== 0">
          <strong>Ne contient pas:</strong>
          <span class="allergenText" *ngFor="let allergen of constructFreeFromAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
        <div class="stateContainer" *ngIf="constructUndeclaredAllergenWording().length !== 0">
          <strong>Non déclaré:</strong>
          <span class="allergenText" *ngFor="let allergen of constructUndeclaredAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
      </div>
      <div class="rightContainer col-md-4">
        <div class="rowTitle" *ngIf="productForm.nutriScore && productForm.nutriScore.length !== 0">
          Nutri-score
        </div>
        <div class="nutriScoreSelector" *ngIf="productForm.nutriScore && productForm.nutriScore.length !== 0">
          <div class="nutriA" [ngClass]="productForm.nutriScore === 'A' ? 'sel' : ''">
            <div class="nutriText">A</div>
          </div>
          <div class="nutriB" [ngClass]="productForm.nutriScore === 'B' ? 'sel' : ''">
            <div class="nutriText">B</div>
          </div>
          <div class="nutriC" [ngClass]="productForm.nutriScore === 'C' ? 'sel' : ''">
            <div class="nutriText">C</div>
          </div>
          <div class="nutriD" [ngClass]="productForm.nutriScore === 'D' ? 'sel' : ''">
            <div class="nutriText">D</div>
          </div>
          <div class="nutriE" [ngClass]="productForm.nutriScore === 'E' ? 'sel' : ''">
            <div class="nutriText">E</div>
          </div>
        </div>
      </div>
    </div>
    <div class="nutriValuesContainer col-md-12 negatePaddingLeft">
      <div class="rowTitle">
        Valeurs Nutrionnelles
      </div>
      <div class="col-md-6 negatePaddingLeft">
        <div class="textInfo">
          Valeurs nutritionnelles
        </div>

        <div class="textValue">
          {{constructPreparationStateWording()}}
        </div>
      </div>

      <div class="nutrimentsTableContainer">
        <table class="nutrimentsTable"
               [className]="servingSizeDescription ? 'nutrimentsTable col-md-12' : 'nutrimentsTable col-md-6'">
          <thead>
          <tr>
            <th class="col-md-3 textCenter">
            </th>
            <th class="col-md-3 textCenter">
              Pour {{productForm.nutriment.servingSize}}{{findMeasurementShortUnitWording(productForm.nutriment.measurementUnitCode)}}
            </th>
            <th class="col-md-3 textCenter" *ngIf="servingSizeDescription">
              Pour "{{servingSizeDescription}}"
            </th>
            <th class="col-md-3 textCenter" *ngIf="servingSizeDescription">
              {{dailyValueIntakeReference}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let nutriment of productForm.nutriment.items">
            <td
              class="col-md-3">{{findNutrimentWording(nutriment.nutrientTypeCode, nutriment.measurementUnitCode)}}</td>
            <td class="col-md-3">{{nutriment.quantityContained}} <span
              class="unit">{{findMeasurementShortUnitWording(nutriment.measurementUnitCode)}}</span>
            </td>
            <td class="col-md-3" *ngIf="servingSizeDescription">{{nutriment.optionalQuantityContained}}
              <span class="unit">{{findMeasurementShortUnitWording(nutriment.measurementUnitCode)}}</span>
            </td>
            <td class="col-md-3" *ngIf="servingSizeDescription">{{getDailyValueIntakeReference(nutriment)}}
              %
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="complementaryInfosContainer col-md-12 negatePaddingLeft"
         *ngIf="productForm.consumerUsageInstructions || productForm.packagingMarkedLabelAccreditationCode.length>0
                || productForm.preparationInstructions ||productForm.consumerStorageInstructions">
      <div class="leftContainer col-md-4"
           *ngIf="productForm.consumerUsageInstructions || productForm.packagingMarkedLabelAccreditationCode.length>0">
        <div class="informationBlock" *ngIf="productForm.consumerUsageInstructions">
          <div class="rowTitle">Conditions d'utilisation</div>
          <div class="valueContainer">
            <div class="textValue">{{productForm.consumerUsageInstructions}}</div>
          </div>
        </div>
        <div class="informationBlock"
             *ngIf="productForm.packagingMarkedLabelAccreditationCode.length>0">
          <div class="rowTitle">
            Sigles et/ou accréditations
          </div>
          <div class="valueContainer">
            <div
              class="textValue">{{findListWording(packagingMarkedLabelAccreditationCodeOptions, productForm.packagingMarkedLabelAccreditationCode)}}</div>
          </div>
        </div>
      </div>
      <div class="middleContainer col-md-4" *ngIf="productForm.preparationInstructions ||productForm.webLink ">
        <div class="informationBlock" *ngIf="productForm.preparationInstructions">
          <div class="rowTitle">Instructions de préparation</div>
          <div class="valueContainer">
            <div class="textValue">{{productForm.preparationInstructions}}</div>
          </div>
        </div>
        <div class="informationBlock" *ngIf="productForm.webLink">
          <div class="rowTitle">
            Url du fichier externe
          </div>
          <div class="textValue">
            <a href="{{productForm.webLink}}" target="_blank">{{productForm.webLink}}</a>
          </div>
        </div>
      </div>
      <div class="rightContainer col-md-4" *ngIf="productForm.consumerStorageInstructions">
        <div class="informationBlock">
          <div class="rowTitle">Conditions particulières de conservation</div>
          <div class="valueContainer">
            <div class="textValue">{{productForm.consumerStorageInstructions}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="gtinManagerContainer col-md-12 negatePaddingLeft"
         *ngIf="productForm.structuredAddress.streetAddress !== ''">
      <div class="leftContainer col-md-4">
        <div class="managerNameContainer col-md-12">
          <div class="rowTitle">
            Nom de l'exploitant
          </div>
          <div class="textValue">
            {{productForm.contactName}}
          </div>
        </div>
      </div>
      <div class="middleRightContainer col-md-8">
        <div class="informationsRow col-md-12">
          <div class="rowTitle">
            Adresse de l'exploitant
          </div>
          <div class="textValue">
            {{productForm.fullAddress}}
          </div>
        </div>
      </div>
    </div>
    <div class="gtinOwnerContainer col-md-12 negatePaddingLeft">
      <div class="valuesContainer">
        <div class="leftContainer row negatePaddingLeft">
          <div class="ownerNameContainer col-md-12">
            <div class="bigTextInfo">
              Les droits d'utilisation du GTIN sont détenus par :
              <div class="ownerName">{{companySvc.company.companyName}}</div>
            </div>
          </div>
        </div>
        <div class="rightContainer row">
          <div class="ownerAdressContainer col-md-6">
            <div class="littleTextInfo">
              Adresse
            </div>
            <!--                        <div class="textValue">{{constructStructuredAddressWording()}}</div>-->
            <div
              class="textValue">{{companySvc.company.street}} {{companySvc.company.city}} {{companySvc.company.cp}} </div>
          </div>
          <div class="glnContainer col-md-6">
            <div class="littleTextInfo">Global Location Number (GLN)</div>
            <div class="textValue">{{companySvc.company.gln}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--  whales user -->


<div *ngIf="!featureToggleSvc.isWhalesUser()" class="header">
  <div class="modal-title"></div>
  <div class="header-menu-links">
    <div class="col-md-12 headerButtonsContainer centerBlock">
      <button *ngIf="!isGtin8()" type="button" class="header-menu-button barcode-btn" (click)="generateBarcode()">
        <img src="assets/icons/generateCodeWhite.svg" alt="Créer code-barres"
             tooltip="Créer le codes-barres"><span>Créer le code-barres</span>
      </button>
      <button type="button" class="header-menu-button" (click)="editProduct()">
        <img src="assets/icons/paperpencil.svg" alt="Modifier" tooltip="Modifier">
        <span>Modifier</span>
      </button>
      <button type="button" class="header-menu-button" (click)="shareCIP()" [disabled]="productForm.isPrivate">
        <img src="assets/icons/shareOrange.svg" alt="Partager la carte d'identité de produit"
             tooltip="Partager la carte d'identité de produit">
        <span>Partager</span>
      </button>
      <button type="button" class="header-menu-button headerDeleteBtn" (click)="confirmationModal.openModal()">
        <img src="assets/icons/supprimer.svg" alt="Supprimer" tooltip="Supprimer">
        <span>Supprimer</span>
      </button>
    </div>

    <div class="col-md-3 visibilityContainer"
         *ngIf="featureToggleSvc.canSeeChangeVisibility() && !productsSvc.isRiskyProduct(productForm.productResellerType)">
      <div class="icon-help pull-right"
      matTooltip="Publier ce produit rend visible ses informations clés aux adhérents ayant accès aux registres GS1." matTooltipClass="mat-tooltip-bottom-arrow-top-right"

           >
           <!-- popoverTitle="{{constants.inputsInfo.productVisibility.title}}"
           popover="{{constants.inputsInfo.productVisibility.content}}"
           placement="left"
           triggers="mouseenter:mouseleave"


          -->
      </div>
      <ui-switch class="pull-right" name="productVisibility" [checked]="!productForm.isPrivate"
                 (valueChange)="onVisibilityChange($event)">
      </ui-switch>

      <div *ngIf="!productForm.isPrivate" class="pull-right visibilityState">GTIN publié</div>
      <div *ngIf="productForm.isPrivate" class="pull-right visibilityState">GTIN non publié</div>
    </div>
  </div>
</div>
<div *ngIf="!featureToggleSvc.isWhalesUser()" class="defineScrollbar">
  <div class="container col-md-11 col-md-offset-1">
    <div class="contentHeaderContainer col-md-12 negatePaddingLeft"
         [className]="imagesToDisplay[0] ? 'contentHeaderContainer col-md-12 negatePaddingLeft hasImg' : 'contentHeaderContainer col-md-12 negatePaddingLeft'">
      <div class="leftContainer col-md-5">
        <div class="imgContainer" *ngIf="showSubSection(extractUrlFromImage())">
          <div class="gradientImgContainer">
            <div class="imgCarousel" *ngIf="showSubSection(extractUrlFromImage())">
              <carousel>
                <slide *ngFor="let image of imagesToDisplay">
                  <div class="carousel-image">
                    <img src="{{image['url']}}">
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
        <div class="imgContainer" *ngIf="!showSubSection(extractUrlFromImage())">
          <div class="gradientImgContainer noPictureProduct">
            <img src="/assets/img/nopicture.png"/>
          </div>
        </div>
      </div>
      <div class="rightContainer col-md-6">
        <div class="titleContainer">
          <div class="textTitle">
            {{productForm.getProductName(languageProductName)}}
          </div>
          <div>
                        <span *ngFor="let lang of productForm.getProductNamesLanguage();index as index;">
                            <span [className]="languageProductName === lang ? 'lang-section selected-lang' :
                            'lang-section'" (click)="languageProductName = lang">
                                {{lang}}</span>
                            <span *ngIf="productForm.getProductNamesLanguage().length > 1 &&
                            index !== productForm.getProductNamesLanguage().length - 1"> | </span>
                        </span>

          </div>
        </div>
        <div class="categoryContainer">
          <div class="textInfo">
            Catégorie
          </div>
          <div class="textCategory textValue">
            {{productForm.category}}
          </div>
        </div>
        <div class="gtinContainer valueContainer">
          <div class="textInfo gtinLabel">
            GTIN (Global Trade Item Number)
          </div>
          <div class="textCategory textValue">
            {{productForm.gtin.toString()}}
            <button
              class="copyGtinBtn"
              #btn
              (click)="clickCopyGtin($event, btn)"
              type="button"
              ngxClipboard
              [cbContent]="productForm.gtin.toString()"
              tooltip="{{ copyGtinToolTip }}"
              placement="right">
              <img src="assets/icons/icon-copy.png"/>
            </button>
          </div>
        </div>
        <div class="valueContainer">
          <div class="textInfo gtinInfo">
            PAYS DE VENTE
          </div>
          <div class="textCategory textValue">
            <span class="gtinValue">{{constructTargetMarketWording()}}</span>
          </div>
        </div>
        <div class="brandContainer valueContainer">
          <div class="textInfo">
            Marque(s)
          </div>
          <div class="textBrand textValue" *ngIf="!cannotSeeBrand(productForm)">
            {{productForm.brand}} {{productForm.subBrand}}
          </div>

          <div class="textBrand textValue" *ngIf="cannotSeeBrand(productForm)">
            SANS MARQUE
          </div>
        </div>
        <div class="legalNameContainer valueContainer">
          <div class="textInfo">
            Dénomination légale de vente
          </div>
          <div class="textLegalName textValue">
            {{productForm.regulatedProductName}}
          </div>
        </div>
        <div class="netContentContainer valueContainer">
          <div class="textInfo">
            Contenu net
          </div>
          <div class="textNetContent textValue">
            {{constructNetContentWording()}}
          </div>
        </div>
        <div class="dryWeightContainer valueContainer" *ngIf="constructDrainedWeightWording() !== ''">
          <div class="textInfo">
            Poids net égouté
          </div>
          <div class="textValue">
            {{constructDrainedWeightWording()}}
          </div>
        </div>
        <div class="alcoholContainer valueContainer" *ngIf="productForm.alcoholDegree !== null">
          <div class="textInfo">
            % d'alcool par volume
          </div>
          <div class="textValue">
            {{productForm.alcoholDegree}} %
          </div>
        </div>
      </div>
    </div>
    <div class="compositionContainer col-md-12 negatePaddingLeft">
      <div class="leftContainer col-md-4" *ngIf="productForm.ingredientStatement.length !== 0">
        <div class="rowTitle">
          Ingrédients
        </div>
        <div class="ingredientsList">
          {{productForm.ingredientStatement}}
        </div>
      </div>
      <div class="middleContainer col-md-4">
        <div class="rowTitle">
          Régimes & allergènes
        </div>
        <div class="preAllergenList" *ngIf="productDiet.length !== 0">
          Produit <span class="dietType" *ngFor="let diet of productDiet">{{diet.wording}}</span>
        </div>
        <div class="stateContainer" *ngIf="constructContainsAllergenWording().length !== 0">
          <strong>Contient:</strong>
          <span class="allergenText" *ngFor="let allergen of constructContainsAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
        <div class="stateContainer" *ngIf="constructMayContainsAllergenWording().length !== 0">
          <strong>Peut contenir:</strong>
          <span class="allergenText" *ngFor="let allergen of constructMayContainsAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
        <div class="stateContainer" *ngIf="constructFreeFromAllergenWording().length !== 0">
          <strong>Ne contient pas:</strong>
          <span class="allergenText" *ngFor="let allergen of constructFreeFromAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
        <div class="stateContainer" *ngIf="constructUndeclaredAllergenWording().length !== 0">
          <strong>Non déclaré:</strong>
          <span class="allergenText" *ngFor="let allergen of constructUndeclaredAllergenWording()">
                            {{allergen}}
                    </span>
        </div>
      </div>
      <div class="rightContainer col-md-4">
        <div class="rowTitle" *ngIf="productForm.nutriScore && productForm.nutriScore.length !== 0">
          Nutri-score
        </div>
        <div class="nutriScoreSelector" *ngIf="productForm.nutriScore && productForm.nutriScore.length !== 0">
          <div class="nutriA" [ngClass]="productForm.nutriScore === 'A' ? 'sel' : ''">
            <div class="nutriText">A</div>
          </div>
          <div class="nutriB" [ngClass]="productForm.nutriScore === 'B' ? 'sel' : ''">
            <div class="nutriText">B</div>
          </div>
          <div class="nutriC" [ngClass]="productForm.nutriScore === 'C' ? 'sel' : ''">
            <div class="nutriText">C</div>
          </div>
          <div class="nutriD" [ngClass]="productForm.nutriScore === 'D' ? 'sel' : ''">
            <div class="nutriText">D</div>
          </div>
          <div class="nutriE" [ngClass]="productForm.nutriScore === 'E' ? 'sel' : ''">
            <div class="nutriText">E</div>
          </div>
        </div>
      </div>
    </div>
    <div class="nutriValuesContainer col-md-12 negatePaddingLeft">
      <div class="rowTitle">
        Valeurs Nutrionnelles
      </div>
      <div class="col-md-6 negatePaddingLeft">
        <div class="textInfo">
          Valeurs nutritionnelles
        </div>

        <div class="textValue">
          {{constructPreparationStateWording()}}
        </div>
      </div>

      <div class="nutrimentsTableContainer">
        <table class="nutrimentsTable"
               [className]="servingSizeDescription ? 'nutrimentsTable col-md-12' : 'nutrimentsTable col-md-6'">
          <thead>
          <tr>
            <th class="col-md-3 textCenter">
            </th>
            <th class="col-md-3 textCenter">
              Pour {{productForm.nutriment.servingSize}}{{findMeasurementShortUnitWording(productForm.nutriment.measurementUnitCode)}}
            </th>
            <th class="col-md-3 textCenter" *ngIf="servingSizeDescription">
              Pour "{{servingSizeDescription}}"
            </th>
            <th class="col-md-3 textCenter" *ngIf="servingSizeDescription">
              {{dailyValueIntakeReference}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let nutriment of productForm.nutriment.items">
            <td
              class="col-md-3">{{findNutrimentWording(nutriment.nutrientTypeCode, nutriment.measurementUnitCode)}}</td>
            <td class="col-md-3">{{nutriment.quantityContained}} <span
              class="unit">{{findMeasurementShortUnitWording(nutriment.measurementUnitCode)}}</span>
            </td>
            <td class="col-md-3" *ngIf="servingSizeDescription">{{nutriment.optionalQuantityContained}}
              <span class="unit">{{findMeasurementShortUnitWording(nutriment.measurementUnitCode)}}</span>
            </td>
            <td class="col-md-3" *ngIf="servingSizeDescription">{{getDailyValueIntakeReference(nutriment)}}
              %
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="complementaryInfosContainer col-md-12 negatePaddingLeft"
         *ngIf="productForm.consumerUsageInstructions || productForm.packagingMarkedLabelAccreditationCode.length>0
                || productForm.preparationInstructions ||productForm.consumerStorageInstructions">
      <div class="leftContainer col-md-4"
           *ngIf="productForm.consumerUsageInstructions || productForm.packagingMarkedLabelAccreditationCode.length>0">
        <div class="informationBlock" *ngIf="productForm.consumerUsageInstructions">
          <div class="rowTitle">Conditions d'utilisation</div>
          <div class="valueContainer">
            <div class="textValue">{{productForm.consumerUsageInstructions}}</div>
          </div>
        </div>
        <div class="informationBlock"
             *ngIf="productForm.packagingMarkedLabelAccreditationCode.length>0">
          <div class="rowTitle">
            Sigles et/ou accréditations
          </div>
          <div class="valueContainer">
            <div
              class="textValue">{{findListWording(packagingMarkedLabelAccreditationCodeOptions, productForm.packagingMarkedLabelAccreditationCode)}}</div>
          </div>
        </div>
      </div>
      <div class="middleContainer col-md-4" *ngIf="productForm.preparationInstructions ||productForm.webLink ">
        <div class="informationBlock" *ngIf="productForm.preparationInstructions">
          <div class="rowTitle">Instructions de préparation</div>
          <div class="valueContainer">
            <div class="textValue">{{productForm.preparationInstructions}}</div>
          </div>
        </div>
        <div class="informationBlock" *ngIf="productForm.webLink">
          <div class="rowTitle">
            Url du fichier externe
          </div>
          <div class="textValue">
            <a href="{{productForm.webLink}}" target="_blank">{{productForm.webLink}}</a>
          </div>
        </div>
      </div>
      <div class="rightContainer col-md-4" *ngIf="productForm.consumerStorageInstructions">
        <div class="informationBlock">
          <div class="rowTitle">Conditions particulières de conservation</div>
          <div class="valueContainer">
            <div class="textValue">{{productForm.consumerStorageInstructions}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="gtinManagerContainer col-md-12 negatePaddingLeft"
         *ngIf="productForm.structuredAddress.streetAddress !== ''">
      <div class="leftContainer col-md-4">
        <div class="managerNameContainer col-md-12">
          <div class="rowTitle">
            Nom de l'exploitant
          </div>
          <div class="textValue">
            {{productForm.contactName}}
          </div>
        </div>
      </div>
      <div class="middleRightContainer col-md-8">
        <div class="informationsRow col-md-12">
          <div class="rowTitle">
            Adresse de l'exploitant
          </div>
          <div class="textValue">
            {{productForm.fullAddress}}
          </div>
        </div>
      </div>
    </div>
    <div class="gtinOwnerContainer col-md-12 negatePaddingLeft">
      <div class="valuesContainer">
        <div class="leftContainer row negatePaddingLeft">
          <div class="ownerNameContainer col-md-12">
            <div class="bigTextInfo">
              Les droits d'utilisation du GTIN sont détenus par :
              <div class="ownerName">{{companySvc.company.companyName}}</div>
            </div>
          </div>
        </div>
        <div class="rightContainer row">
          <div class="ownerAdressContainer col-md-6">
            <div class="littleTextInfo">
              Adresse
            </div>
            <!--                        <div class="textValue">{{constructStructuredAddressWording()}}</div>-->
            <div
              class="textValue">{{companySvc.company.street}} {{companySvc.company.city}} {{companySvc.company.cp}} </div>
          </div>
          <div class="glnContainer col-md-6">
            <div class="littleTextInfo">Global Location Number (GLN)</div>
            <div class="textValue">{{companySvc.company.gln}}</div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
<confirmation-modal [isDeleteDialog]="true" #confirmationModal
                    [cancelBtnLabel]="'Annuler'"
                    [submitBtnLabel]="'Valider'"
                    [title]="constants.deleteProduct.title" [body]="constants.deleteProduct.message"
                    (onConfirm)="deleteProduct()"></confirmation-modal>

<share-modal #shareModal [url]="shareUrl"></share-modal>
