import {AfterViewChecked, Component} from '@angular/core';


@Component({
  selector: 'dashboard-footer',
  styleUrls: ['./dashboard-footer.less'],
  template: `
    <div class="DashboardFooter">
    </div>
  `
})
export class DashboardFooter {
  constructor() {
  }
}
