<div id="importPanel" class="new-way-import" style="padding-bottom: 20px;" [ngClass]="{'no-scroll': showProductReseller}">
    <product-reseller (onRedirectToMassCreation)="goToMassCreation($event)" *ngIf="showProductReseller" isImport="true"></product-reseller>

    <form  *ngIf="!showProductReseller" (ngSubmit)="submit()" ngNativeValidate>
        <div class="import-header">
            <div class="modal-title"></div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <nav class="new-way-import-breadcrumb">
                    <ul>
                        <li [class.current-step]="isShowingDownloadTemplate()">
                            <a class="first-step" (click)="goToDownloadTemplate()">
                                <div class="step-bullet">
                                    <div class="step-bullet-img"></div>
                                </div>
                                <div class="step-text step-text-title">
                                    {{constants.import.newWay.breadcrumb.firstStep}}
                                </div>
                            </a>
                        </li>
                        <li [class.current-step]="isShowingUploadFile()">
                            <a class="last-step" (click)="goToUploadFile()">
                                <div class="step-bullet">
                                    <div class="step-bullet-img"></div>
                                </div>
                                <div class="step-text step-text-title">
                                    {{constants.import.newWay.breadcrumb.secondStep}} {{productText()}}
                                </div>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="col-xs-12 first-separator" *ngIf="isShowingDownloadTemplate() && !showDefaultSection()"></div>

        <div class=" col-xs-12" style="padding-bottom: 60px;" *ngIf="isShowingDownloadTemplate()">
            <create-gtin [isBundle]="isBundle" [isNotNew]="isNotNew" [productResellerType]="null" [forImport]="true" *ngIf="showImportMyGtinsSection()" (onRedirectToMassCreation)="updateAttributes($event)"></create-gtin>
            <create-gtin [isBundle]="isBundle" [isNotNew]="isNotNew" [productResellerType]="productResellerType" [forImport]="false" *ngIf="showCreateGtinsSection()" ></create-gtin>
            <update-my-gtin *ngIf="showUpdateProductSection()"></update-my-gtin>
            <div *ngIf="showDefaultSection()">
                <div class="import-section-header">
                    <h3>{{constants.import.template.file.title2}}</h3>
                </div>

                <div>
                    <div class="import-section-body col-sm-12 col-xs-12 col-12 col-lg-7 col-md-12 col-xl-7">
                        <div class="import-template-csv product-import-download-template-item">
                            <div>
                                <div class="Oval genericIcon">
                                </div>
                                <span><label class="product-import-download-template-item-label">{{constants.import.template.files.generic.name}}</label></span>
                                <button type="button" (click)="downloadGenericTemplate()" class="right"><span>{{constants.import.template.button}}</span></button>
                            </div>
                        </div>
                        <div class="import-template-csv product-import-download-template-item">
                            <div>
                                <div class="Oval vcsIcon"></div>
                                <span><label class="product-import-download-template-item-label">{{constants.import.template.files.wine.name}}</label></span>
                                <button type="button" (click)="downloadWineTemplate()" class="right"><span>{{constants.import.template.button}}</span></button>
                            </div>
                        </div>
                        <div class="import-template-csv product-import-download-template-item">
                            <div>
                                <div class="Oval vcsIcon"></div>
                                <span><label class="product-import-download-template-item-label">{{constants.import.template.files.spirit.name}}</label></span>
                                <button type="button" (click)="downloadSpiritTemplate()" class="right"><span>{{constants.import.template.button}}</span></button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-sm-12 col-xs-12 col-12 col-lg-4 col-md-7 col-xl-3 product-import-download-template-guidance">
                        <label>{{constants.import.template.guidance.title}}</label>

                        <div class="product-import-download-choose-model">
                            <div *ngFor="let item of constants.import.template.guidance.items">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="left" style="margin-left:5%;"> <img src="assets/icons/ico-action-warning-round-empty-blue.png"
                                                                                        srcset="assets/icons/ico-action-warning-round-empty-blue@2x.png 2x, assets/icons/ico-action-warning-round-empty-blue@3x.png 3x"
                                                                                        class="ico_action_warning_round_empty_blue"></div>

                                        <div class="col-sm-10 left" style="margin-left:4%;"><b>{{item.subtitle}}</b></div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-2"></div>
                                    <div class="col-sm-10" style="margin-left:16%;">{{item.contain}}</div>
                                </div>
                            </div>
                            <br> <br>
                        </div>
                        <br> <br>
                    </div>
                </div>
            </div>
        </div>
        <div class="row importation-screen" *ngIf="isShowingUploadFile()">
            <div class="col-md-10 col-md-offset-1">
                <div class="row">
                    <div class="col-md-12">
                        <h2>Sélectionnez ou glissez-déposez votre fichier contenant vos {{productText()}}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <span class="section-title"></span>
                    </div>
                    <div class="col-md-4">
                        <span class="section-title">Résultat de l'analyse</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="row upload-box">
                            <div class="col-md-12">

                                <div ng2FileDrop
                                     #element
                                     class="dropZone"
                                     [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                                     (fileOver)="fileOverBase($event)"
                                     [uploader]="uploader">
                                    <div class="row">
                                        <label for="importFile" class="col-xs-12 text-center importFileLabel">
                                            <strong class="importTxt">Importez depuis votre ordinateur</strong>
                                            <strong class="depoTxt"> ou glissez-déposez le modèle une fois rempli.</strong>
                                        </label>
                                        <input #file class="inputFile" type="file" id="importFile" name="importFile"
                                               value="Importez depuis votre ordinateur" ng2FileSelect
                                               [uploader]="uploader"/>
                                        <div class="col-xs-12">
                                            <div class="dropZone-infoText center-block" *ngIf="productImportForm.importFile.name == null">
                                                <img class="icoImportImg" src="/assets/icons/ico_import_drag.svg" >
                                            </div>
                                            <img class="center-block" src="/assets/icons/ico-file.png" *ngIf="productImportForm.importFile.name != null">
                                        </div>

                                        <div class="infoText col-xs-12" *ngIf="productImportForm.importFile.name == null">
                                            Format d’import attendu : .CSV (coma separated values), .XLS et .XLSX.
                                            Taille Maximum : 30 mo
                                        </div>
                                        <div class="infoText fileName col-xs-12"
                                             *ngIf="productImportForm.importFile.name != null">
                                            {{productImportForm.filename}}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="col-md-12" *ngIf="submitting || productImportForm.reportUrl ">
                                <div class="progress-container-box">
                                    <div class="progress-container">
                                        <img class="icon" width="45px" src="assets/icons/viewCode.svg">
                                        <span class="file-name">
                                                    {{productImportForm.filename}}
                                            </span>
                                        <span class="nbr-products-analysed" *ngIf="productImportForm.reportUrl">
                                                    {{productImportForm.failedProducts + productImportForm.succeededProducts}} produits analysés
                                                    <img src="assets/icons/ok.svg"/>
                                            </span>
                                        <span class="nbr-products-analysed" *ngIf="!productImportForm.reportUrl">
                                                Produits en cours d’analyse…
                                            </span>

                                        <div class="progresss-bar">
                                            <div [ngStyle]="{'width.%':productImportForm.getProgressValue()}"
                                                 class="progresss-bar-value"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="product-analysis-box"
                             [ngClass]="{'active': submitting || productImportForm.reportUrl  }">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="indicator-container separator">
                                        <span class="state-indicator success">{{productImportForm.getPercentageSucceededProducts()}}%</span>
                                        <span>{{productText(true)}} valides ({{productImportForm.succeededProducts}})</span>
                                    </div>
                                    <div class="indicator-container">
                                        <span class="state-indicator error">{{productImportForm.getPercentageFailedProducts()}}%</span>
                                        <span>{{productText(true)}} invalides ({{productImportForm.failedProducts}})</span>
                                    </div>
                                    <div class="action-btn">
                                        <button class="downloadReportBtn" (click)="downloadReport()"
                                                [ngClass]="{'active': productImportForm.reportUrl}">
                                            <span class="download-icon"
                                                  [ngClass]="{'active': productImportForm.reportUrl}"></span>
                                            Télécharger le rapport
                                        </button>
                                        <span class="product-analysis-info">
                                                    En téléchargeant le rapport vous pourrez corriger vos erreurs puis ré-importer le fichier corrigé
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 first-separator" *ngIf="isShowingDownloadTemplate() && !showDefaultSection() && isVcs()"></div>

        <div class="import-footer">
            <div class="import-footer-right" role="group">
                <button type="button" class="cancelButtonReseller" *ngIf="!isLastStep() && productResellerType !== 'WITHOUT_BRAND_AND_IS_MANUFACTURER'" routerLink="/dashboard"><span>{{constants.import.button.cancel}}</span>
                </button>
                <button type="button" class="cancelButtonReseller" *ngIf="!isLastStep() && productResellerType === 'WITHOUT_BRAND_AND_IS_MANUFACTURER'" routerLink="/dashboard"><span>{{constants.import.button.cancel}}</span>
                </button>
                <button type="button" class="cancelButton" *ngIf="isLastStep()" (click)="goToPreviousStep()"><span>{{constants.import.button.back}}</span>
                </button>
                <button type="button" class="stepButtonReseller" (click)="goToNextStep()" *ngIf="!isLastStep() && productResellerType !== 'WITHOUT_BRAND_AND_IS_MANUFACTURER'"><span>{{constants.import.button.next}}</span>
                </button>
                <button type="button" class="stepButtonReseller" (click)="goToNextStep()" *ngIf="!isLastStep() && productResellerType === 'WITHOUT_BRAND_AND_IS_MANUFACTURER'"><span>{{constants.import.button.next2}}</span>
                </button>
                <button type="submit" class="submitButton" *ngIf="isShowingUploadFile()"
                        [disabled]="isAnalyseStep || submitting|| productImportForm.importFile.name == null"><span>{{constants.import.button.submitAnalysedProduct}}</span>
                </button>
            </div>
        </div>
    </form>
<!--    <a target="_blank" routerLink="/dashboard/product/categories"> Rechercher le code de la catégorie de mes produits</a>-->

    <product-import-confirmation-modal
            [productImportForm]="productImportForm"
            (onConfirmClick)="onImportConfirmClick($event)">
    </product-import-confirmation-modal>
    <confirmation-modal #confirmationModal [cancelBtnLabel]="'Non'" [submitBtnLabel]="'Oui'"
                        [title]="constants.import.newWay.infoPopIn.title" [body]="constants.import.newWay.infoPopIn.message"
                        (onConfirm)="onConfirmModal()">
    </confirmation-modal>
</div>
