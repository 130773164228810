<body class="searchBody">
    <div class="container-fluid defineScrollbar searchPageContainer">
        <div class="col-xs-12 headerContainer">
            <div class="col-xs-3 header">
                <div class="col-lg-12 col-md-12 col-xs-12">
                    <div class="logo"></div>
                </div>
            </div>
            <div class="col-md-8 headerLinks pull-right">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <button *ngIf="!isUserConnected()" class="pull-right loginBtn" (click)="ssoAuthentication()">Se
                        connecter
                    </button>
                    <button *ngIf="isUserConnected()" class="pull-right loginBtn" (click)="ssoAuthentication()">Accédez à
                        CodeOnline
                    </button>
                    <a *ngIf="!isUserConnected()" class="pull-right subscribe"
                       href="http://www.gs1.fr/Obtenir-un-code-a-barres/Adherez-a-GS1" target="_blank">Adhérer à GS1 FRANCE</a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="topContainer">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center logoContainer">
                    <img class="logoCOL" src="assets/icons/logo_col_transparent_big.png">
                    <br>
                </div>
                <div class="preInputContainer">
                    <p class="preInputText col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">Accédez à des informations de qualité pour des millions de produits dans le monde</p>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 searchSection" *ngIf="isSearchByKey">
                    <search-engine [internal]="false" [border]="'white'" class="search-engine"></search-engine>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 searchSection" *ngIf="!isSearchByKey">
                    <div class="searchDiv">
                        <input #inputElement type="tel" class="searchInput searchBtn"
                               (keyup.enter)="searchGtin()"
                               (keyup)="cleanView($event)"

                               pattern="^[0-9]*$"
                               placeholder="Saisir un code GTIN / EAN" [(ngModel)]="searchedGtin"

                        />
                        <i class="fa fa-search searchBtn" (click)="cleanView($event);searchGtin()">
                            <img src="/assets/icons/search.png">
                        </i>
                    </div>

                </div>
                <div class="resultsContainer col-md-12 col-xs-12" *ngIf="gtinExist === true && isPrivate === false">
                    <a class="col-lg-4 col-md-6 col-sm-8 col-xs-10 resultSection center-block" href="{{url}}" target="_blank">
                        <div>
                            <div class="imgSection">
                                <div class="imgGradientContainer">
                                    <div class="img-section-innerr">
                                        <div class="item">
                                            <img [ngClass]="{'no-img': productImg === '/assets/img/nopicture.png'}" class="productImg" src="{{productImg}}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="productInfoContainer">
                                <p class="brandName">{{brandName}}</p>
                                <p class="productName">{{productName}}</p>
                                <p class="companyName">{{companyName}}</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center notFoundProduct"
                     *ngIf="gtinExist === false && rateLimitExceeded === false && this.isGtin8Valid === true && ! this.error">
                    <div class="ProductNotFound-title">La carte d’identité de ce produit n’existe pas.</div>
                    <div class="ProductNotFound-text" *ngIf="!this.isGtin8 || (this.isGtin8 && license)">L'entreprise ayant les droits d'utilisation de ce GTIN n'a pas encore renseigné les informations du produit dans codeOnline.</div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center privateProduct"
                     *ngIf="isPrivate === true">
                    <div class="privateProductTitle">La carte d’identité de ce produit est privée.</div>
                    <div class="privateProductText">L'entreprise ayant les droits d'utilisation de ce GTIN ne souhaite pas partager les informations associées à ce GTIN.</div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center"
                     *ngIf="(gtinExist === false || this.isPrivate === true) && rateLimitExceeded === false && this.isGtin8Valid  && ! this.error">
                    <div class="company-section" *ngIf="license">
                        <div>
                            L'entreprise est : <span>{{ license.companyName }}</span>
                        </div>
                        <div>
                            Son identifiant entreprise / Global Location Number est :  <span>{{license.licenseeGLN}}</span>
                        </div>
                    </div>
                    <div class="areYouOwner"  *ngIf="!this.isGtin8 || (this.isGtin8 && license)">
                        <div>
                            Vous detenez les droits d'utilisation de ce GTIN ?
                        </div>
                        <div><a (click)="ssoAuthentication()" target="_blank" target="_blank">Connectez-vous</a> et saisissez les informations produit.</div>
                    </div>
                </div>
                <loader class="col-md-12 spinner" [color]="'orange'" *ngIf="searchInProgress" [size]="'50px'"></loader>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style="color:red"
                 *ngIf="rateLimitExceeded === true || isValid === false || isGtin8Valid === false || error === true">
                <div *ngIf="rateLimitExceeded === true">Nous regrettons de ne pouvoir donner suite à votre recherche.
                    Vous êtes arrivé à la limite
                    autorisée.
                </div>
                <div *ngIf="isValid === false">Le format du GTIN est incorrect.</div>
                <div *ngIf="isGtin8Valid === false">Le GTIN est invalide ou le code n'est pas un code français.</div>
                <div *ngIf="error === true" class="technical-error">
                    <p class="first-p">Une erreur technique est survenue sur ce code GTIN, veuillez nous excuser pour la gêne occasionnée.</p>
                    <p class="second-p">Pour plus de détail, veuillez contacter le service client GS1 France<br>au 01 40 22 18 00 ou par mail à <a href="mailto:serviceclient@gs1fr.org">serviceclient@gs1fr.org</a></p>
                    <p class="error-code" *ngIf="errorCode">code erreur : {{errorCode}}</p>
                </div>
            </div>
            <div class="bottomContainer">
                <div class="codeOnlineHelp">
                    <span class="textTitle">Découvrez CodeOnline, le service en ligne de GS1 France qui fait croître votre business</span>
                </div>
                <div class="col-md-5 col-sm-5 col-xs-12 cardContainer leftCard">
                    <div class="cardValues">
                        <div class="cardTitle">
                            Vous êtes fabricant,  producteur ou industriel ?
                        </div>
                        <div class="cardContent">
                            <p>Faites parler vos produits grâce au langage GS1 :</p>
                            <p>- Créez et gérez vos codes produits GTIN / EAN</p>
                            <p>- Téléchargez et imprimez vous-même vos codes barres</p>
                            <p>- Partagez la carte d’identité de vos produits</p>
                            <p>- Donnez-leur de la visibilité auprès des 2 millions d’entreprises adhérentes à GS1 dans le monde</p>
                        </div>
                        <div class="cardBottom">
                            <a href="https://www.gs1.fr/Obtenir-un-code-a-barres/Adherez-a-GS1" target="_blank">Adhérer à GS1 France</a>
                            <button class="pull-right loginBtn" (click)="ssoAuthentication()">Se
                                connecter
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-5 col-xs-12 cardContainer rightCard">
                    <div class="cardValues">
                        <div class="cardTitle">Vous cherchez de l'information produit fiable ?</div>
                        <div class="cardContent">
                            <p>Plus de 11 millions de produits disponibles</p>
                            <p>Des informations alimentées uniquement par les marques</p>
<!--                            <p>Un accès aux produits du monde entier grâce à l’intégration de Verified by GS1. En savoir plus</p>-->
                            <p>Besoin d’obtenir de l’information sur un grand nombre de produits ? Vous souhaitez automatiser la récupération de ces donneés ?</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footerContainer">
                <div class="footerTitle">
                    Accédez à CodeOnline
                </div>
                <div class="footerSubtitle">
                    Devenez adhérent GS1 et accédez à CodeOnline
                </div>
                <div class="footerRegisterBtn">
                    <a target="_blank" href="https://www.gs1.fr/Obtenir-un-code-a-barres/Adherez-a-GS1">Devenir adhérent</a>
                </div>
                <div class="footerLoginBtn">
                    Vous avez déjà un compte ? <a (click)="ssoAuthentication()">Connectez vous</a>
                </div>
            </div>
            <div class="footerCopyright">
                CodeOnline est une plateforme <img src="assets/icons/logoGS1Clear.svg" />
            </div>
        </div>
    </div>
</body>
