<div id="importPanel" class="import">
    <form (ngSubmit)="submit()" ngNativeValidate >
        <div class="import-header">
            <div class="modal-title"></div>
        </div>
        <div class="import-body container-fluid">
            <div class="row">
                <nav class="import-breadcrumb">
                    <ul>
                        <li [class.current-step]="isShowingDownloadTemplate()">
                            <a class="first-step" (click)="goToDownloadTemplate()">
                                <div class="step-text">1</div>
                                <div class="step-bullet">
                                    <div class="step-bullet-img"></div>
                                </div>
                                <div class="step-text step-text-title">{{constants.import.template.breadcrumb}}</div>
                            </a>
                        </li>
                        <li [class.current-step]="isShowingFillTemplateTutorial()">
                            <a (click)="goToFillTemplateTutorial()">
                                <div class="step-text">2</div>
                                <div class="step-bullet">
                                    <div class="step-bullet-img"></div>
                                </div>
                                <div class="step-text step-text-title">{{constants.import.fill.breadcrumb}}</div>
                            </a>
                        </li>
                        <li [class.current-step]="isShowingUploadCsv()">
                            <a class="last-step" (click)="goToUploadCsv()">
                                <div class="step-text">3</div>
                                <div class="step-bullet">
                                    <div class="step-bullet-img"></div>
                                </div>
                                <div class="step-text step-text-title">{{constants.import.upload.breadcrumb}}</div>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="row" *ngIf="isShowingDownloadTemplate()">
                <div class="import-section-header col-sm-3 col-xs-12">{{constants.import.template.title}}</div>
                <div class="import-section-body col-sm-9 col-xs-12">
                    <label>{{constants.import.template.file.title}}</label>
                    <div class="info-text">{{constants.import.template.file.csv}}</div>
                    <div class="import-template-csv">
                        <img src="assets/icons/ico_file_CSV.svg" alt="Fichier CSV">
                        <div>
                            <span class="import-template-csv-name">{{constants.import.template.file.name}}</span>
                            <button type="button" (click)="downloadCsvTemplate()"><span>{{constants.import.template.button}}</span></button>
                        </div>
                    </div>
                    <div class="import-template-csv-faq">
                        <div class="import-template-csv-faq-title" (click)="toggleFaqSection()">
                            {{constants.import.template.faq.title}}<img [src]="isMoreSectionOpened ? '/assets/icons/sortArrowUp.svg' : '/assets/icons/sortArrowDown.svg'">
                        </div>
                        <div id="faqAnchor" class="import-template-csv-faq-content" [hidden]="!isMoreSectionOpened">
                            <ng-container *ngFor="let item of constants.import.template.faq.items">
                                <p class="import-template-csv-faq-question">{{item.question}}</p>
                                <p class="import-template-csv-faq-answer">{{item.answer}}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isShowingFillTemplateTutorial()">
                <div class="import-section-header col-sm-3 col-xs-12">{{constants.import.fill.title}}</div>
                <div class="import-section-body col-sm-9 col-xs-12">
                    <ul class="import-fill-template-tab">
                        <li class="import-fill-template-tab-item" [ngClass]="{'active': fillTemplateActiveTab === 'withoutGtin'}">
                            <button type="button" (click)="changeTab('withoutGtin')"><img src="assets/icons/ico_without_GTIN.svg">{{constants.import.fill.withoutGtin.title}}</button>
                        </li>
                        <li class="import-fill-template-tab-item" [ngClass]="{'active': fillTemplateActiveTab === 'withGtin'}">
                            <button type="button" (click)="changeTab('withGtin')"><img src="assets/icons/ico_with_GTIN.svg">{{constants.import.fill.withGtin.title}}</button>
                        </li>
                        <li class="import-fill-template-tab-item" [ngClass]="{'active': fillTemplateActiveTab === 'updateProducts'}">
                            <button type="button" (click)="changeTab('updateProducts')"><img src="assets/icons/ico_update_products.svg">{{constants.import.fill.updateProducts.title}}</button>
                        </li>
                    </ul>
                    <div class="import-fill-template-content">

                        <div class="import-fill-template-content-section" *ngIf="fillTemplateActiveTab === 'withoutGtin'">
                            <div class="intro">
                                <p>{{constants.import.fill.withoutGtin.intro.title}}</p>
                                <ul>
                                    <li *ngFor="let point of constants.import.fill.withoutGtin.intro.points" [innerHtml]="point"></li>
                                </ul>
                                <p>{{constants.import.fill.withoutGtin.imageLegend}}</p>
                            </div>
                            <img class="screenshot" src="assets/img/screenshot_import_template_csv_without_gtin.png" alt="{{constants.import.fill.withoutGtin.imageLegend}}">
                        </div>

                        <div class="import-fill-template-content-section" *ngIf="fillTemplateActiveTab === 'withGtin'">
                            <div class="intro">
                                <p>{{constants.import.fill.withGtin.intro.title}}</p>
                                <ul>
                                    <li *ngFor="let point of constants.import.fill.withGtin.intro.points" [innerHtml]="point"></li>
                                </ul>
                            </div>
                            <p class="warning">{{constants.import.fill.withGtin.imageWarning}}</p>
                            <img class="screenshot" src="assets/img/screenshot_fill_import_template_csv_with_gtin.gif">
                        </div>

                        <div class="import-fill-template-content-section" *ngIf="fillTemplateActiveTab === 'updateProducts'">
                            <div class="intro">
                                <p>{{constants.import.fill.updateProducts.intro.title}}</p>
                                <ol>
                                    <li *ngFor="let point of constants.import.fill.updateProducts.intro.points" [innerHtml]="point"></li>
                                </ol>
                            </div>
                            <div class="warning">
                                <ul>
                                    <li *ngFor="let warning of constants.import.fill.updateProducts.warnings" [innerHtml]="warning"></li>
                                </ul>
                            </div>
                        </div>

                        <div id="moreSectionLink" class="import-fill-template-content-section">
                            <a [ngClass]="{'collapsed': !isMoreSectionOpened}" (click)="toggleMoreSection()">
                                <span>{{constants.import.fill.common.more}}</span><img [src]="isMoreSectionOpened ? '/assets/icons/sortArrowUp.svg' : '/assets/icons/sortArrowDown.svg'">
                            </a>
                        </div>
                        <div id="moreSection" class="import-fill-template-content-more" [ngClass]="{'collapse': !isMoreSectionOpened}">
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.gtin.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.gtin.title}}"
                                    popover="{{constants.inputsInfo.gtin.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p [innerHtml]="constants.import.fill[fillTemplateActiveTab].gtin.description"></p>
                                <p>{{constants.import.fill[fillTemplateActiveTab].gtin.tips.title}}</p>
                                <ul>
                                    <li *ngFor="let value of constants.import.fill[fillTemplateActiveTab].gtin.tips.values">{{value}}</li>
                                </ul>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productName.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.name.title}}"
                                    popover="{{constants.inputsInfo.name.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p [innerHtml]="constants.import.fill.common.productName.description"></p>
                                <p>{{constants.import.fill.common.productName.tips.title}}</p>
                                <ul>
                                    <li *ngFor="let value of constants.import.fill.common.productName.tips.values">{{value}}</li>
                                </ul>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productBrand.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.brand.title}}"
                                    popover="{{constants.inputsInfo.brand.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p [innerHtml]="constants.import.fill.common.productBrand.description"></p>
                                <p>{{constants.import.fill.common.productBrand.tips.title}}</p>
                                <ul>
                                    <li *ngFor="let value of constants.import.fill.common.productBrand.tips.values">{{value}}</li>
                                </ul>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productSubBrand.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.subBrand.title}}"
                                    popover="{{constants.inputsInfo.subBrand.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p>{{constants.import.fill.common.productSubBrand.description}}</p>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productImageUrl.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.images.title}}"
                                    popover="{{constants.inputsInfo.images.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p *ngFor="let description of constants.import.fill.common.productImageUrl.description">{{description}}</p>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productDescription.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.description.title}}"
                                    popover="{{constants.inputsInfo.description.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p>{{constants.import.fill.common.productDescription.description}}</p>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productWebLink.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.webLink.title}}"
                                    popover="{{constants.inputsInfo.webLink.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p *ngFor="let description of constants.import.fill.common.productWebLink.description">{{description}}</p>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productMPN.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.mpn.title}}"
                                    popover="{{constants.inputsInfo.mpn.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p>{{constants.import.fill.common.productMPN.description}}</p>
                            </div>
                            <div class="import-fill-template-content-section">
                                <p class="import-fill-template-content-section-title">{{constants.import.fill.common.productSKU.title}}</p>
                                <div class="icon-help"
                                    popoverTitle="{{constants.inputsInfo.sku.title}}"
                                    popover="{{constants.inputsInfo.sku.content}}"
                                    placement="right"
                                    triggers="mouseenter:mouseleave">
                                </div>
                                <p>{{constants.import.fill.common.productSKU.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isShowingUploadCsv()">
                <div class="import-section-header col-sm-3 col-xs-12">{{constants.import.upload.title}}</div>
                <div class="import-section-body col-sm-9 col-xs-12">
                    <label for="fileName">{{constants.import.upload.file.title}}</label>
                    <div class="info-text">{{constants.import.upload.file.csv}}</div>
                    <div class="info-text fileUpload">{{constants.import.upload.file.instruction}}</div>
                    <div class="import-input-file">
                        <input name="inputFileName" [ngModel]="productImportForm.importFile.name" placeholder="{{constants.import.upload.file.placeholder}}" disabled>
                        <button type="button" (click)="file.click()"><span>{{constants.import.upload.file.button}}</span></button>
                    </div>
                    <input #file type="file" class="hidden" id="importFile" name="importFile" ng2FileSelect [uploader]="uploader"/>
                </div>
            </div>
        </div>
        <div class="import-tutorial-panel">
            <div class="import-tutorial-img" (click)="openTutorial()">
                <div class="import-tutorial-img-description">
                    <div>Tutoriel d'import</div>
                </div>
            </div>
        </div>
        <div class="import-footer">
            <div class="import-footer-right" role="group">
                <button type="button" class="cancelButton" routerLink="/dashboard" *ngIf="isLastStep()"><span>{{constants.import.button.backToDashboard}}</span></button>
                <button type="button" class="stepButton" (click)="goToNextStep()" *ngIf="!isLastStep()"><span>{{constants.import.button.next}}</span></button>
                <button type="submit" class="submitButton" *ngIf="isShowingUploadCsv()" [disabled]="submitting || productImportForm.importFile.name == null"><span>{{constants.import.button.submit}}</span></button>
            </div>
        </div>
    </form>
</div>
<product-import-tutorial-modal></product-import-tutorial-modal>