<div class="ProductEdition">
  <product-view-generic [product]="product" [isBundle]="isBundle" *ngIf="isGeneric">
  </product-view-generic>

  <product-view-vcs [product]="product" *ngIf="isVcs&& featureToggleSvc.isWhalesUser()">
  </product-view-vcs>

  <product-view-vcs-old [product]="product" *ngIf="isVcs && !featureToggleSvc.isWhalesUser()">
  </product-view-vcs-old>

  <product-view-food [product]="product" *ngIf="isFood"></product-view-food>
</div>
