<div class="Unity-body" *ngIf="unityForm.productForm.adhereToTheSectoralAgreement">
    <!-- FORM -->
    <div class="Unity-form container-fluid">
        <!-- Image section -->
        <!-- FIXME should uncomment this -->
<!--        <div class="Unity-form-section row">-->
<!--            <div class="Unity-form-section-content col-md-10 col-xs-8">-->
<!--                <div class="Unity-form-subSection row">-->
<!--                    &lt;!&ndash;Images&ndash;&gt;-->
<!--                    <div class="Unity-form-input col-xs-10">-->
<!--                        <div class="Unity-form-input cold-xs-12">-->
<!--                            <label for="image-url">ou renseignez l'URL de l'image</label>-->
<!--                            <div class="icon-help"-->
<!--                                 popoverTitle="{{unityForm.constants.inputsInfo.regulatedProductName.title}}"-->
<!--                                 popover="{{unityForm.constants.inputsInfo.regulatedProductName.content}}"-->
<!--                                 placement="right"-->
<!--                                 triggers="mouseenter:mouseleave">-->
<!--                            </div>-->
<!--                            <input [ngClass]="{'disabled': imageUploader == ImageUploader.DRAG_DROP}"-->
<!--                                   [disabled]="imageUploader == ImageUploader.DRAG_DROP" id="image-url"-->
<!--                                   (change)="checkImageValidity()" [(ngModel)]="alternativeImageUrl"-->
<!--                                   name="image-url" type="text" placeholder="http://..."/>-->
<!--                        </div>-->
<!--                        <div class="Unity-form-input cold-xs-12">-->
<!--                            <label for="contentDescription">Description courte du contenu de l'image</label>-->
<!--                            <input [ngClass]="{'disabled': imageUploader == ImageUploader.NOT_SELECTED}"-->
<!--                                   maxlength="70"-->
<!--                                   [disabled]="imageUploader == ImageUploader.NOT_SELECTED"-->
<!--                                   id="contentDescription" [(ngModel)]="unityForm.productForm.contentDescription"-->
<!--                                   name="contentDescription" type="text"-->
<!--                                   placeholder="{{unityForm.constants.foodCreateForm.placeholders.contentDescription}}"/>-->
<!--                            <div class="Unity-form-infoText">{{70 - unityForm.productForm.contentDescription.length}}/70-->
<!--                                {{unityForm.constants.createForm.helpers.inputTextInfoLength}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!-- /Image section -->
        <div class="composition-step row">
            <div class="Unity-form-section row">
                <div class="Unity-form-section-name col-md-2 col-xs-4">
                    Composition
                </div>
                <div class="Unity-form-section-content col-md-10 col-xs-4 ingredientsArea">
                    <!-- ingredientStatement -->
                    <div class="Unity-form-subSection row topLeftContainer">
                        <div class="Unity-form-input">
                            <label for="ingredientStatement">
                                Liste des ingrédients
                            </label>
                            <div class="icon-help"
                                 popoverTitle="{{unityForm.constants.inputsInfo.ingredientStatement.title}}"
                                 popover="{{unityForm.constants.inputsInfo.ingredientStatement.content}}"
                                 placement="right"
                                 triggers="mouseenter:mouseleave">
                            </div>
                            <!--                                    <p style="color:red; text-align: justify;"-->
                            <!--                                    *ngIf="unityForm.productForm.ingredientStatement.length > 0">Important : Renseignez en majuscule les substances ou produits allergènes mis en évidence dans la liste des ingrédients présente sur l’emballage de la denrée alimentaire. La mise en majuscule ne doit être utilisée que pour les substances ou produits allergènes.</p>-->
                            <div class="ingredientsContainer">
                                <div class="textareaHeader" (click)="editableContent.focus();">Ingrédients:</div>
                                <div class="textAreaContainer" (click)="editableContent.focus();">
                                    <div class="textAreaVisible defineScrollbar" contenteditable="true" #editableContent (keyup)="updateTextArea(editableContent, ingredientStatement)">{{ingredientPlaceholder}}</div>
                                    <textarea id="ingredientStatement" class="mt-2 ingredient"
                                              maxlength="5000"
                                              [(ngModel)]="unityForm.productForm.ingredientStatement"
                                              name="ingredientStatement" #ingredientStatement>
                                            </textarea>
                                    <div class="textAreaFooter" *ngIf="unityForm.productForm.ingredientStatement.length > 0" (click)="editableContent.focus();">
                                        Important : Renseignez en <strong>majuscule</strong> les substances ou
                                        produits
                                        allergènes mis en évidence dans la liste des ingrédients présente sur
                                        l’emballage de la denrée alimentaire. La mise en majuscule ne doit être
                                        utilisée
                                        que pour les substances ou produits allergènes.
                                    </div>
                                </div>
                            </div>
                            <div class="Unity-form-infoText">{{5000 - unityForm.productForm.ingredientStatement.length}}
                                /5000
                                {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- netContent section -->
            <div class="Unity-form-section row netContent">
                <div class="Unity-form-subSection row">
                    <div class="Unity-form-input col-xs-9">
                        <!-- regulatedProductName -->
                        <label for="regulatedProductName">
                            Dénomination légale de vente
                            <span
                                    class="Unity-form-input--required">(Obligatoire)</span>
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.regulatedProductName.title}}"
                             popover="{{unityForm.constants.inputsInfo.regulatedProductName.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <input id="regulatedProductName" maxlength="500"
                               #regulatedProductName="ngModel"
                               [(ngModel)]="unityForm.productForm.regulatedProductName"
                               name="regulatedProductName"
                               placeholder="{{unityForm.constants.createForm.placeholders.foodProduct.regulatedProductName}}"
                               cleanInput required
                        >
                    </div>
                </div>
                <label>Autres informations du contenu net</label>
                <div class="Unity-form-section-content col-md-10 col-xs-4 netContentContainer">
                    <div class="displayDryWeight">
                        <input id="displayDryWeight" type="checkbox" (click)="showDryWeight()"
                               [checked]="canChangeDryWeight">
                        <span class="checkmark"></span>
                        <label for="displayDryWeight">Mon produit dispose d'un liquide de recouvrement</label>
                    </div>
                    <div class="displayAlcoholPerVolume">
                        <input id="displayAlcoholPerVolume" type="checkbox" (click)="showAlcoholPerVolume()"
                               [checked]="canChangeAlcoholPerVolume">
                        <span class="checkmark"></span>
                        <label for="displayAlcoholPerVolume">Mon produit est alcoolisé</label>
                    </div>
                    <div class="Unity-form-subSection row dryWeight" *ngIf="canChangeDryWeight">
                        <div class="Unity-form-input mt-2 col-md-12 col-xs-12">
                            <label for="drainedWeight">Poids net égoutté </label>
                            <div class="icon-help"
                                 popoverTitle="{{unityForm.constants.inputsInfo.drainedWeight.title}}"
                                 popover="{{unityForm.constants.inputsInfo.drainedWeight.content}}"
                                 placement="right"
                                 triggers="mouseenter:mouseleave">
                            </div>
                            <div class="Unity-form-input row">
                                <div class="col-md-12 col-xs-12 drainedWeightContainer">
                                    <input type="text" id="drainedWeight" class="drained-Weight" maxlength="20"
                                           name="drainedWeight" placeholder="Valeur"
                                           [ngClass]="{'error': hasErrorDrainedWeight()}"
                                           [(ngModel)]="unityForm.productForm.drainedWeight.quantityContained"
                                           cleanInput/>
                                    <select name="drainedWeightMeasurementUnitCode"
                                            [(ngModel)]="unityForm.productForm.drainedWeight.measurementUnitCode">
                                        <option [ngValue]=""></option>
                                        <option *ngFor="let measurementUnitCode of unityForm.drainedWeightMeasurementUnitConst"
                                                [ngValue]="measurementUnitCode.code">
                                            {{unityForm.findDrainedWeightMesurementWording(measurementUnitCode.code)}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="Unity-form-subSection row alcoholPerVolume" *ngIf="canChangeAlcoholPerVolume">
                        <div class="Unity-form-input mt-3 col-md-12 col-xs-12">
                            <label for="specific">Pourcentage d'alcool par volume</label>
                            <div class="icon-help"
                                 popoverTitle="{{unityForm.constants.inputsInfo.alcoholDegreeFood.title}}"
                                 popover="{{unityForm.constants.inputsInfo.alcoholDegreeFood.content}}"
                                 placement="right"
                                 triggers="mouseenter:mouseleave">
                            </div>
                            <div class="Unity-form-input row">
                                <div class="col-md-2 col-xs-5 alcohol-degree">
                                    <input id="specific" type="text" maxlength="4"
                                           [(ngModel)]="unityForm.productForm.alcoholDegree"
                                           [ngClass]="{'error': !isValidAlcoholDegree()}"
                                           placeholder="{{unityForm.constants.foodCreateForm.placeholders.alcoholDegreeFood}}"
                                           name="alcoholDegree">
                                </div>
                                <div class="col-md-2 col-xs-2 volume">% par vol</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Nutritional Values -->
            <!--Nutriments-->
            <div class="Unity-form-section row col-xs-12">
                <div class="Unity-form-section-name col-md-2 col-xs-4 nutriValuesTitle">Valeurs nutritionnelles
                </div>
                <div class="Unity-form-section-content nutriValuesContainer">
                    <div class="Unity-form-subSection row">
                        <div class="Unity-form-input mt-3 col-md-12 col-xs-12 foodStateContainer">
                            <label>
                                Précisez l'état de la denrée pour lequel vous déclarez les valeurs nutritionnelles:
                                <span
                                        class="Unity-form-input--required">(Obligatoire)</span>
                            </label>
                            <div class="Unity-form-input row">
                                <div class="col-md-12 col-xs-12 alcohol-degree">
                                    <label class="radio-inline">
                                        <input type="radio" name="preparationStateCode"
                                               value="PREPARED" required
                                               [(ngModel)]="unityForm.productForm.preparationStateCode">
                                        <span class="radio-inline-text"> Préparée</span>
                                        <span class="check"></span>
                                    </label>
                                    <label class="radio-inline">
                                        <input type="radio" name="preparationStateCode"
                                               value="UNPREPARED" required
                                               [(ngModel)]="unityForm.productForm.preparationStateCode">
                                        <span class="radio-inline-text"> Tel que vendu</span>
                                        <span class="check"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="Unity-form-subSection row nutri-values">
                        <div class="Unity-form-input col-xs-12 mt-3">
                            <div class="row">
                                <div class="labelContainer col-xs-5">
                                    <div class="col-xs-12 itemSpace"
                                         *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                                        <div class="row">
                                            <div class="col-xs-2 valueLabel">
                                                {{unityForm.findNutrimentWording(item.nutrientTypeCode,
                                                item.measurementUnitCode)}}
                                            </div>
                                            <div class="col-xs-2 valueSelect">
                                                <select name="measurementPrecisionCode"
                                                        required
                                                        [(ngModel)]="item.measurementPrecisionCode">
                                                    <option *ngFor="let measurement of unityForm.measurementPrecision"
                                                            [ngValue]="measurement.code">
                                                        {{measurement.wording}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="valuesContainer">
                                    <div class="col-xs-12 text-center portion">
                                        pour
                                        <select class="specificWidth"
                                                name="servingSize"
                                                required
                                                [(ngModel)]="unityForm.productForm.nutriment.measurementUnitCode">
                                            <option *ngFor="let servingSizeUnit of unityForm.nutrimentServingSize"
                                                    [ngValue]="servingSizeUnit.code">
                                                {{unityForm.findServingSizeWording(servingSizeUnit.code)}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-xs-12 valuesColumn "
                                         *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                                        <div class="valuesCell">
                                            <input class="specificWidth"
                                                   required
                                                   type="text"
                                                   autocomplete="false"
                                                   (keypress)="checkValue($event)"
                                                   (input)="checkEnergeticValue(item, 'quantityContained'); resetNutrimentClass(item);"
                                                   [(ngModel)]="item.quantityContained"
                                                   maxlength="10"
                                                   id="quantityContained_{{item.nutrientTypeCode}}"
                                                   name="quantityContained_{{i}}" #name="ngModel"
                                                   [ngClass]="{'error': item.hasErrorQuantityContained()}">
                                        </div>
                                        <div class="valuesUnit">
                                            {{unityForm.findMeasurementUnitWording(item.measurementUnitCode)}}
                                        </div>
                                    </div>
                                </div>

                                <div class="valuesContainer optionalColumn" #optionalColumn
                                     [style.height.px]="optionalColumnHeight">
                                    <div class="closeOptionalColumn"
                                         *ngIf="canSeeOptionalNutriment"
                                         (click)="hideOptionalNutriment()">X</div>
                                    <div class="oneMoreLine" (click)="showOptionalNutriment()"
                                         *ngIf="!canSeeOptionalNutriment">
                                        Ajouter une portion particulière
                                    </div>
                                    <div class="optionalContainer" *ngIf="canSeeOptionalNutriment">
                                        <div class="col-xs-12 portion">
                                            <div>
                                                pour
                                            </div>
                                            <input id="servingSizeDescription" class="specificWidth"
                                                   maxlength="20"
                                                   [(ngModel)]="unityForm.productForm.nutriment.servingSizeDescription"
                                                   name="servingSizeDescription" #name="ngModel"
                                                   placeholder="{{unityForm.constants.foodCreateForm.placeholders.servingSizeDescription}}"
                                                   cleanInput>
                                        </div>

                                        <div class="col-xs-12 valuesColumn " [hidden]="!canSeeOptionalNutriment"
                                             *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                                            <div class="valuesCell">
                                                <input type="text"
                                                       class="specificWidth"
                                                       [(ngModel)]="item.optionalQuantityContained"
                                                       name="optionalQuantityContained_{{i}}"
                                                       autocomplete="false"
                                                       (keypress)="checkValue($event)"
                                                       (input)="checkEnergeticValue(item, 'optionalQuantityContained')"
                                                       maxlength="10"
                                                       [ngClass]="{'error': item.hasErrorOptionalQuantityContained()}"
                                                       [attr.required]="unityForm.productForm.nutriment.servingSizeDescription? true: null"
                                                       [attr.disabled]="!unityForm.productForm.nutriment.servingSizeDescription? true: null">

                                            </div>
                                            <div class="valuesUnit">
                                                {{unityForm.findMeasurementUnitWording(item.measurementUnitCode)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 removeNutrimentContainer">
                                    <div class="portionMargin"></div>
                                    <div *ngFor="let item of unityForm.productForm.nutriment.items; index as i"
                                         [className]="item.isRequired ? 'blankSpace removeNutriment' : 'removeNutriment'">
                                                <span class="col-xs-12 removeNutrimentBtn" *ngIf="!item.isRequired"
                                                      (click)="removeNutriment(item, i)">x</span>
                                    </div>
                                </div>
                                <div class="Unity-form-input col-xs-12 mt-3">
                                    <div class="addNutrimentContainer">
                                        <div class="col-xs-5 addNutriment" (click)="addNutriment()"
                                             *ngIf="showAddNutriment && optionalNutrimentItems.length >0">
                                            <div>
                                                Ajouter un nutriment
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Unity-form-input col-xs-5 addNutrimentSelectContainer">
                                <select class="net-content-item-unit addNutrimentSelect"
                                        (change)="selectNutriment(optionalColumn)"
                                        [(ngModel)]="selectedNutriment"
                                        [ngModelOptions]="{standalone: true}"
                                        *ngIf="!showAddNutriment">>
                                    <option *ngFor="let nutriment of optionalNutrimentItems"
                                            [ngValue]="nutriment">{{nutriment.wording}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- /Nutritional Values-->
            <!--dailyValueIntakeReference-->
            <div class="Unity-form-section row" id="referentialContainer" *ngIf="canSeeOptionalNutriment">
                <div class="Unity-form-section-name half-width col-md-2 col-xs-4">Apports de référence (pour la
                    portion particulière)
                </div>
                <div class="Unity-form-section-description col-md-4 col-xs-4">
                    Nous calculons pour vous les apports de référence en fonction des valeurs nutritionnelles
                    entrées plus haut. Vous pouvez cependant revenir sur chaque valeur.
                </div>
                <div class="Unity-form-section-content col-md-10 col-xs-8">
                    <div class="Unity-form-subSection row referential">
                        <div class="referentialsInfos" *ngIf="dailyValueIntakeReferenceEditable == false">
                            <p>{{unityForm.productForm.dailyValueIntakeReference}}</p>
                            <div class="editLabel" (click)="editDailyValueIntakeReference()">Modifier le
                                libellé
                            </div>
                        </div>
                        <div class="referentialsInfos" *ngIf="dailyValueIntakeReferenceEditable == true">
                            <input class="col-md-6" type="text" required maxlength="200"
                                   *ngIf="dailyValueIntakeReferenceEditable == true"
                                   name="dailyValueIntakeReference"
                                   [(ngModel)]="unityForm.productForm.dailyValueIntakeReference"/>
                        </div>
                        <div class="Unity-form-subSection-name" (click)="calculateAllDailyValues()"
                             style="cursor: pointer;">
                            <img src="assets/icons/calculer.png"/>
                            Calculer automatiquement à partir des valeurs nutritionnelles
                        </div>
                        <div class="Unity-form-input col-xs-12 mt-3">
                            <div class="row">
                                <div class="col-xs-12" *ngIf="showDailyValueIntakeReferenceMessage()">
                                    En référence à la portion particulière
                                    renseignée:  {{unityForm.productForm.nutriment.servingSizeDescription}}
                                </div>
                                <div *ngFor="let item of unityForm.productForm.nutriment.items; index as i">
                                    <div class="col-xs-12 itemSpace" *ngIf="unityForm.findNutrimentWording(item.nutrientTypeCode,
                                                item.measurementUnitCode).length>0">
                                        <div class="row">
                                            <div class="col-xs-2">
                                                {{unityForm.findNutrimentWording(item.nutrientTypeCode,
                                                item.measurementUnitCode)}}
                                            </div>
                                            <div class="col-xs-2">
                                                <input class="specificWidth"
                                                       type="text"
                                                       autocomplete="false"
                                                       (keypress)="checkValue($event)"
                                                       [(ngModel)]="item.dailyValueIntakeReference"
                                                       maxlength="10"
                                                       name="dailyValueIntakeReference_{{i}}" #name="ngModel"
                                                       [ngClass]="{'error': item.hasErrorDailyValueIntakeReference()}"
                                                       placeholder="{{unityForm.constants.foodCreateForm.placeholders.dailyValueIntakeReference}}"
                                                       [attr.disabled]="!showDailyValueIntakeReferenceMessage()? true: null"
                                                       [attr.required]="isRequiredDailyValueIntakeReference()? true: null">
                                            </div>
                                            <div class="col-xs-2">
                                                %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--allergen food-->
        <div class="Unity-form-section row">
            <div class="Unity-form-section-name col-md-2 col-xs-4">Allergènes (Obligatoires)</div>
            <div class="Unity-form-section-content" id="allergensContainer">
                <div class="Unity-form-subSection row allergens">
                    <div class="Unity-form-input col-xs-12 mt-3">
                        <div class="row">
                            <!--                                        <div class="table-head">-->
                            <!--                                            <div class="col-xs-5"></div>-->
                            <!--                                        </div>-->
                            <div class="col-xs-12 itemSpace"
                                 *ngFor="let allergen of unityForm.productForm.allergensFood; index as i">
                                <div class="row">
                                    <div class="col-xs-5 labelContainer"
                                         [ngClass]="{'col-xs-4 col-xs-push-1': !unityForm.isRequiredAllergen(allergen.allergenTypeCode)}">
                                        {{unityForm.findAllergenWording(allergen.allergenTypeCode)}}
                                    </div>
                                    <div [ngClass]="{'col-xs-push-1': !unityForm.isRequiredAllergen(allergen.allergenTypeCode)}"
                                         class="radio-container">
                                        <div class="radio-grid-cell col-xs-2"
                                             *ngFor="let containmentLevelC of unityForm.containmentLevelConst;">
                                            <input type="radio" class="radio-grid-item"
                                                   attr.name="allergen_{{i}}" name="allergen_{{i}}"
                                                   [value]="containmentLevelC.code"
                                                   [(ngModel)]="allergen.levelOfContainmentCode"
                                                   [required]="unityForm.isRequiredAllergen(allergen.allergenTypeCode)"
                                                   [ngClass]="{'error': !isValidAllergenValues(allergen.allergenTypeCode, allergen.levelOfContainmentCode)}"
                                                   (change)="unityForm.addOrRemoveOptionalAllergen(allergen.allergenTypeCode,allergen.levelOfContainmentCode)"/>
                                            <div class="check">{{containmentLevelC.wording}}</div>
                                        </div>
                                        <!--                                                    <div class="table-head-cell" *ngFor="let containmentLevelC of containmentLevelConst;">{{containmentLevelC.wording}}</div>-->

                                        <!--                                                    <select name="allergen_{{i}}"-->
                                        <!--                                                            [required]="isRequiredAllergen(allergen.allergenTypeCode)"-->
                                        <!--                                                            [(ngModel)]="allergen.levelOfContainmentCode"-->
                                        <!--                                                            [ngClass]="{'error': !isValidAllergenValues(allergen.allergenTypeCode, allergen.levelOfContainmentCode)}"-->
                                        <!--                                                            (change)="addOrRemoveOptionalAllergen(allergen.allergenTypeCode,-->
                                        <!--                                                            allergen.levelOfContainmentCode)">-->
                                        <!--                                                        <option [value]=" "></option>-->
                                        <!--                                                        <option *ngFor="let containmentLevelC of containmentLevelConst;"-->
                                        <!--                                                                [value]="containmentLevelC.code">-->
                                        <!--                                                            {{containmentLevelC.wording}}-->
                                        <!--                                                        </option>-->
                                        <!--                                                    </select>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row diet-type-row">
                    <div class="Unity-form-section-name col-md-2 col-xs-4">Régimes particuliers</div>
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="cœliaque">
                            <input id="cœliaque" class="diet-type-checkbox" type="checkbox" name="COELIAC"
                                   [(ngModel)]="unityForm.productForm.dietTypes.COELIAC"><span class="checkmark"></span>
                            cœliaque
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.COELIAC.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.COELIAC.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                    <div class="Unity-form-input  col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="diabetique">
                            <input id="diabetique" class="diet-type-checkbox" type="checkbox" name="DIABETIC"
                                   [(ngModel)]="unityForm.productForm.dietTypes.DIABETIC"><span class="checkmark"></span>
                            Diabétique
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.DIABETIC.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.DIABETIC.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row diet-type-row">
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="dietetique">
                            <input id="dietetique" class="diet-type-checkbox" type="checkbox" name="DIETETIC"
                                   [(ngModel)]="unityForm.productForm.dietTypes.DIETETIC"
                            ><span class="checkmark"></span>
                            Diététique
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.DIETETIC.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.DIETETIC.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="sans-gluten">
                            <input id="sans-gluten" class="diet-type-checkbox" type="checkbox"
                                   name="FREE_FROM_GLUTEN"
                                   [(ngModel)]="unityForm.productForm.dietTypes.FREE_FROM_GLUTEN"
                            ><span class="checkmark"></span>
                            Sans gluten
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.FREE_FROM_GLUTEN.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.FREE_FROM_GLUTEN.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row diet-type-row">
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="halal">
                            <input id="halal" class="diet-type-checkbox" type="checkbox" name="HALAL"
                                   [(ngModel)]="unityForm.productForm.dietTypes.HALAL"
                            ><span class="checkmark"></span>
                            Halal
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.HALAL.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.HALAL.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="kasher">
                            <input id="kasher" class="diet-type-checkbox" type="checkbox" name="KOSHER"
                                   [(ngModel)]="unityForm.productForm.dietTypes.KOSHER"
                            ><span class="checkmark"></span>
                            Kasher
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.KOSHER.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.KOSHER.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row diet-type-row">
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="vegan">
                            <input id="vegan" class="diet-type-checkbox" type="checkbox" name="VEGAN"
                                   [(ngModel)]="unityForm.productForm.dietTypes.VEGAN"
                            ><span class="checkmark"></span>
                            Végan
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.VEGAN.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.VEGAN.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="vegetarien">
                            <input id="vegetarien" class="diet-type-checkbox" type="checkbox" name="VEGETARIAN"
                                   [(ngModel)]="unityForm.productForm.dietTypes.VEGETARIAN"
                            ><span class="checkmark"></span>
                            Végétarien
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.VEGETARIAN.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.VEGETARIAN.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row diet-type-row">
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="sans-beuf">
                            <input id="sans-beuf" class="diet-type-checkbox" type="checkbox" name="WITHOUT_BEEF"
                                   [(ngModel)]="unityForm.productForm.dietTypes.WITHOUT_BEEF"
                            ><span class="checkmark"></span>
                            Sans Bœuf
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.WITHOUT_BEEF.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.WITHOUT_BEEF.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                    <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                        <!--Name-->
                        <label for="sans-porc">
                            <input id="sans-porc" class="diet-type-checkbox" type="checkbox" name="WITHOUT_PORK"
                                   [(ngModel)]="unityForm.productForm.dietTypes.WITHOUT_PORK"
                            ><span class="checkmark"></span>
                            Sans porc
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.dietTypes.WITHOUT_PORK.title}}"
                             popover="{{unityForm.constants.inputsInfo.dietTypes.WITHOUT_PORK.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="Unity-form-section row">
            <div class="Unity-form-section-name col-md-2 col-xs-4">Informations complémentaires</div>
            <!-- structuredAddress -->
            <div class="Unity-form-section-content col-md-10 col-xs-8">
                <div class="Unity-form-subSection row col-xs-5 mt-3 areaContainer">
                    <div class="Unity-form-input">
                        <label for="consumerUsageInstructions">
                            Conditions d'utilisation
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.consumerUsageInstructions.title}}"
                             popover="{{unityForm.constants.inputsInfo.consumerUsageInstructions.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <textarea id="consumerUsageInstructions" class="mt-2 infosArea defineScrollbar" maxlength="5000" ngInit
                                  [(ngModel)]="unityForm.productForm.consumerUsageInstructions"
                                  name="consumerUsageInstructions" #name="ngModel"
                                  placeholder="Entrez votre texte ici"></textarea>

                        <!--                                    <textarea autosize id="consumerUsageInstructions" class="mt-2 autosizeTextarea"-->
                        <!--                                                maxlength="5000"-->
                        <!--                                                ngInit-->
                        <!--                                                [minRows]="1" [maxRows]="50" [useImportant]="true"-->
                        <!--                                                [(ngModel)]="unityForm.productForm.consumerUsageInstructions"-->
                        <!--                                                name="consumerUsageInstructions" #name="ngModel">-->
                        <!--                                    </textarea>-->
                        <div class="Unity-form-infoText">{{5000 - unityForm.productForm.consumerUsageInstructions.length}}
                            /5000
                            {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row col-xs-5 mt-3 areaContainer">
                    <div class="Unity-form-input">
                        <label for="preparationInstructions">Instructions de préparation
                            <span *ngIf="unityForm.productForm.preparationStateCode === 'PREPARED'" class="Unity-form-input--required">(Obligatoire)</span></label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.preparationInstructions.title}}"
                             popover="{{unityForm.constants.inputsInfo.preparationInstructions.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <textarea maxlength="2500" id="preparationInstructions" name="preparationInstructions"
                                  [(ngModel)]="unityForm.productForm.preparationInstructions" class="infosArea defineScrollbar"
                                  placeholder="Entrez votre texte ici" [required]="unityForm.productForm.preparationStateCode === 'PREPARED'"></textarea>
                        <div class="Unity-form-infoText">{{2500 - unityForm.productForm.preparationInstructions.length}}
                            /2500
                            {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row col-xs-5 mt-3 areaContainer">
                    <div class="Unity-form-input">
                        <label for="consumerStorageInstructions">Conditions particulières de
                            conservation</label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.consumerStorageInstructions.title}}"
                             popover="{{unityForm.constants.inputsInfo.consumerStorageInstructions.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <textarea maxlength="5000" id="consumerStorageInstructions"
                                  name="consumerStorageInstructions"
                                  [(ngModel)]="unityForm.productForm.consumerStorageInstructions" class="infosArea defineScrollbar"
                                  placeholder="Entrez votre texte ici"></textarea>

                        <!--                                        <textarea autosize maxlength="5000" id="consumerStorageInstructions"-->
                        <!--                                                    name="consumerStorageInstructions"-->
                        <!--                                                    class="autosizeTextarea"-->
                        <!--                                                    [(ngModel)]="unityForm.productForm.consumerStorageInstructions"></textarea>-->
                        <div class="Unity-form-infoText">{{5000 - unityForm.productForm.consumerStorageInstructions.length}}
                            /5000
                            {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row col-xs-5 mt-3 areaContainer">
                    <div class="Unity-form-input">
                        <label for="compulsoryAdditiveLabelInformation">Mentions obligatoires
                            complémentaires</label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.compulsoryAdditiveLabelInformation.title}}"
                             popover="{{unityForm.constants.inputsInfo.compulsoryAdditiveLabelInformation.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <textarea maxlength="1000" id="compulsoryAdditiveLabelInformation"
                                  name="compulsoryAdditiveLabelInformation"
                                  [(ngModel)]="unityForm.productForm.compulsoryAdditiveLabelInformation" class="infosArea defineScrollbar"
                                  placeholder="Entrez votre texte ici"></textarea>
                        <!--                                        <textarea autosize maxlength="1000" id="compulsoryAdditiveLabelInformation"-->
                        <!--                                                    name="compulsoryAdditiveLabelInformation" class="autosizeTextarea"-->
                        <!--                                                    [(ngModel)]="unityForm.productForm.compulsoryAdditiveLabelInformation"></textarea>-->
                        <div class="Unity-form-infoText">{{1000 - unityForm.productForm.compulsoryAdditiveLabelInformation.length}}
                            /1000
                            {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Unity-form-section row">
            <div class="Unity-form-section-name col-xs-12">Nutri-Score</div>
            <div class="Unity-form-section-content col-md-10 col-xs-8">
                <div class="Unity-form-subSection row col-xs-10 mt-3 areaContainer">
                    <div class="Unity-form-input">
                        <label class="nutriScoreLabel">Nutri-Score</label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.consumerUsageInstructions.title}}"
                             popover="{{unityForm.constants.inputsInfo.consumerUsageInstructions.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <div class="nutriScoreContainer">
                            <input id="toggleNutriScore" type="checkbox" (click)="hasNutriScore()"
                                   [checked]="activeNutriScore">
                            <span class="checkmark"></span>
                            <label class="toggleNutriScore">
                                Je dispose d'un Nutri-score pour ce produit
                            </label>
                        </div>
                        <div class="nutriScoreSelector" [ngClass]="activeNutriScore ? 'active' : ''">
                            <div class="nutriA" [ngClass]="unityForm.productForm.nutriScore === 'A' ? 'sel' : ''"
                                 (click)="selectNutriscore('A')">
                                <div class="nutriText">A</div>
                            </div>
                            <div class="nutriB" [ngClass]="unityForm.productForm.nutriScore === 'B' ? 'sel' : ''"
                                 (click)="selectNutriscore('B')">
                                <div class="nutriText">B</div>
                            </div>
                            <div class="nutriC" [ngClass]="unityForm.productForm.nutriScore === 'C' ? 'sel' : ''"
                                 (click)="selectNutriscore('C')">
                                <div class="nutriText">C</div>
                            </div>
                            <div class="nutriD" [ngClass]="unityForm.productForm.nutriScore === 'D' ? 'sel' : ''"
                                 (click)="selectNutriscore('D')">
                                <div class="nutriText">D</div>
                            </div>
                            <div class="nutriE" [ngClass]="unityForm.productForm.nutriScore === 'E' ? 'sel' : ''"
                                 (click)="selectNutriscore('E')">
                                <div class="nutriText">E</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Unity-form-section row">
            <div class="Unity-form-section-content col-md-10 col-xs-8">
                <div class="Unity-form-subSection row col-xs-10 mt-3 areaContainer">
                    <div class="Unity-form-input">
                        <label for="packagingMarkedLabelAccreditationCode">Sigles et/ou accréditations
                            renseignés sur le packaging</label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.packagingMarkedLabelAccreditationCode.title}}"
                             popover="{{unityForm.constants.inputsInfo.packagingMarkedLabelAccreditationCode.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <div class="Unity-form-input">
                            <div class="selectizeContainerEdit">
                                <ng-selectize id="packagingMarkedLabelAccreditationCode"
                                              name="packagingMarkedLabelAccreditationCode"
                                              [config]="packagingMarkedLabelAccreditationCodeConfig"
                                              [options]="packagingMarkedLabelAccreditationCodeOptions"
                                              [(ngModel)]="unityForm.productForm.packagingMarkedLabelAccreditationCode"
                                              ngDefaultControl></ng-selectize>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Unity-form-section row">
            <div class="Unity-form-section-content col-md-10 col-xs-8">
                <div class="Unity-form-section-content col-xs-10 col-md-10 externalUrlContainer">
                    <label class="externalFileUrl">
                        Url du fichier externe
                    </label>
                    <div class="icon-help"
                         popoverTitle="{{unityForm.constants.inputsInfo.consumerUsageInstructions.title}}"
                         popover="{{unityForm.constants.inputsInfo.consumerUsageInstructions.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <div class="Unity-form-input row">
                        <input type="text" placeholder="http://...">
                    </div>
                </div>
            </div>
        </div>

        <!-- food section -->
        <div class="Unity-form-section row">
            <div class="Unity-form-section-name col-xs-12">Pays d'origine</div>
            <!-- structuredAddress -->
            <div class="Unity-form-section-content col-xs-4" id="originCountryContainer">
                <div class="Unity-form-subSection row col-xs-4">
                    <div class="Unity-form-input">
                        <label for="countryOfOrigin">Pays d'origine </label><span
                            class="Unity-form-input--required"> (Obligatoire)</span>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.countryOfOrigin.title}}"
                             popover="{{unityForm.constants.inputsInfo.countryOfOrigin.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <div class="Unity-form-input">
                            <ng-selectize id="countryOfOrigin" name="countryOfOrigin"
                                          [config]="countryOfOriginConfig"
                                          [options]="countryOfOriginOptions"
                                          [(ngModel)]="unityForm.productForm.countryOfOrigin"
                                          ngDefaultControl></ng-selectize>
                        </div>
                    </div>
                </div>
                <div class="Unity-form-subSection row col-xs-8 exploitantInfos">
                    <div class="Unity-form-section-name col-md-10 col-xs-12">Informations sur l'exploitant</div>
                    <div class="Unity-form-input col-xs-12">
                        <!-- contactName -->
                        <label for="contactName">
                            Nom / Raison sociale de l’exploitant
                            <span class="Unity-form-input--required"
                                  *ngIf="unityForm.productForm.structuredAddress.streetAddress.length > 0 ">(Obligatoire)</span>
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.contactName.title}}"
                             popover="{{unityForm.constants.inputsInfo.contactName.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <input id="contactName" maxlength="500"
                               [(ngModel)]="unityForm.productForm.contactName"
                               name="contactName" #name="ngModel"
                               placeholder="{{unityForm.constants.foodCreateForm.placeholders.contactName}}"
                               cleanInput
                               [required]="unityForm.productForm.structuredAddress.streetAddress.length > 0 ? true: false">
                        <div class="Unity-form-infoText">{{500 - unityForm.productForm.contactName.length}}/500
                            {{unityForm.constants.createForm.helpers.inputTextInfoLength}}
                        </div>
                    </div>
                    <div class="Unity-form-input col-xs-12 mt-3 addressContainer">
                        <!--Name-->
                        <label>
                            Adresse de l’exploitant
                        </label>
                        <div class="icon-help"
                             popoverTitle="{{unityForm.constants.inputsInfo.structuredAddress.title}}"
                             popover="{{unityForm.constants.inputsInfo.structuredAddress.content}}"
                             placement="right"
                             triggers="mouseenter:mouseleave">
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input id="streetAddress" maxlength="500"
                                       [(ngModel)]="unityForm.productForm.structuredAddress.streetAddress"
                                       name="streetAddress" #name="ngModel"
                                       [required]="isRequiredStructuredAddress()"
                                       placeholder="{{unityForm.constants.foodCreateForm.placeholders.structuredAddress.streetAddress}}"
                                       style="height: 36.4px;"
                                       cleanInput>
                            </div>
                            <div class="col-md-6">
                                <input id="city"
                                       [(ngModel)]="unityForm.productForm.structuredAddress.city"
                                       name="city" #name="ngModel"
                                       [required]="isRequiredStructuredAddress()"
                                       placeholder="{{unityForm.constants.foodCreateForm.placeholders.structuredAddress.city}}"
                                       cleanInput maxlength="200"
                                       style="height: 36.4px;"
                                >
                            </div>
                            <div class="col-md-6">
                                <input id="postalCode"
                                       [(ngModel)]="unityForm.productForm.structuredAddress.postalCode"
                                       name="postalCode" #name="ngModel"
                                       [required]="isRequiredStructuredAddress()"
                                       placeholder="{{unityForm.constants.foodCreateForm.placeholders.structuredAddress.postalCode}}"
                                       cleanInput maxlength="80"
                                       style="height: 36.4px;"
                                >
                            </div>

                            <ng-selectize class="col-md-12" id="countryCode" name="countryCode"
                                          [config]="countryCodeConfig" [options]="countryOfOriginOptions"
                                          [(ngModel)]="unityForm.productForm.structuredAddress.countryCode"
                                          placeholder="{{unityForm.constants.foodCreateForm.placeholders.structuredAddress.countryCode}}"
                                          ngDefaultControl></ng-selectize>
                        </div>
                    </div>
                </div>


            </div>
        </div>

<!--        &lt;!&ndash; Codes section &ndash;&gt;-->
<!--        <div class="Unity-form-section bottomPart row">-->
<!--            <div class="Unity-form-section-name col-xs-12">GTIN</div>-->
<!--            <div class="Unity-form-section-content col-md-12 col-xs-2 bottomContent">-->
<!--                <div class="Unity-form-subSection col-md-8 row labelContainer">-->
<!--                    <label for="gtin">GTIN <span-->
<!--                            class="Unity-form-input&#45;&#45;required">(Obligatoire)</span></label>-->
<!--                </div>-->
<!--                <div class="Unity-form-subSection row">-->
<!--                    &lt;!&ndash;GTIN&ndash;&gt;-->
<!--                    <div class="Unity-form-input col-md-6 col-xs-12">-->
<!--                        <div class="Unity-form-input-gtin">-->
<!--                            <img class="col-md-5 col-lg-5 col-xs-5 barcode"-->
<!--                                 src="{{barcodeUrl}}">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--                <div class="Unity-form-subSection row">-->
<!--                    &lt;!&ndash;GTIN info block&ndash;&gt;-->
<!--                    <div class=" col-md-6  col-xs-6">-->
<!--                        <div class="Unity-form-input-gtin&#45;&#45;infoBlock"-->
<!--                             [hidden]="unityForm.productForm.cipError.status !== 'warning'"-->
<!--                             [ngClass]="unityForm.productForm.cipError.status">-->
<!--                            {{unityForm.productForm.cipError.message}}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; Visiblity &ndash;&gt;-->
<!--                <div class="col-md-6  col-xs-2 visibilityContainer"-->
<!--                     *ngIf="featureToggleSvc.canSeeChangeVisibility()">-->
<!--                    <ui-switch name="productVisibility" id="productVisibility"-->
<!--                               [checked]="!unityForm.productForm.isPrivate"-->
<!--                               (valueChange)="onVisibilityChange($event)">-->
<!--                    </ui-switch>-->
<!--                    <div class="Unity-form-input">-->
<!--                        <label class="productVisibilityLabel" for="productVisibility">Publier ce GTIN-->
<!--                        </label>-->
<!--                        <div class="icon-help"-->
<!--                             popoverTitle="{{unityForm.constants.inputsInfo.productVisibility.title}}"-->
<!--                             popover="{{unityForm.constants.inputsInfo.productVisibility.content}}"-->
<!--                             placement="right"-->
<!--                             triggers="mouseenter:mouseleave">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; Visiblity Message &ndash;&gt;-->
<!--                <div class=" col-md-4  col-xs-2 ">-->
<!--                                    <span class="productVisibilityWarnning" *ngIf="unityForm.productForm.isPrivate">-->
<!--                                    Attention, si vous désactivez la publication, les liens de partages seront inactifs et vos partenaires ne pourront pas accéder aux données du GTIN.-->
<!--                                    </span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

    </div>
</div>
