<div class="close">
  <button class="close-button" mat-dialog-close>
    <i class="bi bi-x"></i>
  </button>
</div>

<div class="new-feature">
  Nouveauté
</div>

<div class="content">
  <div class="step1 fade-in" *ngIf="step==1">
        <span class="highlight title">
            Créez le QR Code de vos bouteilles de vin pour vous conformer en toute simplicité aux nouvelles règles d'étiquetage.
        </span>
    <div class="image-1">
      <img src="assets/img/vcs-image.png" alt="">
    </div>
    <div class="circle">
      <img src="assets/img/ellipse1vcs.png" alt="">
    </div>
    <div class="text">
      GS1 France met à votre disposition
      un générateur de QR Code augmenté GS1,
      directement sur CodeOnline.
    </div>
  </div>
  <div class="step2 fade-in" *ngIf="step==2">
        <span class="highlight title">
            Créer un double marquage, pour deux besoins différents, à un seul endroit : CodeOnline
        </span>
    <div class="image-1">
      <img src="assets/img/barcode-explorer-1D-vs-2D-benefits.png" alt="">
    </div>
    <div class="image-2">
      <img src="assets/img/barcode-explorer-1D-vs-2D-benefits-2.png" alt="">
    </div>
    <div class="image-3">
      <img src="assets/img/add-circle.png" alt="">
    </div>
    <div class="circle">
      <img src="assets/img/ellipse2vcs.png" alt="">
    </div>
    <div class="text">
      Continuez à générer <span class="highlight">votre
      code-barres/ GTIN.</span>
    </div>
    <div class="text-2">
       <span class="highlight"> Générez un QR Code augmenté GS1</span> pour
       votre bouteille en remplissant la liste des
      ingrédients et les valeurs nutritionnelles.
    </div>
    <div class="arrow arrow-1">
      <div class="arrow-body"></div>
      <div class="arrow-head"></div>
    </div>
    <div class="arrow arrow-2">
      <div class="arrow-body"></div>
      <div class="arrow-head"></div>
    </div>
  </div>
  <div class="step3 fade-in" *ngIf="step==3">
        <span class="highlight title">
            Générez votre QR Code augmenté GS1 en deux clics !
        </span>
    <div class="image">
      <img src="assets/img/group-screens-vcs-new.png" alt="">
    </div>
    <div class="text">
      Cliquez sur « Créer le QR Code augmenté GS1 ».
    </div>
    <div class="text-2">
      Un lien sera automatiquement
      géneré avec les informations
      nutritionnelles <b>+</b> <br>
      un QR Code prêt à l'emploi !
    </div>
    <div class="arrow arrow-1">
      <div class="arrow-body"></div>
      <div class="arrow-head"></div>
    </div>
    <div class="arrow arrow-2">
      <div class="arrow-body"></div>
      <div class="arrow-head"></div>
    </div>
    <button class="ok-btn" gs1Button mat-dialog-close>J’ai compris !</button>
  </div>

</div>

<div class="actions">
  <div class="previous" *ngIf="step==2 || step==3" (click)="goPrevStep()">
    <button class="previous-button">
      <i class="bi-chevron-left"></i>
    </button>
  </div>
  <div class="next" *ngIf="step==1 || step==2" (click)="goNextStep()">
    <button class="next-button">
      <i class="bi-chevron-right"></i>
    </button>
  </div>
</div>
<div class="stepper"> {{step}}/3</div>
