<div class="Unity Consumer defineScrollbar">
        <form #form (ngSubmit)="submitForm()" (autocomplete)="false" #formProduct="ngForm" novalidate>
            <div class="Unity-header">
                <div class="modal-title"></div>
            </div>
            <div class="Unity-body">
                <!-- FORM -->
                <div class="Unity-form container-fluid">
                    <div class="Unity-form-section row">
                        <div class="Unity-form-section-name col-md-2 col-xs-4">Informations complémentaires</div>
                        <!-- structuredAddress -->
                            <div class="Unity-form-section-content col-md-10 col-xs-8">
                                <div class="Unity-form-subSection col-xs-5 mt-3 areaContainer">
                                    <div class="Unity-form-input">
                                        <label for="consumerUsageInstructions">
                                            Conditions d'utilisation
                                        </label>
                                        <div class="icon-help"
                                                popoverTitle="{{constants.inputsInfo.consumerUsageInstructions.title}}"
                                                popover="{{constants.inputsInfo.consumerUsageInstructions.content}}"
                                                placement="right"
                                                triggers="mouseenter:mouseleave">
                                        </div>
                                        <textarea id="consumerUsageInstructions" class="mt-2 infosArea defineScrollbar" maxlength="5000" ngInit [(ngModel)]="productForm.consumerUsageInstructions"
                                                  name="consumerUsageInstructions" #name="ngModel" placeholder="Entrez votre texte ici"></textarea>

                                    </div>
                                </div>
                                <div class="Unity-form-subSection col-xs-5 mt-3 areaContainer">
                                    <div class="Unity-form-input">
                                        <label for="preparationInstructions">Instructions de préparation
                                            <span *ngIf="productForm.preparationStateCode === 'PREPARED'" class="Unity-form-input--required">(Obligatoire)</span>

                                        </label>
                                        <div class="icon-help"
                                                popoverTitle="{{constants.inputsInfo.preparationInstructions.title}}"
                                                popover="{{constants.inputsInfo.preparationInstructions.content}}"
                                                placement="right"
                                                triggers="mouseenter:mouseleave">
                                        </div>
                                        <textarea maxlength="2500" id="preparationInstructions" name="preparationInstructions"
                                                  [(ngModel)]="productForm.preparationInstructions" class="infosArea defineScrollbar"
                                                  placeholder="Entrez votre texte ici" [required]="productForm.preparationStateCode === 'PREPARED'"></textarea>
                                    </div>
                                </div>
                                <div class="Unity-form-subSection col-xs-5 mt-3 areaContainer">
                                    <div class="Unity-form-input">
                                        <label for="consumerStorageInstructions">Conditions particulières de
                                            conservation</label>
                                        <div class="icon-help"
                                                popoverTitle="{{constants.inputsInfo.consumerStorageInstructions.title}}"
                                                popover="{{constants.inputsInfo.consumerStorageInstructions.content}}"
                                                placement="right"
                                                triggers="mouseenter:mouseleave">
                                        </div>
                                        <textarea maxlength="5000" id="consumerStorageInstructions" name="consumerStorageInstructions" [(ngModel)]="productForm.consumerStorageInstructions" class="infosArea defineScrollbar" placeholder="Entrez votre texte ici"></textarea>
                                    </div>
                                </div>
                                <div class="Unity-form-subSection col-xs-5 mt-3 areaContainer">
                                    <div class="Unity-form-input">
                                        <label for="compulsoryAdditiveLabelInformation">Mentions obligatoires
                                            complémentaires</label>
                                        <div class="icon-help"
                                                popoverTitle="{{constants.inputsInfo.compulsoryAdditiveLabelInformation.title}}"
                                                popover="{{constants.inputsInfo.compulsoryAdditiveLabelInformation.content}}"
                                                placement="right"
                                                triggers="mouseenter:mouseleave">
                                        </div>
                                        <textarea maxlength="1000" id="compulsoryAdditiveLabelInformation" name="compulsoryAdditiveLabelInformation" [(ngModel)]="productForm.compulsoryAdditiveLabelInformation" class="infosArea defineScrollbar" placeholder="Entrez votre texte ici"></textarea>
<!--                                        <textarea autosize maxlength="1000" id="compulsoryAdditiveLabelInformation"-->
<!--                                                    name="compulsoryAdditiveLabelInformation" class="autosizeTextarea"-->
<!--                                                    [(ngModel)]="productForm.compulsoryAdditiveLabelInformation"></textarea>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- consumerUsageInstructions -->
                        <div class="Unity-form-section row">
                            <div class="Unity-form-section-name col-md-2 col-xs-4">
                                Nutri-score
                                </div>
                            <div class="Unity-form-section-content col-md-10 col-xs-8">
                                <div class="Unity-form-subSection row">
                                    <div class="Unity-form-input col-xs-6 mt-3">
                                        <label class="nutriScoreLabel">
                                            Indiquez le nutri-score
                                        </label>
                                        <div class="icon-help"
                                                popoverTitle="{{constants.inputsInfo.consumerUsageInstructions.title}}"
                                                popover="{{constants.inputsInfo.consumerUsageInstructions.content}}"
                                                placement="right"
                                                triggers="mouseenter:mouseleave">
                                        </div>
                                        <div class="nutriScoreContainer">
                                            <input id="toggleNutriScore" type="checkbox" (click)="hasNutriScore()" [checked]="activeNutriScore">
                                            <span class="checkmark"></span>
                                            <label class="toggleNutriScore">
                                                Je dispose d'un Nutri-score pour ce produit
                                            </label>
                                        </div>
                                        <div class="nutriScoreSelector" [ngClass]="activeNutriScore ? 'active' : ''">
                                            <div class="nutriA" [ngClass]="selectedNutriscore === 'A' ? 'sel' : ''" (click)="selectNutriscore('A')">
                                                <div class="nutriText">A</div>
                                            </div>
                                            <div class="nutriB" [ngClass]="selectedNutriscore === 'B' ? 'sel' : ''" (click)="selectNutriscore('B')">
                                                <div class="nutriText">B</div>
                                            </div>
                                            <div class="nutriC" [ngClass]="selectedNutriscore === 'C' ? 'sel' : ''" (click)="selectNutriscore('C')">
                                                <div class="nutriText">C</div>
                                            </div>
                                            <div class="nutriD" [ngClass]="selectedNutriscore === 'D' ? 'sel' : ''" (click)="selectNutriscore('D')">
                                                <div class="nutriText">D</div>
                                            </div>
                                            <div class="nutriE" [ngClass]="selectedNutriscore === 'E' ? 'sel' : ''" (click)="selectNutriscore('E')">
                                                <div class="nutriText">E</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-section row">
                            <div class="Unity-form-section-name col-md-2 col-xs-4">Sigles / accréditations marqués sur le packaging</div>

                            <div class="Unity-form-section-content col-md-10 col-xs-8">
                                <div class="Unity-form-subSection row col-xs-10 col-md-10 accreditationContainer">
                                    <label for="packagingMarkedLabelAccreditationCode">
                                        Sigles et/ou accréditations renseignés sur le packaging
                                    </label>
                                    <div class="icon-help"
                                             popoverTitle="{{constants.inputsInfo.packagingMarkedLabelAccreditationCode.title}}"
                                             popover="{{constants.inputsInfo.packagingMarkedLabelAccreditationCode.content}}"
                                             placement="right"
                                             triggers="mouseenter:mouseleave">
                                    </div>
                                    <div class="Unity-form-input row">
                                        <div class="selectizeContainer">
                                                <ng-selectize id="packagingMarkedLabelAccreditationCode" name="packagingMarkedLabelAccreditationCode"
                                                          [config]="packagingMarkedLabelAccreditationCodeConfig"
                                                          [options]="packagingMarkedLabelAccreditationCodeOptions"
                                                          [(ngModel)]="productForm.packagingMarkedLabelAccreditationCode"
                                                          ngDefaultControl></ng-selectize>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="Unity-form-section row">
                            <div class="Unity-form-section-name col-md-2 col-xs-4">Lien vers fichier externe</div>
                            <div class="Unity-form-section-content col-md-10 col-xs-8">
                                <div class="Unity-form-section-content col-xs-10 col-md-10 externalUrlContainer">
                                    <label class="externalFileUrl">
                                        Url du fichier externe
                                    </label>
                                    <div class="icon-help"
                                         popoverTitle="{{constants.inputsInfo.consumerUsageInstructions.title}}"
                                         popover="{{constants.inputsInfo.consumerUsageInstructions.content}}"
                                         placement="right"
                                         triggers="mouseenter:mouseleave">
                                    </div>
                                    <div class="Unity-form-input row">
                                        <input type="text" placeholder="http://...">
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="Unity-footer">
                <div class="completionLevel">
                    <div class="barContainer">
                        <div class="completionBar" [style.width.%]="33.333 * (stepCount - 1)"></div>
                    </div>
                    <div class="currentStep">Étape {{stepCount}}/4</div>
                </div>
                <div class="Unity-footer-buttons" role="group">
                    <button gs1Button [appearance]="'outlined'" (click)="returnBack()">Retour</button>
                    <button  gs1Button (click)="checkRequiredTextArea(form, $event)">Suivant</button>
                </div>
            </div>
        </form>
    </div>
