
<div class="classificationCategoriesModal">
    <bs-modal class="classificationCategoriesModal" [animation]="true" [keyboard]="false"
            [backdrop]="'static'" #modal cssClass="classificationCategoriesModal-cmp">
        <bs-modal-header class="classificationCategoriesModal-header" [showDismiss]="true">
            <!-- {{categoryDetails.text}} -->
            <h2 class="col-md-12 classificationCategoriesModal-title">{{categoryDetails.text}}</h2>
        </bs-modal-header>
        <bs-modal-body>
            <div class="classificationCategoriesModal-body">
                <span class="col-md-12 code-category" *ngIf="showSelectCategory">
                            Code: {{categoryDetails.code}}
                </span>
                <span class="col-md-12 def-category" [innerHTML]="categoryDetails.definition">

                </span>
            </div>
        </bs-modal-body>
        <bs-modal-footer class="classificationCategoriesModal-footer" [showDefaultButtons]="false">
            <div class="classificationCategoriesModal-footer-buttons" role="group">
                <div class="col-md-12 bookmark-category-actions">
                    <!--                    Do not delete this code !!-->

                    <!--                    <div class="col-md-6 bookmark-category" [ngClass]="{'is-bookmarked': hasCategory(categoryDetails)}"-->
                    <!--                         (click)="bookmarkCategory(categoryDetails)">-->
                    <!--                        <span>-->
                    <!--                            <span *ngIf="!hasCategory(categoryDetails)">Ajoutez à vos favoris</span>-->
                    <!--                                    <span *ngIf="hasCategory(categoryDetails)">Supprimez de vos favoris</span>-->

                    <!--                        </span>-->
                    <!--                    </div>-->
                    <div class="row copy-action">
                        <div class="col-md-8" [hidden]="showSelectCategory">
                            <div class="row copy-action-form">
                                <div class="col-md-3">
                                    Code:
                                </div>
                                <div class="col-md-9">
                                    <input #shareClassificationCode type="text" [value]="categoryDetails.code" readonly/>
                                </div>
                                <button class="copy-btn" [ngxClipboard]="shareClassificationCode">
                                    Copier
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 pull-right" *ngIf="showSelectCategory">
                        <button class="select-btn" (click)="saveSelectedCategory()">
                            Selectionner
                        </button>
                    </div>
                </div>
            </div>
        </bs-modal-footer>
    </bs-modal>
</div>
