import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UiSwitchModule} from 'ngx-ui-switch';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {BsModalModule} from 'ng2-bs3-modal';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {StickyModule} from 'ng2-sticky-kit';
import {FileUploadModule} from 'ng2-file-upload';
import {AutosizeModule} from 'ngx-autosize';


import {SharedModule} from '../shared/shared.module';
import {ProductsSvc} from './services/products.service';
import {DashboardHeaderSvc} from './services/dashboard-header.service';
import {BarcodeGenerationSvc} from './services/barcode-generation.service';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ComponentLoaderFactory} from 'ngx-bootstrap/component-loader';

import {Dashboard} from './dashboard.component';
import {DashboardProducts} from './components/dashboard-products/dashboard-products.component';
import {DashboardHeader} from './components/dashboard-header/dashboard-header.component';
import {DashboardLateralMenu} from './components/dashboard-lateral-menu/dashboard-lateral-menu.component';
import {DashboardFooter} from './components/dashboard-footer/dashboard-footer.component';
import {BarcodeModal} from './components/barcode-modal/barcode-modal.component';
import {BarcodeDisclaimerModal} from './components/barcode-disclaimer-modal/barcode-disclaimer-modal.component';
import {ClassificationModal} from './components/classification-modal/classification-modal.component';
import {
  ProductCreationChoiceCategoryComponent
} from './components/product-creation-choice-category';

import {ProductImport} from './components/product-import/product-import.component';
import {ProductImportTutorialModal} from './components/product-import/product-import-tutorial-modal.component';
import {ProductImportUpload} from './components/product-import/product-import-upload.component';
import {ProductImportStatus} from './components/product-import/product-import-status.component';
import {BarcodeModalFormImg} from './components/barcode-modal/barcode-modal-form-img.component';
import {BarcodeModalFormVecto} from './components/barcode-modal/barcode-modal-form-vecto.component';
import {HelpCenterModule} from './helpCenter/helpCenter.module';
import {CompanySvc} from '../shared/services/company.service';
import {AuthGuard} from '../shared/services/auth-guard';
import {InputTrim} from '../shared/directives/input-trim';
import {InputClean} from '../shared/directives/input-clean';
import {ProductExportModal} from './components/product-export-modal/product-export-modal.component';
import {ProductImportModal} from './components/product-import-modal/product-import-modal.component';
import {
  ProductImportConfirmationModal
} from './components/product-import-confirmation-modal/product-import-confirmation-modal.component';
import {
  ProductImportDownloadTemplate
} from './components/product-import-download-template/product-import-download-template.component';
import {ClassificationSvc} from '../shared/services/classification.service';
import {OnBoardingModal} from './components/onboarding-disclaimer-modal/onboarding-modal.component';
import {SharedDashboardSvc} from './services/sharedDashboardSvc';
import {ProductViewChoiceCategoryComponent} from './components/product-view-choice-category';
import {SettingsViewComponent} from './components/settings';
import {SettingsPrefixesComponent} from './components/settings-prefixes';
import {
  ImportMyGtinComponent
} from './components/product-import-download-template/import-my-gtin/import-my-gtin.component';
import {CreateGtinComponent} from './components/product-import-download-template/create-gtin/create-gtin.component';
import {
  UpdateMyGtinComponent
} from './components/product-import-download-template/update-my-gtin/update-my-gtin.component';
import {
  NewFeatureInformationModalComponent
} from './components/new-feature-information-modal/new-feature-information-modal.component';
import {ProductCategoryComponent} from './components/product-category/product-category.component';
import {
  UnityConsumerCreationFoodComponent
} from './components/product-creation-choice-category/product-creation/third-step/product-creation-food/unity-consumer-creation-food.component';
import {
  ProductEditionChoiceCategoryComponent
} from './components/product-edition-choice-category/product-edition-choice-category.component';
import {
  UnityConsumerEditionGenericComponent
} from './components/product-edition-choice-category/product-edition-generic/unity-consumer-edition-generic.component';
import {
  UnityConsumerEditionFoodComponent
} from './components/product-edition-choice-category/product-edition-food/unity-consumer-edition-food.component';
import {
  UnityConsumerCreationVcsComponent
} from './components/product-creation-choice-category/product-creation/third-step/product-creation-vcs/unity-consumer-creation-vcs.component';

import {
  UnityConsumerEditionVcsComponent
} from './components/product-edition-choice-category/product-edition-vcs/unity-consumer-edition-vcs.component';
import {
  CompositionStepComponent
} from './components/product-creation-choice-category/product-creation/third-step/product-creation-food/steps/composition.step.component';
import {
  ComplementaryInfoStepComponent
} from './components/product-creation-choice-category/product-creation/third-step/product-creation-food/steps/complementaryInfo.step.component';
import {
  OriginStepComponent
} from './components/product-creation-choice-category/product-creation/third-step/product-creation-food/steps/origin.step.component';
import {
  DietStepComponent
} from './components/product-creation-choice-category/product-creation/third-step/product-creation-food/steps/diet.step.component';
import {ProductViewGenericComponent} from './components/product-view-choice-category/product-view-generic';
import {ProductViewVcsComponent} from './components/product-view-choice-category/product-view-vcs';
import {
  ProductViewFoodComponent
} from './components/product-view-choice-category/product-view-food/product-view-food.component';
import {ProductCategoryImportComponent} from './components/product-category-import/product-category-import.component';
import {Resolver} from '../shared/services/resolver';
import {ClipboardModule} from 'ngx-clipboard';
import {
  ClassificationOnBoardingModal
} from './components/classification-onboarding-modal/first-Modal/classification-onboarding-modal.component';
import {
  ClassificationSecondOnBoardingModal
} from './components/classification-onboarding-modal/second-modal/classification-second-onboarding-modal.component';
import {FoodOnBoardingModal} from './components/food-onboarding-modal/food-onboarding-modal.component';
import {
  ProductCreationGenericFirstStep
} from './components/product-creation-choice-category/product-creation/first-step/product-creation-generic-first-step';
import {
  SecondStepComponent
} from './components/product-creation-choice-category/product-creation/second-step/second-step.component';
import {
  ProductCreationComponent
} from './components/product-creation-choice-category/product-creation/product-creation.component';
import {
  ThirdStepComponent
} from './components/product-creation-choice-category/product-creation/third-step/third-step.component';
import {
  FinalStepComponent
} from './components/product-creation-choice-category/product-creation/final-step/final-step.component';
import {UnityFormResolver} from './services/unityFormResolver.service';
import {ProductResellerComponent} from './components/product-reseller/product-reseller.component';
import {DashboardRoutingModule} from './dashboard.routes';

import {CodificationRuleComponent} from './components/codification-rule/codification-rule.component';
import {QrCodeCreationComponent} from './components/qr-code-creation/qr-code-creation.component';
import {
  UnityConsumerCreationVcsOldComponent
} from './components/product-creation-choice-category/product-creation/third-step/product-creation-vcs-old/unity-consumer-creation-vcs.component-old';
import {
  UnityConsumerEditionVcsOldComponent
} from './components/product-edition-choice-category/product-edition-vcs-old/unity-consumer-edition-vcs-old.component';
import {ProductViewVcsOldComponent} from './components/product-view-choice-category/product-view-vcs-old';
import {GS1LayoutModule, GS1LoaderModule, GS1ModalModule} from '@gs1france/gs1-components-library';
import {GS1ButtonModule} from '@gs1france/gs1-components-library';
import {
  ApplicationDisclaimerModal
} from './components/application-disclaimer-modal/application-disclaimer-modal.component';

import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { UnreachableProductModalComponent } from './components/unreachable-product-modal/unreachable-product-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import { UpdateCategoryComponent } from './components/update-category/update-category.component';
import {
    QrExperienceModal
} from './components/product-view-choice-category/product-view-generic/qr-experience-modal/qr-experience-modal.component';
import {
    RegulatoryModal
} from './components/product-view-choice-category/product-view-vcs/regulatory-notice/regulatory-modal.component';
import { QrExperienceModalVCS } from './components/product-view-choice-category/product-view-vcs/qr-experience-modal/qr-experience-modal.component';
import { ValidationEditionProductComponent } from './components/product-edition-choice-category/validation-edition-product/validation-edition-product.component';
import { ValidationCreationProductComponent } from './components/product-creation-choice-category/product-creation/validation-creation-product/validation-creation-product.component';

@NgModule({
    // bootstrap: [Dashboard],
  imports: [
    DashboardRoutingModule,
    FormsModule,
    BsModalModule,
    HelpCenterModule,
    SharedModule,
    FileUploadModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    PopoverModule.forRoot(),
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    InfiniteScrollModule,
    UiSwitchModule,
    StickyModule,
    AutosizeModule,
    ClipboardModule,
    ReactiveFormsModule,
    GS1LoaderModule,
    GS1ModalModule,
    GS1LayoutModule,
    GS1ButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatProgressBarModule
  ],
    providers: [
        ProductsSvc,
        DashboardHeaderSvc,
        BarcodeGenerationSvc,
        ComponentLoaderFactory,
        CompanySvc,
        AuthGuard,
        ClassificationSvc,
        SharedDashboardSvc,
        UnityFormResolver,
        Resolver,
        GS1ModalModule
    ],
    declarations: [
        Dashboard,
        DashboardProducts,
        DashboardHeader,
        DashboardLateralMenu,
        BarcodeModal,
        BarcodeDisclaimerModal,
        ClassificationModal,
        ProductCreationChoiceCategoryComponent,
        ProductExportModal,
        ProductImportModal,
        ProductImport,
        ProductImportConfirmationModal,
        ProductImportTutorialModal,
        ProductImportUpload,
        ProductImportStatus,
        ImportMyGtinComponent,
        NewFeatureInformationModalComponent,
        CreateGtinComponent,
        UpdateMyGtinComponent,
        ProductImportDownloadTemplate,
        BarcodeModalFormImg,
        BarcodeModalFormVecto,
        ProductViewChoiceCategoryComponent,
        UnityConsumerCreationFoodComponent,
        UnityConsumerEditionVcsComponent,
        InputTrim,
        InputClean,
        OnBoardingModal,
        SettingsViewComponent,
        SettingsPrefixesComponent,
        ProductCategoryComponent,
        ProductEditionChoiceCategoryComponent,
        UnityConsumerEditionGenericComponent,
        UnityConsumerEditionFoodComponent,
        ProductViewGenericComponent,
        ProductViewVcsComponent,
        CompositionStepComponent,
        ComplementaryInfoStepComponent,
        OriginStepComponent,
        DietStepComponent,
        ProductViewFoodComponent,
        ProductCategoryImportComponent,
        ClassificationOnBoardingModal,
        ClassificationSecondOnBoardingModal,
        FoodOnBoardingModal,
        ProductCreationGenericFirstStep,
        SecondStepComponent,
        ProductCreationComponent,
        ThirdStepComponent,
        UnityConsumerCreationVcsComponent,
        FinalStepComponent,
        ProductResellerComponent,
        CodificationRuleComponent,
        UnityConsumerCreationVcsOldComponent,
        UnityConsumerEditionVcsOldComponent,
        ProductViewVcsOldComponent,
        ApplicationDisclaimerModal,
        QrCodeCreationComponent,
        UpdateCategoryComponent,
        UnreachableProductModalComponent,
        QrExperienceModal,
        RegulatoryModal,
        QrExperienceModalVCS,
        ValidationEditionProductComponent,
        ValidationCreationProductComponent,
        DashboardFooter
    ],
    exports: []
})
export class DashboardModule {

  constructor() {
  }

}
