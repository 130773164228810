<div class="Unity Consumer defineScrollbar">
    <form #form (ngSubmit)="submitForm(true, form)" (autocomplete)="false" #formProduct="ngForm" novalidate>
        <div class="Unity-header">
            <div class="modal-title"></div>
        </div>
        <div class="Unity-body">
            <!-- FORM -->
            <div class="Unity-form container-fluid">
                <!--allergen food-->
                <div class="Unity-form-section row">
                    <div class="Unity-form-section-name col-md-2 col-xs-4">Allergènes (Obligatoires)</div>
                    <div class="Unity-form-section-content" id="allergensContainer">
                        <div class="Unity-form-subSection row allergens">
                            <div class="Unity-form-input col-xs-12 mt-3">
                                <div class="row">
                                    <!--                                        <div class="table-head">-->
                                    <!--                                            <div class="col-xs-5"></div>-->
                                    <!--                                        </div>-->
                                    <div class="col-xs-12 itemSpace"
                                         *ngFor="let allergen of productForm.allergensFood; index as i">
                                        <div class="row" attr.name="row_{{i}}">
                                            <div class="labelContainer {{allergen.allergenTypeCode}}"
                                                 #labelContainerElement
                                                 [ngClass]="{'col-xs-4 col-xs-push-1': !isRequiredAllergen(allergen.allergenTypeCode),
                                                 'radioBtnError': !isValidAllergenValues(allergen.allergenTypeCode, allergen.levelOfContainmentCode)}">
                                                {{findAllergenWording(allergen.allergenTypeCode)}}
                                            </div>
                                            <div [ngClass]="{'col-xs-push-1': !isRequiredAllergen(allergen.allergenTypeCode)}"
                                                 class="radio-container">
                                                <div class="radio-grid-cell col-xs-2"
                                                     *ngFor="let containmentLevelC of containmentLevelConst;">
                                                    <input type="radio" class="radio-grid-item"
                                                           attr.name="allergen_{{i}}" name="allergen_{{i}}"
                                                           [value]="containmentLevelC.code"
                                                           [(ngModel)]="allergen.levelOfContainmentCode"
                                                           [required]="isRequiredAllergen(allergen.allergenTypeCode)"
                                                           (change)="addOrRemoveOptionalAllergen(allergen.allergenTypeCode,allergen.levelOfContainmentCode)"
                                                           (click)="updateLabel(labelContainerElement)"/>
                                                    <div class="check">{{containmentLevelC.wording}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection row diet-type-row">
                            <div class="Unity-form-section-name col-md-2 col-xs-4">Régimes particuliers</div>
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="cœliaque">
                                    <input id="cœliaque" class="diet-type-checkbox" type="checkbox" name="COELIAC"
                                           [(ngModel)]="productForm.dietTypes.COELIAC"><span class="checkmark"></span>
                                    cœliaque
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.COELIAC.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.COELIAC.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                            <div class="Unity-form-input  col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="diabetique">
                                    <input id="diabetique" class="diet-type-checkbox" type="checkbox" name="DIABETIC"
                                           [(ngModel)]="productForm.dietTypes.DIABETIC"><span class="checkmark"></span>
                                    Diabétique
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.DIABETIC.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.DIABETIC.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection row diet-type-row">
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="dietetique">
                                    <input id="dietetique" class="diet-type-checkbox" type="checkbox" name="DIETETIC"
                                           [(ngModel)]="productForm.dietTypes.DIETETIC"
                                    ><span class="checkmark"></span>
                                    Diététique
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.DIETETIC.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.DIETETIC.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="sans-gluten">
                                    <input id="sans-gluten" class="diet-type-checkbox" type="checkbox"
                                           name="FREE_FROM_GLUTEN"
                                           [(ngModel)]="productForm.dietTypes.FREE_FROM_GLUTEN"
                                    ><span class="checkmark"></span>
                                    Sans gluten
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.FREE_FROM_GLUTEN.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.FREE_FROM_GLUTEN.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection row diet-type-row">
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="halal">
                                    <input id="halal" class="diet-type-checkbox" type="checkbox" name="HALAL"
                                           [(ngModel)]="productForm.dietTypes.HALAL"
                                    ><span class="checkmark"></span>
                                    Halal
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.HALAL.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.HALAL.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="kasher">
                                    <input id="kasher" class="diet-type-checkbox" type="checkbox" name="KOSHER"
                                           [(ngModel)]="productForm.dietTypes.KOSHER"
                                    ><span class="checkmark"></span>
                                    Kasher
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.KOSHER.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.KOSHER.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection row diet-type-row">
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="vegan">
                                    <input id="vegan" class="diet-type-checkbox" type="checkbox" name="VEGAN"
                                           [(ngModel)]="productForm.dietTypes.VEGAN"
                                    ><span class="checkmark"></span>
                                    Végan
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.VEGAN.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.VEGAN.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="vegetarien">
                                    <input id="vegetarien" class="diet-type-checkbox" type="checkbox" name="VEGETARIAN"
                                           [(ngModel)]="productForm.dietTypes.VEGETARIAN"
                                    ><span class="checkmark"></span>
                                    Végétarien
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.VEGETARIAN.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.VEGETARIAN.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection row diet-type-row">
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="sans-beuf">
                                    <input id="sans-beuf" class="diet-type-checkbox" type="checkbox" name="WITHOUT_BEEF"
                                           [(ngModel)]="productForm.dietTypes.WITHOUT_BEEF"
                                    ><span class="checkmark"></span>
                                    Sans Bœuf
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.WITHOUT_BEEF.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.WITHOUT_BEEF.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                            <div class="Unity-form-input col-md-3 col-lg-3 col-sm-4 col-xs-6">
                                <!--Name-->
                                <label for="sans-porc">
                                    <input id="sans-porc" class="diet-type-checkbox" type="checkbox" name="WITHOUT_PORK"
                                           [(ngModel)]="productForm.dietTypes.WITHOUT_PORK"
                                    ><span class="checkmark"></span>
                                    Sans porc
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.dietTypes.WITHOUT_PORK.title}}"
                                     popover="{{constants.inputsInfo.dietTypes.WITHOUT_PORK.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Unity-footer">
            <div class="completionLevel">
                <div class="barContainer">
                    <div class="completionBar" [style.width.%]="33.333 * (stepCount - 1)"></div>
                </div>
                <div class="currentStep">Étape {{stepCount}}/4</div>
            </div>
            <div class="Unity-footer-buttons" role="group">
                <button gs1Button [appearance]="'outlined'"(click)="returnBack()">Retour</button>
                <button gs1Button (click)="checkRequiredInputs(form, $event)">Suivant</button>
            </div>
        </div>
    </form>
</div>
