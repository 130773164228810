<div class="classification-product-category defineScrollbar">
    <div class="row">
        <div class="col-md-6 search-container">
            <div class="row">
                <div class="col-md-12 title">
                    <span>Consultez l'ensemble des catégories GPC dans le catalogue.</span>
                    <span>Francais / GPC Décembre 2018 (En production dans le réseau GDSN).</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding-top: 20px;">
        <div class="col-md-4" *ngIf="!hideSegments">
            <label>Segments</label>
            <div class="defineClassificationScrollbar" [ngClass]="familyList.length ? 'hasBorder' : ''" id="segments">
                <div class="col-md-12" *ngFor="let segment of segmentList" (click)="showFamilies(segment.code)"
                    style="cursor: pointer" [ngClass]="segment.code == clickedSegment ? 'selected' : ''" [id]="'seg-' + segment.code">
                    {{segment.text}}
                    <button *ngIf="segment.code == clickedSegment" (click)="hideFamilies($event);">X</button>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="familyList.length">
            <label>Familles</label>
            <div class="defineClassificationScrollbar" [ngClass]="classList.length ? 'hasBorder' : ''">
                <div class="col-md-12" *ngFor="let family of familyList" (click)="showClasses(family.code)"
                    style="cursor: pointer" [ngClass]="family.code == clickedFamily ? 'selected' : ''">
                    {{family.text}}
                    <button *ngIf="family.code == clickedFamily" (click)="hideClasses($event);">X</button>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="classList.length">
            <label>Classes</label>
            <div class="defineClassificationScrollbar" [ngClass]="brickList.length ? 'hasBorder' : ''">
                <div class="col-md-12" *ngFor="let class of classList" (click)="showBricks(class.code)"
                    style="cursor: pointer" [ngClass]="class.code == clickedClass ? 'selected' : ''">
                    {{class.text}}
                    <button *ngIf="class.code == clickedClass" (click)="hideBricks($event);">X</button>
                </div>
            </div>
        </div>
        <div class="col-md-4" *ngIf="brickList.length">
            <label>Briques</label>
            <div class="defineClassificationScrollbar">
                <div class="col-md-12" *ngFor="let brick of brickList" style="cursor: pointer" (click)="pickCategory(brick)">
                    {{brick.text}}
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-4 details-container">
        <div class="col-md-12 details-box" [hidden]="isPickedCategory === false"
             #resultDetailsContainer>
            <h2 class="col-md-12">{{categoryDetails.text}}</h2>
            <span class="col-md-12 code-category" *ngIf="showSelectCategory">
                        Code: {{categoryDetails.code}}
            </span>
            <span class="col-md-12" [innerHTML]="categoryDetails.definition">

                </span>
            <div class="col-md-12 bookmark-category-actions"> -->
                <!--                    Do not delete this code !!-->

                <!--                    <div class="col-md-6 bookmark-category" [ngClass]="{'is-bookmarked': hasCategory(categoryDetails)}"-->
                <!--                         (click)="bookmarkCategory(categoryDetails)">-->
                <!--                        <span>-->
                <!--                            <span *ngIf="!hasCategory(categoryDetails)">Ajoutez à vos favoris</span>-->
                <!--                                    <span *ngIf="hasCategory(categoryDetails)">Supprimez de vos favoris</span>-->

                <!--                        </span>-->
                <!--                    </div>-->
                <!-- <div class="row">
                    <div class="col-md-6" [hidden]="showSelectCategory">
                        <div class="row">
                            <div class="col-md-2">
                                Code:
                            </div>
                            <div class="col-md-4">
                                <input #shareClassificationCode type="text" [value]="categoryDetails.code" readonly/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 text-center pull-right"  *ngIf="!showSelectCategory">

                        <button class="select-btn" [ngxClipboard]="shareClassificationCode"
                               >
                            Copier
                        </button>
                    </div>
                </div>

                <div class="col-md-6 text-center pull-right" *ngIf="showSelectCategory">
                    <button class="select-btn" (click)="saveSelectedCategory(categoryDetails)">
                        Selectionner
                    </button>
                </div>

            </div>
        </div> 
    </div>-->
    
</div>

<classification-categories-modal
    #classificationCategoriesModal
    [categoryDetails]="categoryDetails"
    [showSelectCategory]="showSelectCategory"
    (onSelect)="saveSelectedCategory($event.categoryDetails)"
    (onCloseModal)="closedModal()"
>
</classification-categories-modal>