<div class="header">
  <div class="modal-title"></div>
  <div class="header-menu-links">
    <div class="col-md-12 headerButtonsContainer centerBlock">
      <button *ngIf="!isGtin8()" type="button" class="header-menu-button barcode-btn" (click)="generateBarcode()"
              [disabled]="product.oldCodeGpc">
        <img src="assets/icons/upc.svg" alt="Créer code-barres"
             tooltip="Créer le codes-barres"><span>Créer le code-barres</span>
      </button>
      <button type="button" class="header-menu-button qrcode-btn ga-qr-code"
              (click)="openQRcodeModal()" [disabled]="product.oldCodeGpc">
        <img src="assets/icons/qr-code.svg" alt="Créer QR Code"
             tooltip="Créer le QR Code augmenté GS1"><span>Créer le QR Code augmenté GS1</span>
      </button>
    </div>

    <div class="col-md-3 visibilityContainer"
         *ngIf="featureToggleSvc.canSeeChangeVisibility() && !productsSvc.isRiskyProduct(productForm.productResellerType) && !product.oldCodeGpc">
      <div class="icon-help pull-right"
           matTooltip="Publier ce {{isBundle?'GTIN':'produit'}} rend visible ses informations clés aux adhérents ayant accès aux registres GS1."
           matTooltipClass="mat-tooltip-bottom-arrow-top-right">
      </div>
      <ui-switch class="pull-right" name="productVisibility" [checked]="!productForm.isPrivate"
                 (valueChange)="onVisibilityChange($event)">
      </ui-switch>

      <div *ngIf="!productForm.isPrivate" class="pull-right visibilityState">GTIN publié</div>
      <div *ngIf="productForm.isPrivate" class="pull-right visibilityState">GTIN non publié</div>
    </div>
  </div>
</div>

<div class="defineScrollbar">
  <div class="container col-md-12">
    <div class="contentHeaderContainer col-md-11 col-md-offset-1 negatePaddingLeft">
      <div class="leftContainer col-md-5">
        <div class="product-buttons">
          <button type="button" class="product-button" (click)="editProduct()" [disabled]="product.oldCodeGpc">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
              <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>
            <span>Modifier</span>
          </button>
          <button type="button" class="product-button" (click)="shareCIP()"
                  [disabled]="productForm.isPrivate || product.oldCodeGpc">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
              <path
                d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
            </svg>
            <span>Partager</span>
          </button>
          <button type="button" class="product-button" (click)="confirmationModal.openModal()"
                  [disabled]="product.oldCodeGpc" *ngIf="!environment.HIDE_DELETE_BTN_PRODUCT">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
            <span>Supprimer</span>
          </button>
        </div>
        <div class="imgContainer" *ngIf="showSubSection(extractUrlFromImage())">
          <div class="gradientImgContainer">
            <div class="imgCarousel" *ngIf="showSubSection(extractUrlFromImage())">
              <carousel>
                <slide *ngFor="let image of imagesToDisplay">
                  <div class="carousel-image">
                    <img src="{{image['url']}}">
                  </div>
                </slide>
              </carousel>
            </div>
          </div>
        </div>
        <div class="imgContainer" *ngIf="!showSubSection(extractUrlFromImage())">
          <div class="gradientImgContainer noPictureProduct">
            <img src="/assets/img/nopicture.png"/>
          </div>
        </div>
      </div>
      <div class="rightContainer col-md-6">
        <div class="titleContainer">
          <div class="textTitle">
            <span *ngIf="isNotNew">{{ notNewConstant }}</span>{{ productForm.getProductName(languageProductName) }}
          </div>
          <div>
                        <span *ngFor="let lang of productForm.getProductNamesLanguage();index as index;">
                            <span
                              [className]="languageProductName === lang ? 'lang-section selected-lang' : 'lang-section'"
                              (click)="languageProductName = lang">{{ lang }}</span>
                            <span
                              *ngIf="productForm.getProductNamesLanguage().length > 1 && index !== productForm.getProductNamesLanguage().length - 1"> | </span>
                        </span>

          </div>
        </div>
        <div class="categoryContainer">
          <div class="textInfo">
            Catégorie
          </div>
          <div class="textCategory textValue">
            {{ extractProductCategory() }}
          </div>
        </div>
        <div class="gtinContainer valueContainer">
          <div class="textInfo gtinInfo">
            GTIN (Global Trade Item Number)
          </div>
          <div class="textCategory textValue">
            <span class="gtixnValue">{{ productForm.gtin.toString() }}</span>
            <button
              class="copyGtinBtn"
              #btn
              (click)="clickCopyGtin($event, btn)"
              type="button"
              ngxClipboard
              [cbContent]="productForm.gtin.toString()"
              tooltip="{{ copyGtinToolTip }}"
              placement="right">
              <img src="assets/icons/icon-copy.png"/>
            </button>
          </div>
        </div>
        <div class="gtinContainer valueContainer">
          <div class="textInfo gtinInfo">
            PAYS DE VENTE
          </div>
          <div class="textCategory textValue">
            <span class="gtinValue">{{ constructTargetMarketWording() }}</span>
          </div>
        </div>
        <div class="brandContainer valueContainer">
          <div class="textInfo">
            Marque(s)
          </div>
          <div class="textBrand textValue" *ngIf="productForm.subBrand && !cannotSeeBrand(productForm)">
            {{ productForm.brand }} / {{ productForm.subBrand }}
          </div>
          <div class="textBrand textValue" *ngIf="!productForm.subBrand && !cannotSeeBrand(productForm)">
            {{ productForm.brand }}
          </div>
          <div class="textBrand textValue" *ngIf="cannotSeeBrand(productForm)">
            SANS MARQUE
          </div>
        </div>
        <div class="netContentContainer valueContainer">
          <div class="textInfo">
            Contenu net
          </div>
          <div class="textNetContent textValue">
            {{ constructNetContentWording() }}
          </div>
        </div>
        <div class="descriptionContainer valueContainer" *ngIf="productForm.description">
          <div class="textInfo">
            Description
          </div>
          <div class="textDescription textValue">
            {{ productForm.description }}
          </div>
        </div>
        <div class="skuContainer valueContainer" *ngIf="productForm.sku">
          <div class="textInfo">
            SKU
          </div>
          <div class="textSku textValue">
            {{ productForm.sku }}
          </div>
        </div>
        <div class="mpnContainer valueContainer" *ngIf="productForm.mpn">
          <div class="textInfo">
            MPN
          </div>
          <div class="textMPN textValue">
            {{ productForm.mpn }}
          </div>
        </div>
        <div class="valueContainer" *ngIf="productForm.originGtin">
          <div class="textInfo">
            GTIN d'origine
          </div>
          <div class="textValue">
            {{ productForm.originGtin }}
          </div>
        </div>
      </div>
    </div>
    <div class="gtinOwnerContainer col-md-12 negatePaddingLeft">
      <div class="valuesContainer">
        <div class="leftContainer row negatePaddingLeft">
          <div class="ownerNameContainer col-md-12">
            <div class="bigTextInfo">
              Les droits d'utilisation du GTIN sont détenus par :
              <div class="ownerName">{{ companySvc.company.companyName }}</div>
            </div>
          </div>
        </div>
        <div class="rightContainer row">
          <div class="ownerAdressContainer col-md-6">
            <div class="littleTextInfo">
              Adresse
            </div>
            <!--                        <div class="textValue">{{constructStructuredAddressWording()}}</div>-->
            <div
              class="textValue">{{ companySvc.company.street }} {{ companySvc.company.city }} {{ companySvc.company.cp }}
            </div>
          </div>
          <div class="glnContainer col-md-6">
            <div class="littleTextInfo">Global Location Number (GLN)</div>
            <div class="textValue">{{ companySvc.company.gln }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<confirmation-modal [isDeleteDialog]="true" #confirmationModal [cancelBtnLabel]="'Annuler'" [submitBtnLabel]="'Valider'"
                    [title]="constants.deleteProduct.title" [body]="constants.deleteProduct.message"
                    (onConfirm)="deleteProduct()"></confirmation-modal>

<share-modal #shareModal [url]="shareUrl" [isBundle]="isBundle"></share-modal>
<qr-code-creation></qr-code-creation>
