<div class="ProductCreationFood">

<composition-step *ngIf="currentStep == 1" [hasDrainedWeight]="unityForm.hasDrainedWeight"
                  (onHasDrainedWeightChanged)="drainedWeightChangedHandler($event)"
                  [hasAlcohol]="unityForm.hasAlcohol"
                  (onHasAlcoholChanged)="alcoholChangedHendler($event)"
                  [productForm]="unityForm.productForm"
                  (onNext)="onNext($event.submitting)"
                  (onPrevious)="onPrevious($event.submitting)"></composition-step>
<diet-step *ngIf="currentStep == 2" [productForm]="unityForm.productForm"  (onNext)="onNext($event.submitting)" (onPrevious)="onPrevious($event.submitting)"></diet-step>
<complementary-info-step *ngIf="currentStep == 3" [productForm]="unityForm.productForm"  (onNext)="onNext($event.submitting)" (onPrevious)="onPrevious($event.submitting)"></complementary-info-step>
<origin-step *ngIf="currentStep == 4" [productForm]="unityForm.productForm"  (onNext)="onNext($event.submitting)" (onPrevious)="onPrevious($event.submitting)"></origin-step>

</div>
