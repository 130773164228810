<div class="DashboardProducts">
  <!--Dashboard alert-->
  <div class="DashboardProducts-alert">
    <gs1-alert [showIcon]="false" [type]="'info'" *ngIf="productsSvc.totalProductsWithOldGpcCode>0">
      <ng-container><p ><span class="dashboardAlert-text">  Important : mise à jour du système de classification des produits GS1 (GPC)<br>Suite à une demande de nos utilisateurs d’une catégorisation plus fine, une mise à jour de certains de vos produits est nécéssaire.
  <span>Vous avez {{ productsSvc.totalProductsWithOldGpcCode}} produit(s) à mettre à jour</span>.
      </span><span class="dashboardAlert-list"><ul><li>Pour mettre à jour <u>un produit</u>, veuillez cliquer sur l’icône « <img
        src="assets/icons/arrow-clockwise-1.svg"> » à droite sur la ligne de votre produit afin de choisir la nouvelle catégorie correspondante.</li><li>Pour mettre à jour <u>plusieurs produits</u> simultanément, vous pouvez télécharger le fichier d’export avec les produits concernés <a (click)="openUpdateGpcPopup()"><u>en cliquant ici.</u></a> <br>    Renseignez les nouvelles catégories correspondantes, puis réimportez-le sur CodeOnline.</li></ul></span>
      </p></ng-container>
    </gs1-alert>
    <div  [showIcon]="false"  *ngIf="import" (click) ="redirectToImport()" class="alert" role="alert"> Une importation de vos produits est en cours. Cliquez-ici pour suivre sa progression...
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
      </div>
  <!--Dashboard stats-->
  <div class="DashboardProducts-stats">
    <div style="display:inline-block;">
      <div class="DashboardProducts-stats-content">
        <div>
          <img src="assets/icons/upc-scan.svg" alt="">
          {{productsSvc.totalProducts | number | spaceDivider}} {{companySvc.company.newGtin8Available > 0 ? 'GTIN-13' : 'code(s)'}}
          <span *ngIf="companySvc.company.codesAvailable">
                        {{productsSvc.totalProducts > 1 ? 'utilisés' : 'utilisé'}}
            sur {{companySvc.company.codesAvailable | number | spaceDivider}} {{companySvc.company.codesAvailable > 1 ? 'disponibles' : 'disponible'}}
                    </span>
        </div>
        <div>
          <loader class="DashboardProducts-stats-content-loader"
                  [color]="'blue'" [hidden]="!actionInProgress" [size]="'25px'"></loader>
        </div>
      </div>
      <div class="DashboardProducts-stats-content new-gtin8" *ngIf="companySvc.company.newGtin8Available > 0">
        <div>
          <img src="assets/icons/upc-scan-purple.svg" alt="">
          {{productsSvc.totalNewGtin8Products | number | spaceDivider}} GTIN-8
          <span>
                        {{productsSvc.totalNewGtin8Products > 1 ? 'utilisés' : 'utilisé'}}
            sur {{companySvc.company.newGtin8Available | number | spaceDivider}} {{companySvc.company.newGtin8Available > 1 ? 'nouveaux codes disponibles' : 'nouveau code disponible'}}
                    </span>
        </div>
        <div>
          <loader class="DashboardProducts-stats-content-loader"
                  [color]="'blue'" [hidden]="!actionInProgress" [size]="'25px'"></loader>
        </div>
      </div>
    </div>
    <!--When 90% of the codes are attributed-->
    <div *ngIf="hasCodeLimitAlmostFull()">
      <div class="DashboardProducts-stats-content-warning info-block">
        <img src="assets/icons/warningTriangleWhite.svg" alt="">
        <span>{{constants.codesLimit.almostFull}}</span>
      </div>
    </div>
    <!--When 100% of the codes are attributed-->
    <div *ngIf="hasCodeLimitFull()">
      <!--            <div class="DashboardProducts-stats-content-warning warning-block">-->
      <!--                <img src="assets/icons/warningTriangleWhite.svg" alt="">-->
      <!--                <span>{{constants.codesLimit.full}}</span>-->
      <!--            </div>-->
      <danger-alert title="Préfixe supplémentaire requis" [cssClass]="'col-sm-10 pull-right alert-box'"
                    [withIcon]="false">
        Vous n'avez plus de code GTIN disponible. <br/>
        Vous souhaitez codifier de nouveaux produits ? Rendez-vous sur <a href="{{clientSpaceUrl}}" target=”_blank”>votre
        espace client</a> pour commander un préfixe entreprise.
      </danger-alert>
    </div>
  </div>

  <!--Dashboard buttons and searchbar-->
  <div class="DashboardProducts-header">
    <div class="row">
      <div class="col-xs-12 nopadding">
        <div class="DashboardProducts-header-search col-xs-3 ">
          <div class="DashboardProducts-header-search-input">
            <button class="DashboardProducts-header-search-input-button " type="button"
                    (click)="searchProducts(searchText)">
              <img src="assets/icons/search2.svg">
            </button>
            <input [(ngModel)]="searchText" (keyup)="onSearchChange(searchText)"
                   placeholder="Rechercher un nom, code..."/>
          </div>
        </div>

        <div class="DashboardProducts-header-import-export col-xs-3">
          <button type="button"
                  class="DashboardProducts-header-menu-button normalButtonWidth dashboard-export-btn new-import-btn radius-Btn"
                  (click)="ImportMyGtins()">
            <img src="assets/icons/cloud-upload2.svg" class="btn-img-create" alt="create">
            <span class="btn-text">
                        Importer
                        </span>
                    </button>
                    <button type="button"
                            class="DashboardProducts-header-menu-button normalButtonWidth dashboard-export-btn new-export-btn radius-Btn"
                            (click)="exportSelectedProducts()"
                            [disabled]="productsSvc.myProducts.length < 1"
                    *ngIf="this.productsSvc.totalProducts || productsSvc.totalNewGtin8Products">
                        <img src="assets/icons/box-arrow-in-down2.svg" class="btn-img-create" alt="create">
                        <span class="btn-text">
                        Exporter
                        </span>
          </button>
        </div>

                <div class="DashboardProducts-header-menu pull-right">
                    <button type="button" *ngIf="this.productsSvc.totalProducts|| productsSvc.totalNewGtin8Products"
                            class="DashboardProducts-header-menu-button normalButtonWidth update-import-btn radius-Btn"
                            (click)="updateProducts()">
                            <img src="assets/icons/arrow-clockwise.svg" class="btn-img-create" alt="create">
                        <span class="btn-text">
                        Mettre à jour mes produits
                        </span>
          </button>
          <div class="btn-group" dropdown container="body" placement="bottom left">
            <button type="button" dropdownToggle id="button-basic" #createMantGtinBtn
                    class="DashboardProducts-header-menu-button smallButtonWidth dashboard-create-btn radius-Btn">
              <img src="assets/icons/plus_1.svg" class="btn-img-create" alt="create">
              <span class="btn-text">Nouveau</span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-maj-base dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
              <li role="menuitem" class="create-one-gtin" (click)="createProduct()">
                <a class="dropdown-item">Créer un GTIN</a>
              </li>

              <!--[disabled]="productsSvc.totalProducts >= companySvc.company.codesAvailable"-->
              <li role="menuitem" id="create-many-gtin" class="create-many-gtin" (click)="createGtins()">
                <a class="dropdown-item">Créer plusieurs GTIN</a>
                <span>(Création en masse)</span>
              </li>
            </ul>
          </div>
          <div class="DashboardProducts-header-menu-button-dropdown dropdown">
            <!-- <button type="button"
                    class="DashboardProducts-header-menu-button normalButtonWidth dashboard-export-btn radius-Btn"
                    (click)="exportSelectedProducts()"
                    [disabled]="productsSvc.myProducts.length < 1">
            <span class="btn-text">
            Exporter
            </span>
            </button> -->
          </div>
          <!--                    <div class="delimiter"></div>-->
          <!--                    <button type="button" class="DashboardProducts-header-menu-button-barcode radius-Btn"-->
          <!--                            (click)="generateCode()"-->
          <!--                            [disabled]="nbProductsSelected !== 1">-->
          <!--                        <img src="assets/icons/generateCodeWhite.svg" alt="Créer code-barres"-->
          <!--                             tooltip="Créer le codes-barres"><span style="vertical-align: middle;">&nbsp;Créer le code-barres</span>-->
          <!--                    </button>-->

        </div>


      </div>
    </div>
  </div>


  <!--Products list-->
    <div class="DashboardProducts-list grid">

      <!--List header-->
      <div class="DashboardProducts-list-header grid-row">
        <div class="DashboardProducts-list-item--select grid-cell">
          <input type="checkbox" [ngModel]="allSelected" (change)="selectAllProducts(!allSelected)">
        </div>
        <div class="DashboardProducts-list-item--flag grid-cell" (click)="sortList('important')">
          <img src="assets/icons/flag.svg" alt="">
          <div class="sortArrow" *ngIf="productsSvc.sortConf.field == 'important'"
               [ngClass]="{'sortUp': productsSvc.sortConf.ascending, 'sortDown': !productsSvc.sortConf.ascending}"></div>
        </div>
        <div class="DashboardProducts-list-item--name grid-cell" (click)="sortList('productNames')">
          Nom du produit
          <div class="sortArrow" *ngIf="productsSvc.sortConf.field == 'productNames'"
               [ngClass]="{'sortUp': productsSvc.sortConf.ascending, 'sortDown': !productsSvc.sortConf.ascending}"></div>
        </div>
        <div class="DashboardProducts-list-item--brand grid-cell" (click)="sortList('brand')">
          Marque
          <div class="sortArrow" *ngIf="productsSvc.sortConf.field === 'brand'"
               [ngClass]="{'sortUp': productsSvc.sortConf.ascending, 'sortDown': !productsSvc.sortConf.ascending}"></div>
        </div>
        <div class="DashboardProducts-list-item--sku grid-cell" (click)="sortList('sku')">
          Code interne ou SKU
          <div class="sortArrow" *ngIf="productsSvc.sortConf.field === 'sku'"
               [ngClass]="{'sortUp': productsSvc.sortConf.ascending, 'sortDown': !productsSvc.sortConf.ascending}"></div>
        </div>
        <div class="DashboardProducts-list-item--gtin grid-cell" (click)="sortList('gtin')" style="padding-left: 40px;">
          GTIN
          <div class="sortArrow" *ngIf="productsSvc.sortConf.field === 'gtin'"
               [ngClass]="{'sortUp': productsSvc.sortConf.ascending, 'sortDown': !productsSvc.sortConf.ascending}"></div>
        </div>
        <div class="DashboardProducts-list-item--update-gpc grid-cell" *ngIf="productsSvc.totalProductsWithOldGpcCode">
          Mise à jour GPC
        </div>
      </div>
  
      <!--List body-->
      <div id="DashboardProducts-list-content" class="DashboardProducts-list-content" id="scrollContainer"
           [ngClass]="{'heightWhenCodeLimitMessageDisplayed': hasCodeLimitAlmostFull() || hasCodeLimitFull()}"
           [hidden]="!productsSvc.productsLoaded || productsSvc.myProducts.length == 0"
           infiniteScroll
           [infiniteScrollDistance]="2"
           [infiniteScrollThrottle]="500"
           (scrolled)="getNextProducts()"
           [scrollWindow]="false">
  
        <div *ngFor="let product of getProductName(productsSvc.myProducts); let i = index"
             class="DashboardProducts-list-item grid-row"
             [ngClass]="{'selected': product.selected}">
  
          <div class="DashboardProducts-list-item--select grid-cell">
            <input type="checkbox" [ngModel]="product.selected" (change)="selectProduct(product)">
          </div>
          <button class="DashboardProducts-list-item--flag grid-cell" (click)="flagImportant(product)">
            <img src="assets/icons/flag.svg" alt="" [hidden]="product.important">
            <img src="assets/icons/flagActive.svg" alt="" [hidden]="!product.important">
            <div class="sortArrow" *ngIf="productsSvc.sortConf.field == 'important'"></div>
          </button>
          <div class="grid-inner-row edition-link" (click)="viewSelectedProduct(product)">
            <div class="DashboardProducts-list-item--name grid-cell" title="{{product.name}}">
              {{product.name}}
            </div>
            <div class="DashboardProducts-list-item--brand grid-cell" title="{{product.brand}}">
              {{product.brand}}
            </div>
            <div class="DashboardProducts-list-item--sku grid-cell" title="{{product.sku}}">{{product.sku}}
            </div>
            <div class="DashboardProducts-list-item--gtin grid-cell">
              {{product.gtin}}
              <button
                id="copyGtinBtn_{{product.gtin}}"
                class="copyGtinBtn"
                #btn
                (click)="clickCopyGtin($event, btn)"
                type="button"
                ngxClipboard
                [cbContent]="product.gtin"
                tooltip="{{ copyGtinToolTip }}"
                placement="left"
              >
                <img src="assets/icons/icon-copy.png"/>
              </button>
  
              <!-- (ngbTooltip)="tipContent" -->
              <!-- matTooltip="{{ copyGtinToolTip }}" -->
              <!-- tooltip-placement="bottom" -->
            </div>
            <div class="DashboardProducts-list-item--update-gpc grid-cell"  (click)="updateCategory($event, product)" *ngIf="productsSvc.totalProductsWithOldGpcCode">
              <img src="assets/icons/arrow-clockwise-1.svg" *ngIf="product.oldCodeGpc">
            </div>
          </div>
        </div>
  
        <!--Results limit reached-->
        <div class="DashboardProducts-searchLimit"
             *ngIf="searchText.length !== 0 && productsSvc.myProducts.length === constants.searchLimit.value">
          {{constants.searchLimit.warningReached}}
        </div>
      </div>
  
      <!--Dashboard loader-->
      <div class="DashboardProducts-loader text-center" *ngIf="!productsSvc.productsLoaded">
        <loader [color]="'orange'" [size]="'40px'"></loader>
        <span>Chargement en cours</span>
      </div>
  
      <!--No results for the search-->
      <div class="DashboardProducts-searchEmpty"
           *ngIf="productsSvc.productsLoaded && (productsSvc.myProducts.length === 0 && searchText.length !== 0)">
        {{constants.searchEmpty}}
      </div>
  
      <!--No products yet-->
      <div class="DashboardProducts-list-content"
           *ngIf="productsSvc.productsLoaded && productsSvc.totalProducts === 0 && searchText.length === 0">
        <div class="DashboardProducts-noProduct">
          <div class="DashboardProducts-noProduct-title">
            {{constants.noProProduct.title}}
          </div>
          <div class="DashboardProducts-noProduct-subtitle">
            {{constants.noProProduct.subtitle}}
          </div>
  
          <div class="DashboardProducts-noProduct-body" [innerHTML]="constants.noProProduct.body.firstText">
          </div>
          <button type="button" class="DashboardProducts-noProduct-button" (click)="importProducts()">
            <img src="assets/icons/ico_import_orange.svg"
                 alt="{{constants.noProProduct.actions.importProducts}}">
            <br/>{{constants.noProProduct.actions.importProducts}}
          </button>
  
          <div class="DashboardProducts-noProduct-body" [innerHTML]="constants.noProProduct.body.secondText">
          </div>
  
          <button type="button" class="DashboardProducts-noProduct-button" (click)="createProduct()">
            <img src="assets/icons/plus.svg" alt="{{constants.noProProduct.actions.createProduct}}">
            <br/>{{constants.noProProduct.actions.createProduct}}
          </button>
        </div>
        
      </div>
      
      
    </div>
    
  <product-export-modal (onCloseModal)="onCloseExportAction()"></product-export-modal>
  <product-import-modal (onImportProducts)="importProducts()"></product-import-modal>
</div>
<!--<new-feature-information-modal *ngIf="featureToggleSvc.showNewFeatureOnBoarding()"></new-feature-information-modal>-->


