const DOCUMENT_URL_PREFIX = 'https://documents.codeonline.fr';
const CLIENT_SPACE = 'https://preprod-monespaceclient.gs1.fr';

export const environment = {
  production: false,
  BANNER_FILE_URL: '/alerts.json',
  API_URL: 'https://preprod-api.codeonline.fr/api',
  GTIN_URL: 'https://preprod-codeonline-gtin.gs1.fr/',
  SSO_REDIRECT_URL: 'https://preprod-codeonline-gtin.gs1.fr/',
  SSO_LOGOUT_REDIRECT_URL: 'https://preprod-codeonline-gtin.gs1.fr/',
  SSO_DOMAIN: 'https://preprod-connect1.gs1.fr',
  SSO_CLIENT_ID: '929fde43-71fb-451d-9a25-239271c97fa1_CodeOnline',
  SHARE_URL_BASE: 'https://preprod-codeonline-gtin.gs1.fr',
  INVOICE_URL: CLIENT_SPACE + '/factures',
  APP_SYNC_URL: '',
  APP_SYNC_API_KEY: '',

  title: 'GS1 - CodeOnline',
  meta_description: 'CodeOnline, le service de GS1 France qui vous permet de créer vos codes GTIN, leurs symboles codes-barres et de générer leurs cartes d’identité.',
  meta_title: 'La plateforme en ligne pour générer vos GTIN et code-barres',
  baseUrl: '/',
  DOCUMENT_URL_PREFIX: 'https://documents.codeonline.fr',
  IMPORT_CSV_TEMPLATE_URL: DOCUMENT_URL_PREFIX + '/Modele_GS1.csv',
  IMPORT_XLS_TEMPLATE_URL: DOCUMENT_URL_PREFIX + '/Modele_GS1.xls',
  CGU_URL: DOCUMENT_URL_PREFIX + '/Conditions+G%C3%A9n%C3%A9rales+Utilisation+CodeOnline+-+20171003.pdf',
  GUIDE_URL: 'https://www.gs1.fr/publication/codeonline-mode-demploi',
  FOCUS_URL: DOCUMENT_URL_PREFIX + '/Les_règles_GS1_identification_des_produits_pour_marketplaces_0921.pdf',
  SEVEN_STEP_URL: DOCUMENT_URL_PREFIX + '/Le code-barres EAN 13, mes premiers pas.pdf',
  TEMPORARY_URL_PREFIX: 'https://tmp.codeonline.fr',
  IMPORT_TEMPLATE_WINE_URL: DOCUMENT_URL_PREFIX + '/Modele_import_vins_GS1__France_preprod.xlsx',
  IMPORT_TEMPLATE_SPIRIT_URL: DOCUMENT_URL_PREFIX + '/Modele_import_spirit_GS1_France.xlsx',
  OPENDATA_TECHNICAL_DOC: DOCUMENT_URL_PREFIX + '/Documentation_technique_opendata_gs1france.zip',
  OPENDATA_LICENCE_DOC: DOCUMENT_URL_PREFIX + '/ETALAB-Licence-Ouverte-v2.0-2 GS1 France.pdf',
  IMPORT_TEMPLATE_GENERIC_URL: DOCUMENT_URL_PREFIX + '/Modele_import_GS1__France-dev.xlsx',
  IMPORT_TEMPLATE_BUNDLE_URL: DOCUMENT_URL_PREFIX + '/Modele_import_GS1__France_bundles.xlsx',
  CGU_PDF: DOCUMENT_URL_PREFIX + '/Conditions_generales_utilisation_CodeOnline.pdf',
  IMPORT_TEMPLATE_NOTNEW_URL: DOCUMENT_URL_PREFIX + '/Modele_import_GS1__France_Not_New_preprod.xlsx',
  GCP_PAGE: CLIENT_SPACE + '/codes/GCP',
  front_base_url: 'https://preprod-api.codeonline.fr',
  QR_BASE_URL: 'https://preprod-id.gs1.fr',
  HIDE_DELETE_BTN_PRODUCT: false
};
