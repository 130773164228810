<div class="ProductChoiceCreation" [ngClass]="{ 'hide-background' : currentStep == 4 || currentStep == 2 || currentStep == 1}">
    <product-creation-generic-first-step *ngIf="currentStep == 1" [unityForm]="unityForm"
                                         [submitting]="unityForm.submitting"
                                         (onNext)="onNext($event.submitting, $event.adhereToTheSectoralAgreement)"
                                         (onPrevious)="onPrevious($event.submitting)"
                                         [selectedCategory]="selectedCategory" (onModifyCategory)="modifyCategory()"
                                         [productType]="productType"
                                         [isSpi]="isSpi" [isWine]="isWine" [isBundle]="isBundle" [isNotNew]="isNotNew"
                                         [gpcCodes]="gpcCodes">
    </product-creation-generic-first-step>
    <second-step *ngIf="currentStep == 2" [unityForm]="unityForm"
                 [submitting]="unityForm.submitting"
                 (onNext)="onNext($event.submitting, $event.adhereToTheSectoralAgreement, $event.forceToGoToLastStep)"
                 (onPrevious)="onPrevious($event.submitting)" [productType]="productType" [isSpi]="isSpi"
                 [isWine]="isWine"
                 (onPrefixChange)="onPrefixChange($event.prefix)"
                 [cipLoaded]="unityForm.cipLoaded" ></second-step>
    <third-step *ngIf="currentStep == 3" [unityForm]="unityForm"
                (onNext)="onNext($event.submitting,  $event.adhereToTheSectoralAgreement)"
                [productType]="productType"
                (onPrevious)="onPrevious($event.submitting)" [isSpi]="isSpi"
                [isWine]="isWine"
                [submitting]="unityForm.submitting" [gpcCodes]="gpcCodes">
    </third-step>
    <final-step *ngIf="currentStep == 4" [unityForm]="unityForm"
                [submitting]="unityForm.submitting"
                [productType]="productType"
                (onNext)="onNext($event.submitting, $event.adhereToTheSectoralAgreement)"
                (onPrevious)="onPrevious($event.submitting)" [isSpi]="isSpi"
                [isWine]="isWine" (onPrefixChange)="onPrefixChange($event.prefix)"
                [cipLoaded]="unityForm.cipLoaded"
                (onReturnToSectoralStep)="forceReturnToSectoralStep()"
                (onReturnToFirstStep)="forceReturnToFirstStep()"></final-step>
</div>

<confirmation-modal #confirmationModal [cancelBtnLabel]="'Non'" [submitBtnLabel]="'Oui'"
                    [title]="constants.createForm.infoPopIn.title" [body]="constants.createForm.infoPopIn.message" (onConfirm)="onConfirmModal()">
</confirmation-modal>
<app-validation-creation-product (confirmEvent)="validCreation($event)"></app-validation-creation-product>

