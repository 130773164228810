<div class="Unity Consumer defineScrollbar">
    <form (ngSubmit)="submitForm(false, null)"  #formProduct="ngForm" ngNativeValidate>
        <div class="Unity-header">
            <div class="modal-title"></div>
        </div>
        <div class="Unity-body">
            <!-- FORM -->
            <div class="Unity-form container-fluid">
                <!-- food section -->
                <div class="Unity-form-section row">
                    <div class="Unity-form-section-name col-xs-12">Pays d'origine</div>
                    <!-- structuredAddress -->
                    <div class="Unity-form-section-content row" id="originCountryContainer">
                        <div class="Unity-form-subSection countryContainer col-xs-4">
                            <div class="Unity-form-input">
                                <label for="countryOfOrigin">Pays d'origine </label><span
                                    class="Unity-form-input--required"> (Obligatoire)</span>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.countryOfOrigin.title}}"
                                     popover="{{constants.inputsInfo.countryOfOrigin.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                                <div class="Unity-form-input row">
                                    <ng-selectize id="countryOfOrigin" name="countryOfOrigin"
                                                  [config]="countryOfOriginConfig"
                                                  [options]="countryOfOriginOptions"
                                                  [(ngModel)]="productForm.countryOfOrigin"
                                                  (ngModelChange)="selectizeChange()"
                                                  ngDefaultControl></ng-selectize>
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection col-xs-8 exploitantInfos">
                            <div class="Unity-form-section-name col-md-10 col-xs-12">Informations sur l'exploitant</div>
                            <div class="Unity-form-input col-xs-12">
                                <div class="row socialNameContainer">
                                    <!-- contactName -->
                                    <label for="contactName">
                                        Nom / Raison sociale de l’exploitant
                                        <span class="Unity-form-input--required"
                                              *ngIf="isRequiredStructuredAddress()">(Obligatoire)</span>
                                    </label>
                                    <div class="icon-help"
                                         popoverTitle="{{constants.inputsInfo.contactName.title}}"
                                         popover="{{constants.inputsInfo.contactName.content}}"
                                         placement="right"
                                         triggers="mouseenter:mouseleave">
                                    </div>
                                    <input id="contactName" maxlength="500"
                                           [(ngModel)]="productForm.contactName"
                                           name="name" #name="ngModel"
                                           placeholder="{{constants.foodCreateForm.placeholders.contactName}}"
                                           cleanInput
                                           [required]="isRequiredStructuredAddress()">
                                </div>
                            </div>
                            <div class="Unity-form-input col-xs-12 mt-3 addressContainer">
                                <!--Name-->
                                <label for="structuredAddress">
                                    Adresse de l’exploitant
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.structuredAddress.title}}"
                                     popover="{{constants.inputsInfo.structuredAddress.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <input id="streetAddress" maxlength="500"
                                               [(ngModel)]="productForm.structuredAddress.streetAddress"
                                               name="streetAddress" #name="ngModel"
                                               [required]="isRequiredStructuredAddress()"
                                               placeholder="{{constants.foodCreateForm.placeholders.structuredAddress.streetAddress}}"
                                               style="height: 36.4px;"
                                               cleanInput>
                                    </div>
                                    <div class="col-md-6">
                                        <input id="city"
                                               [(ngModel)]="productForm.structuredAddress.city"
                                               name="city" #name="ngModel"
                                               [required]="isRequiredStructuredAddress()"
                                               placeholder="{{constants.foodCreateForm.placeholders.structuredAddress.city}}"
                                               cleanInput maxlength="200"
                                               style="height: 36.4px;"
                                        >
                                    </div>
                                    <div class="col-md-6">
                                        <input id="postalCode"
                                               [(ngModel)]="productForm.structuredAddress.postalCode"
                                               name="postalCode" #name="ngModel"
                                               [required]="isRequiredStructuredAddress()"
                                               placeholder="{{constants.foodCreateForm.placeholders.structuredAddress.postalCode}}"
                                               cleanInput maxlength="80"
                                               style="height: 36.4px;"
                                        >
                                    </div>

                                    <ng-selectize class="col-md-12" id="countryCode" name="countryCode"
                                                  [config]="countryCodeConfig" [options]="countryOfOriginOptions"
                                                  [(ngModel)]="productForm.structuredAddress.countryCode"
                                                  placeholder="{{constants.foodCreateForm.placeholders.structuredAddress.countryCode}}"
                                                  ngDefaultControl></ng-selectize>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="Unity-footer">
            <div class="completionLevel">
                <div class="barContainer">
                    <div class="completionBar" [style.width.%]="33.333 * (stepCount - 1)"></div>
                </div>
                <div class="currentStep">Étape {{stepCount}}/4</div>
            </div>
            <div class="Unity-footer-buttons" role="group">
                <button gs1Button [appearance]="'outlined'" (click)="returnBack()">Retour</button>
                <button gs1Button  (click)="checkRequiredInputs()">Suivant</button>
            </div>
        </div>
    </form>
</div>
