<div class="content"  [className]="product.adhereToTheSectoralAgreement ? 'content has-adhere-to-sectoral-agreement': 'content'">
    <div class="notice-head">
        <div class="title">
            Vous n'avez pas renseigné ces informations?
        </div>
        <div class="subtitle">
            En les renseignant vous pourrez créer un QR Code augmenté GS1, et ainsi ...
        </div>
    </div>
    <div class="notice-body">
        <div class="instruction">
          Vous conformer aux exigences réglementaires
        </div>

        <div class="instruction">
          Créer la carte d’identité complète du produit avec les informations nutritionnelles
        </div>

        <div class="instruction">
          Rendre accessible ces informations via un QR Code à imprimer sur les étiquettes
          et lisible sur les smartphones de vos consommateurs
        </div>
    </div>
    <div class="background-bottel">
        <img src="assets/img/vin.png" alt="">
    </div>
    <div class="notice-footer">
        <div class="footer-text" (click)="editProduct()">
            Remplir les informations <i class="bi bi-arrow-right"></i>
        </div>
    </div>
</div>

