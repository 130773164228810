<bs-modal size="lg" cssClass="customModal" #modal class="validation-modal" [keyboard]="false" [backdrop]="'static'">
  <bs-modal-body class="padding">
    <h2>Vous êtes sur le point de valider l’attribution de votre GTIN</h2>
    <p>
      Un fois terminé, vous ne pourrez plus supprimer les informations de votre produit.
    </p>
  </bs-modal-body>
  <bs-modal-footer>
    <button id="cancelButton" type="button" class="apply-button" (click)="close()">
      Vérifier les informations
    </button>
    <button id="confirmButton" type="button" class="cancel-button" (click)="confirm()">
      Terminer
    </button>
  </bs-modal-footer>
</bs-modal>
