<div class="row">
  <div class="col-md-6">
    <span (click)="gotoDashborad()" class="go-back"> <img src="assets/icons/back.svg"/>Retour</span>
  </div>

  <div class="col-md-6 forward" *ngIf="!hideSkipChoiceCategory && showForCreation">
    <span (click)="forward()" class="go-back"> Passer cette étape <img src="assets/icons/forward.svg"/></span>
  </div>
</div>
<div class="product-category defineScrollbar">
  <div class="row" style="padding-bottom: 100px;">
    <div class="col-md-4 search-container">
      <div class="row">
        <div class="col-md-12 title" *ngIf="!isBundle">
          <h2>Catégorie du produit</h2>
          <span>Identifiez la catégorie de votre produit parmi l’ensemble des catégories GPC.</span>
        </div>
        <div class="col-md-12 title" *ngIf="isBundle">
          <h2>Catégorie de l'assortiment</h2>
          <span>Veuillez sélectionner la catégorie des produits de l’assortiment.<br>
            Si les produits appartiennent à différents segments, choisissez « Assortiment inter Segments ».</span>
        </div>
        <div class="col-md-12 search-box">
          <label>{{isBundle ? "Catégorie" : "Catégorie (ex: café, tournevis, ...)"}}
            <div>
                            <span
                              class="search-box-required">(Obligatoire)</span>
              <div class="icon-help min"
                   popoverTitle="{{constants.inputsInfo.category.title}}"
                   popover="{{isBundle ?  'Le code de la catégorie de produit permet d\'identifier le type de produit. Rechercher une catégorie en tapant la désignation générique de l\'article' : constants.inputsInfo.category.content}}"
                   placement="right"
                   triggers="mouseenter:mouseleave">
              </div>
            </div>
          </label>

          <input id="categoryInput" class="col-md-11" type="text" [autofocus]="true"
                 (keyup)='onKeyupGpcSearchInput(gpcSearchInput.value)' #gpcSearchInput/>

        </div>
        <div class="col-md-12 linkContainer">
          <a id="classificationTree" class="findCategoryLink" (click)="navigateToClassification()" *ngIf="!isVcsUser()">Consulter
            l’ensemble des catégories.</a>
          <a id="classificationTree2" *ngIf="isVcsUser() && showForCreation" (click)="navigateToClassification()">Consulter
            l’ensemble des catégories.</a>
        </div>
        <div class="col-md-12 bookmark-categories" *ngIf="lastSelectedCategories.length > 0 &&  showForCreation">
          <label>Dernières catégories utilisées</label>
          <span class="category-item" tooltip="{{c.text}}" *ngFor="let c of lastSelectedCategories"
                (click)="saveSelectedCategory(c)">
                        {{c.text}}
                    </span>
        </div>
        <div class="col-md-12 bookmark-categories" *ngIf="isVcsUser() && showForCreation">
          <label>Vins et Spiritueux</label>
          <span class="category-item" tooltip="{{c.text}}" *ngFor="let c of vcsCategories"
                (click)="saveSelectedCategory(c)">
                        {{c.text}}
                    </span>
        </div>
      </div>

    </div>
    <div class="col-md-4 results-container">
      <ul class="col-md-12" [ngClass]="{'result-scroll': formatedCategories.length > 0}">
        <li *ngFor="let category of formatedCategories" (click)="showDetails(category)">
          <ul>
            <li [innerHTML]="category.formatedCategory"></li>
            <li class="result-breadcrumb">{{category.breadcrumbs}}</li>
          </ul>
        </li>
        <li *ngIf="noCategoryFound">
          Aucune catégorie ne correspond aux termes de recherche spécifiés
        </li>
        <li class="list-categories-link" *ngIf="noCategoryFound || formatedCategories.length>0 ">
          <a (click)="navigateToClassification()">Vous ne trouvez pas la catégorie de votre produit ?</a>
        </li>
      </ul>
    </div>
    <div class="col-md-4 details-container">
      <div class="col-md-12 details-box defineScrollbar" [hidden]="isPickedCategory === true || !categoryDetails.text"
           #resultDetailsContainer>
        <h2 class="col-md-12">{{categoryDetails.text}}</h2>
        <span class="col-md-12 code-category" *ngIf="showForCreation == true">
                        Code: {{categoryDetails.code}}
                </span>
        <span class="col-md-12" [innerHTML]="categoryDetails.highlightDefinition">

                </span>
        <div class="col-md-12 bookmark-category-actions">
          <!--                    Do not delete this code !!-->

          <!--                    <div class="col-md-6 bookmark-category" [ngClass]="{'is-bookmarked': hasCategory(categoryDetails)}"-->
          <!--                         (click)="bookmarkCategory(categoryDetails)">-->
          <!--                        <span>-->
          <!--                            <span *ngIf="!hasCategory(categoryDetails)">Ajoutez à vos favoris</span>-->
          <!--                                    <span *ngIf="hasCategory(categoryDetails)">Supprimez de vos favoris</span>-->

          <!--                        </span>-->
          <!--                    </div>-->

          <div class="col-md-6 text-center pull-right" *ngIf="showForCreation == true">
            <button class="select-btn" (click)="saveSelectedCategory(categoryDetails)">
              Selectionner
            </button>
          </div>

          <div class="row copy-action" *ngIf="showForCreation == false">
            <div class="col-md-12">
              <div class="row copy-action-form">
                <div class="col-md-3">
                  Code:
                </div>
                <div class="col-md-9">
                  <input #shareClassificationCode type="text" [value]="categoryDetails.code" readonly/>
                </div>
                <button class="copy-btn" [ngxClipboard]="shareClassificationCode">
                  Copier
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
