import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BarcodeDisclaimerModal, BarcodeModal} from './index';
import {BarcodeGenerationSvc, ProductsSvc} from './services/index';
import {CompanySvc, FeatureToggleSvc, UserSvc, CacheService} from '../shared/services/index';
import {Subscription} from 'rxjs';

const version = require('../../../package.json').version;


// creer un service
import {OnBoardingModal} from './components/onboarding-disclaimer-modal/onboarding-modal.component';
import {ClassificationModal} from './components/classification-modal/classification-modal.component';
import {NotificationsSvc} from '../shared/services';
import {SharedDashboardSvc} from './services/sharedDashboardSvc';
import {BannerComponent} from '../shared/components/banner/banner.component';
import {ConfirmationModal, Gs1Modal} from '../shared/components';
import {DashboardConst} from './models/dashboard.constants';
import {environment} from '../../environments/environment';
import {InvoiceSvc} from '../shared/services/invoice.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
    selector: 'dashboard',
    styleUrls: ['./dashboard.less'],
    template: `
        <div class='Dashboard'>
            <banner #banner></banner>
            <div class='Dashboard-container' [ngClass]="{'has-banner': banner.showBanner === true}">
                <div class='Dashboard-menu'>
                    <dashboard-lateral-menu></dashboard-lateral-menu>
                </div>
                <div class='Dashboard-content'>
                    <dashboard-header></dashboard-header>
                    <router-outlet></router-outlet>
                </div>
                <div class='Dashboard-menu'>
                    <dashboard-footer></dashboard-footer>
                </div>
            </div>
            <application-disclaimer-modal *ngIf="showDisclaimerModal"></application-disclaimer-modal>
            <barcode-modal #barcodeModal></barcode-modal>
            <barcode-disclaimer-modal #barcodeDisclaimerModal></barcode-disclaimer-modal>
            <classification-modal #classificationModal></classification-modal>
            <onboarding-modal (onCreate)="createProduct()" #onboardingModal></onboarding-modal>
            <confirmation-modal *ngIf="!featureToggle.showNewFeatureOnBoarding() && this.userSvc.user.authorizations['isBillingPeriod'] && this.hasUnpayedInvoice && !this.userSvc.user.noShowNPAlerte && this.userSvc.user.applicationDisclaimerAccepted" (onGoToInvoice)="goToInvoice()"
                                #confirmationModal [cancelBtnLabel]="'Non'" [submitBtnLabel]="'Oui'" [NPOption]="true" (onCloseModal)="onCloseNPAlert($event.noShow)"
                                [title]="constants.noPayer.warning.title" [body]="constants.noPayer.warning.body">
            </confirmation-modal>
            <invoice-modal *ngIf="!showDisclaimerModal"
                    (onGoToInvoice)="goToInvoice()"
                    #gs1Modal [cancelBtnLabel]="'Non'" [submitBtnLabel]="'Oui'" [NPOption]="false"
                    (onCloseModal)="onCloseNPAlert($event.noShow)"
                    [title]="constants.cache.popIn.title" [body]="constants.cache.popIn.body">
            </invoice-modal>
        </div>
    `
})
export class Dashboard {
    public constants = DashboardConst;
    @ViewChild('barcodeModal', {static: true})
    public barcodeModal: BarcodeModal;
    @ViewChild('barcodeDisclaimerModal', {static: true})
    public barcodeDisclaimerModal: BarcodeDisclaimerModal;
    @ViewChild('confirmationModal', {static: true})
    public confirmationModal: ConfirmationModal;
    @ViewChild(BannerComponent, {static: true})
    public banner: BannerComponent;
    @ViewChild('gs1Modal', {static: true})
    public gs1Modal: Gs1Modal;
    public showDisclaimerModal: boolean = false;
    public hasUnpayedInvoice = false;
    @ViewChild('classificationModal', {static: true})
    private classificationModal: ClassificationModal;
    @ViewChild('onboardingModal', {static: true})
    private onboardingModal: OnBoardingModal;

    private barcodeGenerationSubscription: Subscription;
    constructor(public productSvc: ProductsSvc,
                public userSvc: UserSvc,
                private router: Router,
                private barcodeSvc: BarcodeGenerationSvc,
                public companySvc: CompanySvc,
                public featureToggle: FeatureToggleSvc,
                public cacheService: CacheService,
                private notifSvc: NotificationsSvc,
                private route: ActivatedRoute,
                private sharedDashBoardSvc: SharedDashboardSvc,
                public invoiceSvc : InvoiceSvc,
                private authService : OidcSecurityService) {
        this.barcodeGenerationSubscription = this.barcodeSvc.generateBarcodeSource.subscribe(
            product => this.generateBarcode(product),
            error => console.error(error)
        );
    }

    public ngOnInit() {
        setInterval(() => {
            this.cacheService.checkNewVersionAvailable(version).then(() => {
                if (this.cacheService.showCachePopIn) {
                    this.gs1Modal.openModal();
                }
            });
        }, 60000);

        // Get company data
        this.companySvc.initCompany();
        // Get user data
        this.userSvc.getUser().then((user) => {
            if (user.applicationDisclaimerAccepted === false) {
                this.showDisclaimerModal = true;
                return;
            }
            this.userSvc.setUser(user);
            if (this.featureToggle.canSeeOnBoarding() && !this.userSvc.user.onBording) {
                this.onboardingModal.openModal();
                this.userSvc.updateUser({onBording: true});
            }
            this.banner.loadBanner();
        });
        this.sharedDashBoardSvc.change.subscribe(() => {
            this.createProduct();
        });
        this.invoiceSvc.gethasUnpayedInvoice(this.authService.getUserData().company_id).subscribe( (data) => {
            this.hasUnpayedInvoice = data;
        });
    }

    public ngAfterViewInit() {

    }

    public createProduct() {
        if (!this.companySvc.company.id) {
            return this.notifSvc.companyMissing();
        }
        this.router.navigate(['./product/create'], {relativeTo: this.route});
        return;
        // if (this.featureToggle.showNewCreationWay()) {
        //   this.router.navigate(['./product/create'], {relativeTo: this.route});
        //   return;
        // }
        //
        // if (this.featureToggle.canSeeVcs()) {
        //   this.classificationModal.show();
        //   return;
        // }
        //
        // this.router.navigate(['../dashboard/creation'], {relativeTo: this.route});

    }

    public ngOnDestroy() {
        this.barcodeGenerationSubscription.unsubscribe();
    }

    public generateBarcode(productId: string) {
        this.productSvc.getProduct(productId)
            .subscribe(
                product => {
                    if (!this.userSvc.user.barcodeDisclaimerAccepted) {
                        this.barcodeDisclaimerModal.openModal(product);
                        return;
                    }
                    return this.barcodeModal.openModal(product);
                },
                err => console.error(err)
            );
    }

    public goToInvoice() {
        window.open(environment.INVOICE_URL, '_blank');
    }

    public showAlerte() {
        return !this.featureToggle.showNewFeatureOnBoarding() && this.companySvc.company.isNonPayer && !this.userSvc.user.noShowNPAlerte;
    }

    public onCloseNPAlert(noShow) {
        if (noShow) {
            this.userSvc.updateUser({noShowNPAlerte: true});
        }
    }
}
