<div class="row">
  <div class="col-md-6">
    <span (click)="goBack()" class="go-back"> <img src="assets/icons/back.svg" alt="retour"/>Retour</span>
  </div>
</div>
<div class="product-reseller" id="product-reseller">
  <div class="body-container" id="body-container" [ngClass]="{'has-banner': bannerSvc.canShowBanner === true}">
    <div class="row">
      <div class="col-md-12 choice-container">
        <div class="row">
          <div class="col-md-12 title">
            <h2 *ngIf="showChoiceBox===true">Vous souhaitez identifier</h2>
            <div *ngIf="showChoiceBox===false">
              <h2>Déclaration sur l'honneur</h2>
              <h3 *ngIf="isImport">Je certifie sur l'honneur avoir tout essayé pour trouver les GTIN de
                mes produits :</h3>
              <h3 *ngIf="!isImport">Je certifie sur l'honneur avoir tout essayé pour trouver le GTIN de
                mon produit :</h3>
            </div>

          </div>
          <div class="col-md-4 choice-box" *ngIf="showChoiceBox===true">
            <div class="choice" *ngFor="let choice of (isImport?choicesMass:choicesUnitary); let i = index">
              <input #choice type="radio" id="choice_{{i}}" name="questions" [(ngModel)]="questionValue"
                     [value]="choice.key" (click)="changeChoice(choice)">
              <label for="choice_{{i}}"> {{ choice.value }}</label>
              <div class="new-feature" *ngIf="displayTag(i)">
                Nouveauté
              </div>
              <div class="icon-help min"
                   popoverTitle="{{choice.tip_title}}"
                   popover="{{choice.tip}}"
                   placement="right"
                   triggers="mouseenter:mouseleave">
              </div>
            </div>

          </div>
          <div class="col-md-12 certification-box" *ngIf="showChoiceBox===false">
            <div class="choice"
                 *ngFor="let certification of(isImport?massCertifications:certifications); let i = index"
                 (click)="scrollToAlertwhenAllchecked()">
              <input type="checkbox" id="certif_{{i}}" [(ngModel)]="certification.checked" value=true>
              <label for="certif_{{i}}"> {{ certification.value }}</label>
            </div>

          </div>
          <div class="col-md-8 alert-box" *ngIf="allCertificationChecked() || massCertificationChecked() "
               id="alert">
            <div class="title">
              <span>{{ isImport ? DashboardConst.reseller.alertImport.title : DashboardConst.reseller.alert.title }}</span>
            </div>
            <div class="body"
                 [innerHTML]="isImport ? DashboardConst.reseller.alertImport.body : DashboardConst.reseller.alert.body">
            </div>
          </div>
          <div class="col-md-7 warning-box" *ngIf="questionValue !== null && showChoiceBox===true"
               id="warningBox">
            <div class="title">
              <img src="assets/icons/info-circle.svg" alt="titre">
              <span>{{ returnTitle() }}</span>
            </div>
            <div *ngIf="!isImport">
              <div class="body" *ngIf="questionValue === brandTypes.OWN_BRAND">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span><br/><br/>
                <span>Seul le propriétaire de la marque commerciale ou <a
                  popoverTitle="{{popoverConfig.title}}"
                  popover="{{popoverConfig.description}}"
                  placement="right"
                  triggers="mouseenter:mouseleave">le détenteur des spécifications techniques</a> est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span>
              </div>


              <div class="body" *ngIf="questionValue === brandTypes.THIRD_PARTY_BRAND">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span>
                <br/> <br/>
                <span>Seul le propriétaire de la marque commerciale ou <a
                  popoverTitle="{{popoverConfig.title}}"
                  popover="{{popoverConfig.description}}"
                  placement="right"
                  triggers="mouseenter:mouseleave">le détenteur des spécifications techniques</a> est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span><br/><br/>
                <span><b>Par conséquent,  vous n’êtes pas habilité à codifier ce produit. Nous vous invitons à rechercher le GTIN afin de ne pas créer un doublon.</b></span>
              </div>

              <div class="body" *ngIf="questionValue === brandTypes.WITHOUT_BRAND">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span>
                <br/><br/>
                <span>Seul le fabricant ou <a
                  popoverTitle="{{popoverConfig.title}}"
                  popover="{{popoverConfig.description}}"
                  placement="right"
                  triggers="mouseenter:mouseleave">le détenteur des spécifications techniques</a> est habilité à codifier les produits.<b> Codifier à sa place est contraire aux règles de codification GS1.</b></span>
              </div>

              <div class="body" *ngIf="questionValue === brandTypes.BUNDLE">
                <span>Seuls les assortiments composés par des produits physiquement reliés doivent être codifiés.</span>
                <br/><br/>
                <span>Si tous les produits de l’assortiment sont d’une même marque tierce, vous devez vérifier avec le propriétaire de cette marque commerciale si un GTIN n’existe pas déjà pour cet assortiment. Recodifier un assortiment déjà codifié est contraire aux règles GS1.</span>
              </div>

              <div class="body" *ngIf="questionValue === brandTypes.NOT_NEW">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span>
                <br/> <br/>
                <span>Un produit non neuf peut être identifié avec un nouveau GTIN si vous
                            ou un de vos partenaires commerciaux avez besoin de le différencier du même produit neuf.</span>
              </div>
            </div>

            <div *ngIf="isImport">
              <div class="body" *ngIf="questionValue === brandTypes.OWN_BRAND">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span><br/><br/>
                <span>Seul le propriétaire de la marque commerciale ou <a
                  popoverTitle="{{popoverConfig.title}}"
                  popover="{{popoverConfig.description}}"
                  placement="right"
                  triggers="mouseenter:mouseleave">le détenteur des spécifications techniques</a> est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span>
              </div>

              <div class="body" *ngIf="questionValue === brandTypes.THIRD_PARTY_BRAND">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span>
                <br/><br/>
                <span>Seul le propriétaire de la marque commerciale ou <a
                  popoverTitle="{{popoverConfig.title}}"
                  popover="{{popoverConfig.description}}"
                  placement="right"
                  triggers="mouseenter:mouseleave">le détenteur des spécifications techniques</a> est habilité à codifier les produits. <b>Codifier à sa place est contraire aux règles de codification GS1.</b></span><br/><br/>
                <span><b>Par conséquent,  vous n’êtes pas habilité à codifier ces produits. Nous vous invitons à rechercher les GTIN afin de ne pas créer de doublon.</b></span>
              </div>

              <div class="body" *ngIf="questionValue === brandTypes.WITHOUT_BRAND">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span>
                <br/><br/>
                <span>Seul le fabricant ou <a
                  popoverTitle="{{popoverConfig.title}}"
                  popover="{{popoverConfig.description}}"
                  placement="right"
                  triggers="mouseenter:mouseleave">le détenteur des spécifications techniques</a> est habilité à codifier les produits.<b> Codifier à sa place est contraire aux règles de codification GS1.</b></span>
              </div>

              <div class="body" *ngIf="questionValue === brandTypes.BUNDLE">
                <span>Seuls les assortiments composés par des produits physiquement reliés doivent être codifiés.</span>
                <br/><br/>
                <span>Si tous les produits de l’assortiment sont d’une même marque tierce, vous devez vérifier avec le propriétaire de cette marque commerciale si un GTIN n’existe pas déjà pour cet assortiment. Recodifier un assortiment déjà codifié est contraire aux règles GS1.</span>
              </div>

              <div class="body" *ngIf="questionValue === brandTypes.NOT_NEW">
                <span>Le GTIN identifie de manière unique et non ambiguë le produit. 1 GTIN = 1 produit et 1 produit = 1 GTIN.</span>
                <br/> <br/>
                <span>Un produit non neuf peut être identifié avec un nouveau GTIN si vous
                            ou un de vos partenaires commerciaux avez besoin de le différencier du même produit neuf.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="questionValue === brandTypes.WITHOUT_BRAND && showChoiceBox === true"
         id="manufacturerCheck">
      <div class="col-md-11 manufacturer-check">
        <h4 class="title">Etes-vous le fabricant ou le détenteur des spécifications techniques ?</h4>
        <div class="">
          <div class="col-md-12 choice">
            <input type="radio" id="manufacture_1" name="manufacture"
                   [(ngModel)]="isManufacturer"
                   [value]="true">
            <label for="manufacture_1">Oui et je le certifie sur l'honneur</label>
          </div>
          <div class="col-md-12 choice">
            <input type="radio" id="manufacture_2" name="manufacture"
                   [(ngModel)]="isManufacturer"
                   [value]="false" (click)="scrollToTuto()">
            <label for="manufacture_2">Non</label>
          </div>
        </div>

      </div>

    </div>
    <div class="row"
         *ngIf="(questionValue === brandTypes.THIRD_PARTY_BRAND || (questionValue === 'WITHOUT_BRAND' && isManufacturer ===false)) &&  showChoiceBox===true"
         id="tuto">
      <div class="col-md-11 tuto">
        <h4 class="notice" *ngIf="questionValue === 'WITHOUT_BRAND'">{{ tutoTextValue }}</h4>
        <h4 class="title">Comment trouver le GTIN de mon produit ?</h4>
        <div>
          Vous avez deux possibilités :
          <div class="step" *ngFor="let step of steps">
            <ul>
              <li *ngIf="!step.link"><span>{{ step.value }}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 bloc-footer"
       *ngIf="(questionValue === brandTypes.THIRD_PARTY_BRAND || questionValue === brandTypes.OWN_BRAND || questionValue === brandTypes.BUNDLE || questionValue === brandTypes.NOT_NEW || (questionValue === brandTypes.WITHOUT_BRAND && (isManufacturer === true || isManufacturer === false)))"
       [ngClass]="{ 'third-party-brand' : questionValue === brandTypes.THIRD_PARTY_BRAND}">
    <div class="action-bar pull-right col-md-12"
         [ngClass]="{ 'third-party' : questionValue === brandTypes.THIRD_PARTY_BRAND}">
      <button *ngIf="questionValue === brandTypes.OWN_BRAND || questionValue === brandTypes.BUNDLE || questionValue === brandTypes.NOT_NEW"
              (click)="goToCreateGtin()" class="own-brand-btn">
        J'ai compris
      </button>
      <button
        *ngIf="(questionValue === brandTypes.THIRD_PARTY_BRAND || (questionValue === 'WITHOUT_BRAND' && isManufacturer ===false))  && showChoiceBox === true"
        (click)="dontShowChoiceBox($event)" class="third-party-brand-btn"
        [ngClass]="{'analytic-not-found-gtins-without-brand' : isImport && questionValue === 'WITHOUT_BRAND',
                    'analytic-not-found-gtins-third-party-brand' :  isImport && questionValue === brandTypes.THIRD_PARTY_BRAND}">
        {{ isImport ? "J'ai tout essayé, je ne trouve pas les GTIN" : "J'ai tout essayé, je ne trouve pas le GTIN" }}
      </button>
      <button *ngIf="(questionValue === brandTypes.THIRD_PARTY_BRAND)  && showChoiceBox === true"
              (click)="goBack()" class="own-brand-btn is-third-party"
              [ngClass]="{'analytic-found-gtins-third-party-brand' :  isImport}">
        {{ isImport ? "J'ai trouvé tous mes GTIN" : "J'ai trouvé le GTIN" }}
      </button>
      <button *ngIf="((questionValue === 'WITHOUT_BRAND' && isManufacturer ===false))  && showChoiceBox === true"
              (click)="goBack()" class="own-brand-btn is-third-party"
              [ngClass]="{'analytic-found-gtins-without-brand': isImport}">
        {{ isImport ? "J'ai trouvé tous mes GTIN" : "J'ai trouvé le GTIN" }}
      </button>
      <button
        *ngIf="(questionValue === brandTypes.THIRD_PARTY_BRAND || questionValue === 'WITHOUT_BRAND') && showChoiceBox === false && (allCertificationChecked() === true ) && !isImport"
        (click)="goToCreateGtin()" class="third-party-brand-btn">
        J'ai compris et je souhaite créer un GTIN
      </button>
      <button
        *ngIf="(questionValue === brandTypes.THIRD_PARTY_BRAND) && showChoiceBox === false && (massCertificationChecked() === true) && isImport"
        (click)="goToCreateGtin()" class="third-party-brand-btn unit-from-import-third-party-brand">
        J’ai compris et je souhaite créer un GTIN unitaire
      </button>
      <button
        *ngIf="(questionValue === 'WITHOUT_BRAND') && showChoiceBox === false && (massCertificationChecked() === true) && isImport"
        (click)="goToCreateGtin()" class="third-party-brand-btn unit-from-import-without-brand">
        J’ai compris et je souhaite créer un GTIN unitaire
      </button>
      <button
        *ngIf="(questionValue === brandTypes.THIRD_PARTY_BRAND || questionValue === 'WITHOUT_BRAND') && showChoiceBox === false"
        (click)="goToDashboard()" class="own-brand-btn is-third-party"
        [ngClass]="{'analytics-not-continue-without-brand' : isImport && questionValue === 'WITHOUT_BRAND',
                    'analytics-not-continue-third-party-brand' :  isImport && questionValue === brandTypes.THIRD_PARTY_BRAND}">
        Je ne souhaite pas poursuivre
      </button>
      <div *ngIf="questionValue === 'WITHOUT_BRAND' && showChoiceBox === true &&  isManufacturer === true">
        <button (click)="goBack()" class="third-party-brand-btn without-brand">
          Annuler
        </button>
        <button (click)="goToCreateGtin()"
                class="own-brand-btn without-brand">
          Suivant
        </button>
      </div>
    </div>
  </div>


</div>
