<div class="import-body" id="body">
  <div class="row">
    <div class="col-xs-5 text-center left-pane" *ngIf="forImport || (!isBundle && !isNotNew)">
      <div class="import-section-body col-xs-10 col-xs-push-1">
        <div class="import-template-csv product-import-download-template-item" [class.isClicked]=isGeneric
             (click)="displayGenericTemplate()">
          <div class="row">
            <label class="col-xs-12 product-import-download-template-item-label">Tous produits (hors vins et
              spiritueux)</label>
          </div>
        </div>
        <div class="import-template-csv product-import-download-template-item" [class.isClicked]=isWine
             (click)="displayWineTemplate()">
          <div class="row">
            <label class="col-xs-12 product-import-download-template-item-label">Vins</label>
          </div>
        </div>
        <div class="import-template-csv product-import-download-template-item" [class.isClicked]=isSpi
             (click)="displaySpiTemplate()">
          <div class="row">
            <label class="col-xs-12 product-import-download-template-item-label">Spiritueux</label>
          </div>
        </div>
        <div *ngIf="forImport" class="import-template-csv product-import-download-template-item"
             [class.isClicked]=isBundle (click)="displayBundleTemplate()">
          <div class="row">
            <label class="col-xs-12 product-import-download-template-item-label">Assortiments</label>
          </div>
        </div>
        <div *ngIf="forImport" class="import-template-csv product-import-download-template-item"
             [class.isClicked]=isNotNew (click)="displayNotNewTemplate()">
          <div class="row">
            <label class="col-xs-12 product-import-download-template-item-label">Non neufs</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-6" [ngClass]="((isBundle || isNotNew)&& !forImport)?'center-pane':'right-pane'">
      <div class="alertBox" *ngIf="!this.isDirtyForm() && !isBundle && !isNotNew">
        <codification-rule title="Notre modèle produit évolue">
          Le modèle des produits non-alimentaires et alimentaires (hors vins et spiritueux) a évolué pour votre confort.
          Veillez à télécharger le nouveau modèle.
          Pour cela cliquez sur le type de produit souhaité.
        </codification-rule>
      </div>
      <div *ngIf="isGeneric || isFood || isBundle || isNotNew">
        <div class="col-xs-9 col-xs-offset-2 textSection">
          <div class="row">
            <div class="col-xs-12">
              1) {{
                isBundle ? constants.import.newWay.text.createGtins.genericProduct.firstTextBundle : isNotNew ? constants.import.newWay.text.createGtins.genericProduct.firstTextNotNew :
                  constants.import.newWay.text.createGtins.genericProduct.firstText
              }}
            </div>
            <div class="col-xs-12 btnContainer">
              <div class="col-xs-12 searchBtn" (click)="searchCategory()">
                <button>
                  {{ constants.import.newWay.file.button.search }}
                </button>
              </div>
            </div>
            <div class="col-xs-12" style="height: 2em"></div>
            <div class="col-xs-11">
              2) {{ constants.import.newWay.text.createGtins.genericProduct.secondText }}
            </div>
            <div class="col-xs-12 btnContainer">
              <div class="col-xs-12 searchBtn" (click)="downloadTemplate()">
                <button>
                  {{ constants.import.newWay.file.button.download }}
                </button>
              </div>
            </div>
            <div>
              <div class="col-xs-12" style="height: 2em"></div>
              <div class="col-xs-11" *ngIf="!forImport">
                3) {{ constants.import.newWay.text.createGtins.genericProduct.thirdText }}
              </div>
              <div class="col-xs-11" *ngIf="forImport">
                3) {{ constants.import.newWay.text.createGtins.genericProduct.thirdTextForImport }}
              </div>
            </div>
            <div>
              <div class="col-xs-12" style="height: 2em"></div>
              <div class="col-xs-11">
                4) {{ constants.import.newWay.text.createGtins.genericProduct.fourthText }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-xs-offset-1 textSection alert-section"
             *ngIf="productResellerType === 'OWN_BRAND' || productResellerType === 'WITHOUT_BRAND_AND_IS_MANUFACTURER' || isBundle "
             id="alertSection">
          <div>
            <div class="row title">Important</div>
            <div class="row body" *ngIf="productResellerType === 'OWN_BRAND'">
              Vous souhaitez identifier des produits de vos propres marques.<br>
              Les colonnes "marque principale" et "marque secondaire" de votre fichier doivent impérativement contenir
              des marques dont vous êtes propriétaire.
            </div>
            <div class="row body" *ngIf="productResellerType === 'WITHOUT_BRAND_AND_IS_MANUFACTURER'">
              Vous souhaitez identifier des produits sans marque.<br>
              Votre fichier doit impérativement respecter les règles suivantes :<br>
              - La colonne "Marque principale" doit contenir : "SANS MARQUE".<br>
              - La colonne "Marque secondaire" doit rester vide.
            </div>
            <div class="row body" *ngIf="isBundle">
              Seuls les assortiments composés par des produits physiquement reliés doivent être codifiés.<br>
              Si tous les produits de l'assortiment sont d'une même marque tierce, vous devez vérifier avec le
              propriétaire de cette marque commerciale si un GTIN n'existe pas déjà pour cet assortiment. Recodifier un
              assortiment déjà codifié est contraire aux règles GS1.
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isWine || isSpi">
        <div class="col-xs-9 col-xs-offset-2 textSection">
          <div class="row">
            <div class="col-xs-1">
              <img class="info-img" src="assets/icons/info-ico.svg">
            </div>
            <div class="col-xs-11" *ngIf="!forImport">
              {{ constants.import.newWay.text.createGtins.firstText }}
            </div>
            <div class="col-xs-11" *ngIf="forImport">
              {{ constants.import.newWay.text.createGtins.firstTextForImport }}
            </div>
            <div class="col-xs-12" style="height: 2em"></div>
            <div class="col-xs-1">
              <img class="info-img" src="assets/icons/info-ico.svg">
            </div>
            <div class="col-xs-11">
              <div style="color:#F26334;">
                {{ constants.import.newWay.text.createGtins.secondText.vcs }}
              </div>
              <br>
              <div>
                {{ constants.import.newWay.text.createGtins.secondText.vcsExample }}
              </div>
            </div>
            <div>
              <div class="col-xs-12" style="height: 2em"></div>
              <div class="col-xs-1">
                <img class="info-img" src="assets/icons/info-ico.svg">
              </div>
              <div class="col-xs-11">
                {{ constants.import.newWay.text.createGtins.thirdText }}
              </div>
            </div>
            <div class="col-xs-12 btnContainer">
              <div class="col-xs-12 searchBtn" (click)="downloadVcsTemplate()">
                <button>
                  {{ constants.import.newWay.file.button.download }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-xs-offset-1 textSection alert-section"
             *ngIf="productResellerType === 'OWN_BRAND' || productResellerType === 'WITHOUT_BRAND_AND_IS_MANUFACTURER' "
             id="alertSection2">
          <div>
            <div class="title">Important</div>
            <div class="body" *ngIf="productResellerType === 'OWN_BRAND'">
              Vous souhaitez identifier des produits de vos propres marques.<br>
              La colonne "marque principale" de votre fichier d'import doit impérativement contenir des marques dont
              vous êtes propriétaire.
            </div>
            <div class="body" *ngIf="productResellerType === 'WITHOUT_BRAND_AND_IS_MANUFACTURER'">
              Vous souhaitez identifier des produits sans marque.<br>
              La colonne "Marque principale" de votre fichier doit impérativement contenir : "SANS MARQUE".
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row info-section" *ngIf="isNotNew" [ngClass]="{'import-section': forImport}">
    <gs1-alert [title]="'Recommandation'" [showIcon]="false"
               [content]="'Si vous connaissez le GTIN d\'origine des produits non neufs, nous vous conseillons de le renseigner dans le document modèle, dans la colonne dédiée, pour chacun des produits.'"
               [type]="'info'">
    </gs1-alert>
  </div>
</div>

