<div class="ProductCreationSecondStep" [ngClass]="getBackgroundClassForType()">
    <div class="Unity Consumer">
        <form (autocomplete)="false" #formProduct="ngForm" ngNativeValidate>
            <div class="Unity-header">
                <div class="modal-title"></div>
            </div>
            <div class="Unity-body">
                <div class="Unity-form container-fluid gtinContainer">
                    <div class="row">
                        <div class="col-md-6">
                            <span class="go-back" (click)="returnBack(false)">
                                <img src="assets/icons/back.svg">
                                Retour
                            </span>
                        </div>
                    </div>
                    <!-- <div class="Unity-form-section row">
                        <div class="Unity-form-section-name Unity-form-section-welcome-title col-md-2 col-xs-4">
                            Bienvenue sur
                        </div>
                        <div class="Unity-form-section-name Unity-form-section-title-CoL col-md-2 col-xs-4">
                            CodeOnline
                            <span style="color:#F26334">{{getTitlePart()}}</span>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="Unity-form-subtitle-section col-md-3 col-xs-6">Allez plus loin avec CodeOnline <span style="color:#F26334">{{getTitlePart()}}</span></div>
                    </div>
                    <div class="Unity-form-advantages-paragraphs">
                        <div class="row" *ngFor="let advantage of getAdvantagesParagraphs()">
                        <div class="{{getBackgroundClassForType()}}-paragraphs-advantages">
                                {{advantage}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row" style="padding-top: 23px;">
                        <div class="Unity-form-AdvantagesIntro-section col-md-3 col-xs-3">{{getAdvantagesIntro()}}</div>
                    </div> -->
                    <!-- <div class="Unity-form-advantages col-md-6 col-xs-6">
                        <ul>
                            <li *ngFor="let item of getAdvantages()">
                                {{item}}
                            </li>
                        </ul>
                    </div> -->
                    <div class="icons row">
                        <div class="row">
                            <div class="col-7-ic" *ngFor="let item of getLogos()">
                                <img class="collective-icons" src="assets/img/{{item}}"/>
                            </div>
                        </div>
                    </div>
                    <div class="Unity-form-learnMore row">
                        <div class="{{getBackgroundClassForType()}}-paragraphs-advantages">
                            <a target="_blank" href="{{getLearnMoreLink()}}">
                                <span>
                                    En savoir plus
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="action-buttons-container">
                    <button  (click)="submitForm(false, null, true)" class="action-button btn-orng">
                        <span>Compléter ma fiche produit</span>
                    </button>
                    <button class="action-button btn-link" (click)="submitForm(false, null, unityForm.productForm.adhereToTheSectoralAgreement, true)">
                        <span>Passer directement à l'attribution de mon GTIN</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

