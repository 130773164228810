<div class="ProductCreationThirdStep">
    <unity-consumer-creation-food *ngIf="isFood"
                                  [unityForm]="unityForm"
                                  (onReturnToGenericStep)="returnBack($event.submitting)"
                                  (onNavigateToFinalStep)="submitForm($event.submitting, null, true)">
    </unity-consumer-creation-food>
    <unity-consumer-creation-vcs *ngIf="isVcs && featureToggleSvc.isWhalesUser()"
                                 [unityForm]="unityForm"
                                 (onNavigateToFinalStep)="submitForm($event.submitting, null, unityForm.productForm.adhereToTheSectoralAgreement)"
                                 (onReturnToPreviousStep)="returnBack($event.submitting)"
                                 [isSpi]="isSpi" [isWine]="isWine">
    </unity-consumer-creation-vcs>

  <unity-consumer-creation-vcs-old *ngIf="isVcs && !featureToggleSvc.isWhalesUser()"
                                   [unityForm]="unityForm"
                                   (onNavigateToFinalStep)="submitForm($event.submitting, null, true)"
                                   (onReturnToPreviousStep)="returnBack($event.submitting)"
                                   [isSpi]="isSpi" [isWine]="isWine">
  </unity-consumer-creation-vcs-old>
</div>
