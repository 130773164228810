<div class="search-component {{showResults ? 'search-component-wresult' : ''}}">
  <div class="searchDiv">
    <input
      #inputElement
      type="text"
      class="searchInput searchBtn"
      (keyup.enter)="searchByKey()"
      (keyup)="cleanView($event)"
      placeholder="Saisir un mot clef et trouver votre GTIN"
      [(ngModel)]="searchedKey"
    />
    <button style="display: none;" (click)="searchByKey()"></button>
    <i class="fa fa-search searchBtn" (click)="cleanView($event);searchByKey()">
      <img src="/assets/icons/search.png" />
    </i>
  </div>

  <div *ngIf="showResults" class="result-table" [ngClass]="{'border-white': border === 'white', 'border-gray': border === 'gray'}">
    <div
      *ngFor="let product of results"
      class="row result"
      [ngClass]="{'border-white': border === 'white', 'border-gray': border === 'gray'}"
    >
      <!-- <p>{{product.gtin}}</p> -->
      <div class="col-md-3 result-img">
        <div>
          <img
            [ngClass]="{'no-img': productImg === '/assets/img/nopicture.png'}"
            class="productImg"
            src="{{product.imageUrls && product.imageUrls[0] ? product.imageUrls[0] : '/assets/img/nopicture.png'}}"
          />
        </div>
      </div>
      <div class="col-md-8 result-content">
        <div class="row">
          <div class="col-md-12 gtin-block">
            <a
              class="goModeShared"
              href="{{constructShareUrl(product.gtin)}}"
              target="_blank"
              >{{product.gtin}}</a
            >
            <button
              id="copyGtinBtn_{{product.gtin}}"
              class="copyGtinBtn"
              #btn
              (click)="clickCopyGtin($event, btn)"
              type="button"
              ngxClipboard
              [cbContent]="product.gtin"
              tooltip="{{ copyGtinToolTip }}"
              placement="right"
            >
              <img src="assets/icons/icon-copy.png" />
            </button>
          </div>
          <!-- <div class="col-md-2">

                    </div> -->
          <!-- <div class="col-md-6 product-name">
                      <p>
                        {{product.productNames && product.productNames[0] ?
                        product.productNames[0].value : ''}}
                      </p>
                    </div> -->
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="ellipsis">
              <span
                class="product-name"
                tooltip="{{getProductName(product.productNames)}}"
              >
                {{getProductName(product.productNames)}}</span
              >
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="ellipsis" tooltip="{{product.brand}}" class="brand-p">
              Marque : <span class="brand">{{product.brand}}</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 company-col">
            <p tooltip="{{product.companyName}}" class="company-p ellipsis">
              Entreprise : <span class="company">{{product.companyName}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showOneResult" class="result-card">
    <div
      class="resultsContainer col-md-12 col-xs-12"
      *ngIf="showOneResult === true"
    >
      <a
        class="col-lg-4 col-md-6 col-sm-8 col-xs-10 resultSection center-block"
        *ngIf="showOneResult === true"
        href="{{url}}"
        target="_blank"
      >
        <div>
          <div class="imgSection">
            <div class="imgGradientContainer">
              <div class="img-section-innerr">
                <div class="item">
                  <img
                    [ngClass]="{'no-img': productImg === '/assets/img/nopicture.png'}"
                    class="productImg"
                    src="{{productImg}}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="productInfoContainer">
            <p class="gtin">
              {{gtinFound}}<button
                id="copyGtinBtn_{{gtinFound}}"
                class="copyGtinBtn"
                #btn
                (click)="clickCopyGtin($event, btn)"
                type="button"
                ngxClipboard
                [cbContent]="gtinFound"
                placement="right"
              >
                <img src="assets/icons/icon-copy.png" />
              </button>
            </p>
            <p class="productName">{{productName}}</p>
            <p class="brandName">Marque : {{brandName}}</p>
            <p class="companyName">Entreprise : {{companyName}}</p>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div *ngIf="showNoResult">
    <div
      class="
        col-lg-12 col-md-12 col-sm-12 col-xs-12
        text-center
        notFoundProduct
      "
    >
      <div class="ProductNotFound-title">
        Aucun résultat pour : {{noResultSearchedKey}}.
      </div>
      <div class="ProductNotFound-text">
        Veuillez vérifier votre orthographe.
      </div>
    </div>
  </div>

  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" style="color:red; padding-top: 32px;"
        *ngIf="rateLimitExceeded === true">
      <div *ngIf="rateLimitExceeded === true">Nous regrettons de ne pouvoir donner suite à votre recherche.
          Vous êtes arrivé à la limite
          autorisée.
      </div>
  </div>
</div>

<div class="pagination-container">
<pagination
  *ngIf="showPagination"
  [nbrItems]="total"
  [currentPage]="currentPage"
  (changePage)="onChangePage($event)"
></pagination>
</div>
