import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BsModalComponent} from 'ng2-bs3-modal';
import {ProductsSvc} from '../../services';
import {environment} from '../../../../environments/environment';
import {QrcodeGenerationService} from '../../services';
import {DomSanitizer} from '@angular/platform-browser';
import * as _ from 'lodash';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'qr-code-creation',
  templateUrl: './qr-code-creation.component.html',
  styleUrls: ['./qr-code-creation.component.less']
})
export class QrCodeCreationComponent implements OnInit {
  @Input() public isCustom: boolean;
  @ViewChild('modal', {static: true})
  public modal: BsModalComponent;
  public url: string = '';
  public customUrl = '';
  public type: string = 'svg';
  public imageData: any;
  public size: string = '32.7';
  public regEx = /^(?:[a-z]+)?:\/\/(?:www\.|(?!www))(?:(?:[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])(?=\s*$)|(?:[a-zA-Z0-9]))+(?![\-\.]gs1+)\.[^\s]{2,}(?=\s*$)/;
  public formReady: boolean;
  public isValidLink: boolean;
  public isEmptyUrl: boolean = true;
  public gs1AlertContent = 'En cette période de transition, le QR Code augmenté GS1 pourrait ne pas être lu en caisse. Par conséquent celui-ci devra être associé à un code-barres. ' +
    '<br/>Assurez-vous de bien respecter le placement de ces deux symboles.' +
    '<br/><br/><a  href="https://www.gs1.fr/publication/guide-mise-oeuvre-codes-barres-2d-point-vente-du-commerce-detail" target="_blank"> Consulter le guide (page 24) <i class="bi bi-arrow-right"></i></a>';


  @ViewChild('link')
  public link: HTMLInputElement;

  @ViewChild('sizeInput')
  public sizeInput: NgModel;

  constructor(public productSvc: ProductsSvc, public qrService: QrcodeGenerationService, private sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
  }

  public show() {
    this.modal.open();
    this.isValidLink = !_.isEmpty(this.productSvc.product.productLink);
    if (_.isEmpty(this.url)) {
      this.url = this.isValidLink ? this.productSvc.product.productLink : '';
    }
    if (this.isCompleteVcs()) {
      this.isCustom = this.isValidLink && this.url !== `${environment.SHARE_URL_BASE}/01/${this.productSvc.product.gtin}`;
    }

    if (!this.isCompleteVcs()) {
      this.isCustom = true;
    }

    if (this.isCustom) {
      this.customUrl = this.url;
    }

    this.purgeUrl(this.isCustom, this.link);
  }

  public downloadQRCode() {
    this.qrService.downloadQRcode(this.size, this.type, this.url).subscribe(async r => {
      const imageUrl = URL.createObjectURL(r);
      const qrLink = document.createElement('a');
      qrLink.href = imageUrl;
      qrLink.download = `IMPORTANT_Notice_QR_CODE.zip`;
      document.body.appendChild(qrLink);
      qrLink.click();
      qrLink.remove();
    });

  }

  public generateQR() {
    this.qrService.generateQRcode('60', 'png').subscribe(r => {
      this.imageData = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(r));
    });
  }

  public close() {
    this.modal.close();
  }

  public onFocusOut(link) {
    this.customUrl = this.url?.trim();
    this.isValidLink = false;
    this.isEmptyUrl = false;
    if (link.valid && link.touched) {
      this.isValidLink = true;
      this.refreshQR();
    } else {
      this.imageData = null;
      this.formReady = null;
    }
  }

  public purgeUrl(isCustom, link = null) {
    this.isCustom = isCustom;
    this.isValidLink = false;
    if (!isCustom) {
      this.url = `${environment.SHARE_URL_BASE}/01/${this.productSvc.product.gtin}`;
      this.isValidLink = true;
      this.refreshQR();

    } else {
      this.formReady = null;
      if (!_.isEmpty(this.customUrl)) {
        this.isEmptyUrl = false;
        link.reset(this.customUrl);
        this.url = this.customUrl;
        if (link && link.valid) {
          this.isValidLink = true;
          this.refreshQR();
        }
      } else {
        this.url = '';
        this.isEmptyUrl = true;
      }

    }

  }

  public async refreshQR() {
    // this.type = null;
    if (_.isEmpty(this.url)) {
      this.formReady = null;
      this.imageData = null;
    }
    await this.productSvc.updateProductLink(this.productSvc.product.id, this.url?.trim()).toPromise();
    await this.generateQR();
    this.formReady = this.isValidLink ? false : null;
    if (this.url && this.isValidLink) {
      setTimeout(() => {
        this.formReady = true;
      }, 1000);
    }
  }

  public isCompleteVcs(): boolean {
    return this.productSvc?.product?.adhereToRegulatoryAgreement;
  }

  public showLoader(): boolean {
    return (this.formReady && !this.imageData) || this.formReady === false;
  }

  public isSizeEmpty(): boolean {
    return this.size === null || this.size === '';
  }

  public sizeOutOFRange(): boolean {
    return !this.isSizeEmpty() && !_.isNil(this.sizeInput?.errors);
  }
}
