<div class="Unity Consumer defineScrollbar">
    <form #form (ngSubmit)="submitForm(true, form)" (autocomplete)="false" #formProduct="ngForm" novalidate>
        <div class="Unity-header">
            <div class="modal-title"></div>
        </div>
        <div class="Unity-body">
            <!-- FORM -->
            <div class="Unity-form container-fluid">
                <div class="Unity-form-section row">
                    <div class="Unity-form-section-name col-md-2 col-xs-4">
                        Ingrédients
                    </div>
                    <div class="Unity-form-section-content col-md-10 col-xs-4 ingredientsArea">
                        <!-- ingredientStatement -->
                        <div class="Unity-form-subSection row topLeftContainer">
                            <div class="Unity-form-input">
                                <label for="ingredientStatement">
                                    Liste des ingrédients
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.ingredientStatement.title}}"
                                     popover="{{constants.inputsInfo.ingredientStatement.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                                <!--                                    <p style="color:red; text-align: justify;"-->
                                <!--                                    *ngIf="productForm.ingredientStatement.length > 0">Important : Renseignez en majuscule les substances ou produits allergènes mis en évidence dans la liste des ingrédients présente sur l’emballage de la denrée alimentaire. La mise en majuscule ne doit être utilisée que pour les substances ou produits allergènes.</p>-->
                                <div class="ingredientsContainer">
                                    <div class="textareaHeader" (click)="editableContent.focus();">Ingrédients:</div>
                                    <div class="textAreaContainer" (click)="editableContent.focus();">
                                        <div class="textAreaVisible defineScrollbar" contenteditable="true" #editableContent (keyup)="updateTextArea(editableContent)">{{ingredientPlaceholder}}</div>
                                        <textarea id="ingredientStatement" class="mt-2 ingredient"
                                                  maxlength="5000"
                                                  [(ngModel)]="productForm.ingredientStatement"
                                                  name="ingredientStatement" #ingredientStatement>
                                            </textarea>
                                        <div class="textAreaFooter" *ngIf="productForm.ingredientStatement.length > 0" (click)="editableContent.focus();">
                                            Important : Renseignez en <strong>majuscule</strong> les substances ou
                                            produits
                                            allergènes mis en évidence dans la liste des ingrédients présente sur
                                            l’emballage de la denrée alimentaire. La mise en majuscule ne doit être
                                            utilisée
                                            que pour les substances ou produits allergènes.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- netContent section -->
                <div class="Unity-form-section row netContent">
                    <!-- <div class="Unity-form-section-name col-md-2 col-xs-4">Contenu & poids net</div> -->
                    <div class="Unity-form-section-content col-md-10 col-xs-4 netContentContainer">
                        <div class="Unity-form-subSection row">
                            <div class="Unity-form-input col-xs-8">
                                <!-- regulatedProductName -->
                                <label for="regulatedProductName">
                                    Dénomination légale de vente
                                    <span
                                            class="Unity-form-input--required">(Obligatoire)</span>
                                </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.regulatedProductName.title}}"
                                     popover="{{constants.inputsInfo.regulatedProductName.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                                <input id="regulatedProductName" maxlength="500"
                                       #regulatedProductName="ngModel"
                                       [(ngModel)]="productForm.regulatedProductName"
                                       name="regulatedProductName"
                                       placeholder="{{constants.createForm.placeholders.foodProduct.regulatedProductName}}"
                                       cleanInput required
                                >
                                <div class="required-alert">
                                    <div>
                                        Veuillez renseigner ce champ
                                    </div>
                                </div>
                            </div>
                        </div>
<!--                        <div class="Unity-form-subSection row net-content-container">-->
<!--                            &lt;!&ndash;Main&ndash;&gt;-->
<!--                            <div class="Unity-form-input col-xs-12">-->
<!--                                &lt;!&ndash;Name&ndash;&gt;-->
<!--                                <label>Contenu net<span-->
<!--                                        class="Unity-form-input&#45;&#45;required">(Obligatoire)</span></label>-->
<!--                                <div class="icon-help"-->
<!--                                     popoverTitle="{{constants.inputsInfo.netContents.title}}"-->
<!--                                     popover="{{constants.inputsInfo.netContents.content}}"-->
<!--                                     placement="right"-->
<!--                                     triggers="mouseenter:mouseleave">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div *ngFor="let netContent of productForm.netContents;index as index"-->
<!--                                 class="Unity-form-input col-md-12 col-xs-12 net-content-item"-->
<!--                                 id="net-content-item-{{index}}">-->
<!--                                <input type="text" class="net-content-item-value" maxlength="70"-->
<!--                                       name="netContent-{{index}}"-->
<!--                                       (keypress)="checkValue($event)"-->
<!--                                       [ngClass]="{'error': netContent.hasErrorNetContent()}"-->
<!--                                       [(ngModel)]="netContent.netContent"-->
<!--                                       placeholder="{{constants.createForm.placeholders.netContents}}"-->
<!--                                       required-->
<!--                                       pattern="^([0-9]{1,}([,]\d{1,2})?)$"-->
<!--                                       cleanInput/>-->
<!--                                <select class="net-content-item-unit" name="measurementUnitCode-{{index}}"-->
<!--                                        [(ngModel)]="netContent.measurementUnitCode"-->
<!--                                        (change)="onChangeMeasurementUnit($event.target)"-->
<!--                                        required>-->
<!--                                    <option *ngFor="let measurementUnit of measurementUnitsList[index]"-->
<!--                                            [ngValue]="measurementUnit.code">{{measurementUnit.wording}}-->
<!--                                    </option>-->
<!--                                </select>-->
<!--                                <span class="net-content-item-close" *ngIf="index > 0"-->
<!--                                      (click)="removeNetContent(netContent, index)">x</span>-->
<!--                            </div>-->
<!--                            <div class="Unity-form-input col-md-12 col-xs-12 net-content-add" *ngIf="canAddNetContent"-->
<!--                                 (click)="addNetContent()">-->
<!--                                <span>Ajouter une autre valeur</span>-->
<!--                            </div>-->
<!--                        </div>-->

                        <label class="label-for-displayDryWeight">
                            Autre informations du contenu net
                        </label>
                        <div class="displayDryWeight">
                            <input id="displayDryWeight" type="checkbox" [checked]="hasDrainedWeight"
                                   (click)="showDryWeight()">
                            <span class="checkmark"></span>
                            <label for="displayDryWeight">Mon produit dispose d'un liquide de recouvrement</label>
                        </div>
                        <div class="displayAlcoholPerVolume">
                            <input id="displayAlcoholPerVolume" [checked]="hasAlcohol" type="checkbox"
                                   (click)="showAlcoholPerVolume()">
                            <span class="checkmark"></span>
                            <label for="displayAlcoholPerVolume">Mon produit est alcoolisé</label>
                        </div>
                        <div class="Unity-form-subSection row dryWeight" *ngIf="canChangeDryWeight">
                            <div class="Unity-form-input mt-2 col-md-12 col-xs-12">
                                <label for="drainedWeight">Poids net égoutté </label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.drainedWeight.title}}"
                                     popover="{{constants.inputsInfo.drainedWeight.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                                <div class="Unity-form-input row">
                                    <div class="col-md-12 col-xs-12 drainedWeightContainer">
                                        <input type="text" id="drainedWeight" class="drained-Weight" maxlength="20"
                                               name="drainedWeight" placeholder="Valeur"
                                               (keypress)="checkValue($event)"
                                               [ngClass]="{'error': hasErrorDrainedWeight()}"
                                               [(ngModel)]="productForm.drainedWeight.quantityContained"
                                               cleanInput
                                               pattern="^([0-9]{1,}(,\d{1,2})?)$"/>
                                        <select name="drainedWeightMeasurementUnitCode"
                                                [(ngModel)]="productForm.drainedWeight.measurementUnitCode">
                                            <option [ngValue]=""></option>
                                            <option *ngFor="let measurementUnitCode of drainedWeightMeasurementUnitConst"
                                                    [ngValue]="measurementUnitCode.code">
                                                {{findDrainedWeightMesurementWording(measurementUnitCode.code)}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection row alcoholPerVolume" *ngIf="canChangeAlcoholPerVolume">
                            <div class="Unity-form-input mt-3 col-md-12 col-xs-12">
                                <label for="specific">Pourcentage d'alcool par volume</label>
                                <div class="icon-help"
                                     popoverTitle="{{constants.inputsInfo.alcoholDegreeFood.title}}"
                                     popover="{{constants.inputsInfo.alcoholDegreeFood.content}}"
                                     placement="right"
                                     triggers="mouseenter:mouseleave">
                                </div>
                                <div class="Unity-form-input row">
                                    <div class="col-md-2 col-xs-5 alcohol-degree">
                                        <input id="specific" type="text" maxlength="4"
                                               (keypress)="checkValue($event)"
                                               [(ngModel)]="productForm.alcoholDegree"
                                               [ngClass]="{'error': !isValidAlcoholDegree()}"
                                               placeholder="{{constants.foodCreateForm.placeholders.alcoholDegreeFood}}"
                                               name="alcoholDegree"
                                               pattern="^([0-9]{1,}([,]\d{1,2})?)$"/>
                                    </div>
                                    <div class="col-md-2 col-xs-2 volume">% par vol</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Nutritional Values -->
                <!--Nutriments-->
                <div class="Unity-form-section row col-xs-12">
                    <div class="Unity-form-section-name col-md-2 col-xs-4 nutriValuesTitle">
                        Valeurs nutritionnelles
                    </div>
                    <div class="Unity-form-section-content nutriValuesContainer">
                        <div class="Unity-form-subSection row">
                            <div class="Unity-form-input mt-3 col-md-12 col-xs-12 foodStateContainer">
                                <label>
                                    Précisez l'état de la denrée pour lequel vous déclarez les valeurs nutritionnelles:
                                    <span class="Unity-form-input--required">(Obligatoire)</span>
                                </label>
                                <div class="Unity-form-input row">
                                    <div class="col-md-12 col-xs-12 alcohol-degree">
                                        <label class="radio-inline">
                                            <input type="radio" name="preparationStateCode"
                                                   value="PREPARED" required
                                                   [(ngModel)]="productForm.preparationStateCode">
                                            <span class="radio-inline-text"> Préparée</span>
                                            <span class="check"></span>
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" name="preparationStateCode"
                                                   value="UNPREPARED" required
                                                   [(ngModel)]="productForm.preparationStateCode">
                                            <span class="radio-inline-text"> Tel que vendu</span>
                                            <span class="check"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="Unity-form-subSection row nutri-values">
                            <div class="Unity-form-input col-xs-12 mt-3">
                                <div class="row">
                                    <div class="labelContainer col-xs-5">
                                        <div class="col-xs-12 itemSpace"
                                             *ngFor="let item of productForm.nutriment.items; index as i">
                                            <div class="row">
                                                <div class="col-xs-2 valueLabel">
                                                    {{findNutrimentWording(item.nutrientTypeCode,
                                                    item.measurementUnitCode)}}
                                                </div>
                                                <div class="col-xs-2 valueSelect">
                                                    <select name="measurementPrecisionCode_ {{i}}"
                                                            required
                                                            [(ngModel)]="item.measurementPrecisionCode">
                                                        <option *ngFor="let measurement of measurementPrecision"
                                                                [ngValue]="measurement.code">
                                                            {{measurement.wording}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="valuesContainer">
                                        <div class="col-xs-12 text-center portion">
                                            pour
                                            <select class="specificWidth"
                                                    name="servingSize"
                                                    required
                                                    [(ngModel)]="productForm.nutriment.measurementUnitCode">
                                                <option *ngFor="let servingSizeUnit of nutrimentServingSize"
                                                        [ngValue]="servingSizeUnit.code">
                                                    {{findServingSizeWording(servingSizeUnit.code)}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-xs-12 valuesColumn "
                                             *ngFor="let item of productForm.nutriment.items; index as i">
                                            <div class="valuesCell">
                                                <input class="specificWidth item_quantityContained"
                                                       required
                                                       type="text"
                                                       autocomplete="false"
                                                       (keypress)="checkValue($event)"
                                                       (input)="checkEnergeticValue(item, 'quantityContained'); resetNutrimentClass(item, 'quantityContained');"
                                                       [(ngModel)]="item.quantityContained"
                                                       maxlength="10"
                                                       id="quantityContained_{{item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode}}"
                                                       name="quantityContained_ {{i}}" #name="ngModel"
                                                       [ngClass]="{'error': item.hasErrorQuantityContained()}">
                                            </div>
                                            <div class="valuesUnit">
                                                {{findMeasurementUnitWording(item.measurementUnitCode)}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="valuesContainer optionalColumn" #optionalColumn
                                         [style.height.px]="optionalColumnHeight">
                                        <div class="closeOptionalColumn"
                                            *ngIf="canSeeOptionalNutriment"
                                            (click)="hideOptionalNutriment()">X</div>
                                        <div class="oneMoreLine" (click)="showOptionalNutriment()"
                                             *ngIf="!canSeeOptionalNutriment">
                                            Ajouter une portion particulière
                                        </div>
                                        <div class="optionalContainer" *ngIf="canSeeOptionalNutriment">
                                            <div class="col-xs-12 portion">
                                                <div>
                                                    pour
                                                </div>
                                                <input id="servingSizeDescription" class="specificWidth"
                                                       maxlength="20"
                                                       [(ngModel)]="productForm.nutriment.servingSizeDescription"
                                                       name="name" #name="ngModel"
                                                       (input)="reValidateOptionalQuantityContained()"
                                                       placeholder="{{constants.foodCreateForm.placeholders.servingSizeDescription}}"
                                                       cleanInput>
                                            </div>

                                            <div class="col-xs-12 valuesColumn " [hidden]="!canSeeOptionalNutriment"
                                                 *ngFor="let item of productForm.nutriment.items; index as i">
                                                <div class="valuesCell">
                                                    <!-- (input)="calculateDailyValues(item)" -->
                                                    <input type="text"
                                                           class="optionalQuantityContained specificWidth"
                                                           [(ngModel)]="item.optionalQuantityContained"
                                                           (input)="checkEnergeticValue(item, 'optionalQuantityContained'); resetNutrimentClass(item, 'optionalQuantityContained');"
                                                           name="optionalQuantityContained_ {{i}}" #name="ngModel"
                                                           id="optionalQuantityContained_{{item.nutrientTypeCode === 'ENER-' ? item.measurementUnitCode : item.nutrientTypeCode}}"
                                                           autocomplete="false"
                                                           (keypress)="checkValue($event)"
                                                           maxlength="10"
                                                           [ngClass]="{'error': item.hasErrorOptionalQuantityContained()}"
                                                           [required]="isRequiredOptionalQuantityContained()"
                                                           [disabled]="!isRequiredOptionalQuantityContained()">

                                                </div>
                                                <div class="valuesUnit">
                                                    {{findMeasurementUnitWording(item.measurementUnitCode)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1 removeNutrimentContainer">
                                        <div class="portionMargin"></div>
                                        <div *ngFor="let item of productForm.nutriment.items; index as i"
                                             [className]="item.isRequired ? 'blankSpace removeNutriment' : 'removeNutriment'">
                                                <span class="col-xs-12 removeNutrimentBtn" *ngIf="!item.isRequired"
                                                      (click)="removeNutriment(item, i)">x</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="Unity-form-input col-xs-12 mt-3">
                                    <div class="addNutrimentContainer">
                                        <div class="col-xs-5 addNutriment" (click)="addNutriment()"
                                             *ngIf="showAddNutriment && optionalNutrimentItems.length >0">
                                            <div>
                                                Ajouter un nutriment
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Unity-form-input col-xs-5">
                                    <select class="net-content-item-unit addNutrimentSelect"
                                            (change)="selectNutriment(optionalColumn)"
                                            [(ngModel)]="selectedNutriment" [ngModelOptions]="{standalone: true}"
                                            *ngIf="!showAddNutriment">>
                                        <option *ngFor="let nutriment of optionalNutrimentItems"
                                                [ngValue]="nutriment">{{nutriment.wording}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Nutritional Values-->
                <!--dailyValueIntakeReference-->
                <div class="Unity-form-section row" id="referentialContainer" *ngIf="canSeeOptionalNutriment">
                    <div class="Unity-form-section-name col-md-2 col-xs-4">
                        Apports de référence (pour la portion particulière)
                    </div>
                    <div class="Unity-form-section-description col-md-6 col-xs-6">
                        Nous calculons pour vous les apports de référence en fonction des valeurs nutritionnelles
                        entrées plus haut. Vous pouvez cependant revenir sur chaque valeur.
                    </div>
                    <div class="Unity-form-section-content col-md-10 col-xs-8">

                        <div class="Unity-form-subSection row referential">
                            <div class="referentialsInfos" *ngIf="dailyValueIntakeReferenceEditable === false">
                                <p>{{productForm.dailyValueIntakeReference}}</p>
                                <div class="editLabel" (click)="editDailyValueIntakeReference()">Modifier le libellé
                                </div>
                            </div>
                            <div class="referentialsInfos" *ngIf="dailyValueIntakeReferenceEditable === true">
                                <input class="col-md-6" type="text" required maxlength="200"
                                       *ngIf="dailyValueIntakeReferenceEditable == true"
                                       name="dailyValueIntakeReference"
                                       [(ngModel)]="productForm.dailyValueIntakeReference"/>
                            </div>
                            <div class="Unity-form-subSection-name" (click)="calculateAllDailyValues()"
                                 style="cursor: pointer;">
                                <img src="assets/icons/calculer.png"/>
                                Calculer automatiquement à partir des valeurs nutritionnelles
                            </div>
                            <div class="Unity-form-input col-xs-12 mt-3">
                                <div class="row">
                                    <div class="col-xs-12" style="padding-top: 50px"
                                         *ngIf="showDailyValueIntakeReferenceMessage()">
                                        En référence à la portion particulière
                                        renseignée:  {{productForm.nutriment.servingSizeDescription}}
                                    </div>
                                    <div *ngFor="let item of productForm.nutriment.items; index as i">
                                        <div class="col-xs-12 itemSpace" *ngIf="findNutrimentWording(item.nutrientTypeCode,
                                                item.measurementUnitCode).length>0">
                                            <div class="row">
                                                <div class="col-xs-2">
                                                    {{findNutrimentWording(item.nutrientTypeCode,
                                                    item.measurementUnitCode)}}
                                                    <span *ngIf="isRequiredDailyValueIntakeReference()"
                                                          class="Unity-form-input--required"> (Obligatoire)</span>
                                                </div>
                                                <div class="col-xs-2">
                                                    <input class="dailyValueIntakeReference specificWidth"
                                                           type="text"
                                                           autocomplete="false"
                                                           (keypress)="checkValue($event)"
                                                           [(ngModel)]="item.dailyValueIntakeReference"
                                                           maxlength="10"
                                                           name="dailyValueIntakeReference {{i}}" #name="ngModel"
                                                           [ngClass]="{'error': item.hasErrorDailyValueIntakeReference()}"
                                                           [disabled]="!showDailyValueIntakeReferenceMessage()"
                                                           [required]="isRequiredDailyValueIntakeReference()">
                                                </div>
                                                <div class="col-xs-2">
                                                    %
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Unity-footer">
            <div class="completionLevel">
                <div class="barContainer">
                    <div class="completionBar" [style.width.%]="33.333 * (stepCount - 1)"></div>
                </div>
                <div class="currentStep">Étape {{stepCount}}/4</div>
            </div>
            <div class="Unity-footer-buttons" role="group">
                <button gs1Button [appearance]="'outlined'"(click)="returnBack(false)">Retour</button>
                <button gs1Button (click)="checkRequiredInputs(form, $event)">Suivant</button>
            </div>
        </div>
    </form>
</div>
