<div class="ProductChoiceCreationFirstStep">
  <div class="Unity Consumer defineScrollbar">
    <form (ngSubmit)="submitForm(false, null, unityForm.productForm.adhereToTheSectoralAgreement)"
          #formProduct="ngForm" ngNativeValidate>
      <div class="Unity-header">
        <div class="modal-title"></div>
      </div>
      <div class="Unity-body">
        <!-- FORM -->
        <div class="Unity-form container-fluid">

          <!-- category section -->
          <div class="Unity-form-section row ml-1">
            <label>Catégorie <span
              class="Unity-form-input--required">(Obligatoire)</span></label>

            <div class="Unity-form-section-content">
              <div class="col-md-3 category-product">
                <p id="category">{{ selectedCategory.text }}</p>
              </div>
              <div class="col-md-3 change-category" (click)="modifyCategory()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="#F26334"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0 19.6869C0 19.7782 0.0259403 19.85 0.077821 19.9022C0.142672 19.9674 0.220493 20 0.311284 20H19.6887H19.7276C19.7536 20 19.7795 19.9935 19.8054 19.9804L19.8444 19.9413C19.8703 19.9413 19.8898 19.9348 19.9027 19.9217V19.9022C19.9157 19.8891 19.9287 19.8695 19.9416 19.8434C19.9546 19.8304 19.9676 19.8108 19.9805 19.7847V19.7456V19.7065C19.9935 19.7065 20 19.6999 20 19.6869C20 19.6738 19.9935 19.6608 19.9805 19.6478V19.6282V19.6086V19.589L18.0156 13.5225C18.0026 13.4964 17.9896 13.4768 17.9767 13.4638L17.9377 13.3855L7.27626 2.66145L7.25681 2.64188C7.25681 2.62883 7.25032 2.62231 7.23735 2.62231L4.92218 0.273973C4.72763 0.0913242 4.49416 0 4.22179 0C3.96239 0 3.73541 0.0913242 3.54086 0.273973L0.291829 3.56164C0.201038 3.65297 0.129702 3.75734 0.077821 3.87476C0.0259403 3.99217 0 4.11611 0 4.24658C0 4.37704 0.0259403 4.50098 0.077821 4.6184C0.129702 4.73581 0.201038 4.84018 0.291829 4.93151L2.56809 7.22114V7.26027C2.58106 7.26027 2.58755 7.2668 2.58755 7.27984L13.2296 18.0431L13.2879 18.1018C13.3139 18.1148 13.3398 18.1213 13.3658 18.1213L17.5486 19.3738H0.311284C0.220493 19.3738 0.142672 19.4064 0.077821 19.4716C0.0259403 19.5369 0 19.6086 0 19.6869ZM3.96887 6.34051L13.7354 16.047L13.3658 17.2994L3.24903 7.04501L3.96887 6.34051ZM14.2023 15.636L4.39689 5.90998L5.9144 4.38356L15.642 14.1683V15.636H14.2023ZM16.0311 13.6791L6.36187 3.93346L7.00389 3.28767L17.1012 13.4247L16.0311 13.6791ZM14.3191 16.2622H15.9533C16.0311 16.2622 16.1025 16.2361 16.1673 16.184C16.2322 16.1187 16.2646 16.0404 16.2646 15.9491V14.2661L17.5097 13.9726L19.2023 19.2172L13.9105 17.6321L14.3191 16.2622ZM0.622568 4.24658C0.622568 4.19439 0.629053 4.14873 0.642023 4.10959L0.719844 3.99217L3.98833 0.72407C4.05318 0.658839 4.131 0.626223 4.22179 0.626223C4.32555 0.613177 4.40986 0.645793 4.47471 0.72407L6.57588 2.83757L2.82101 6.59491L0.719844 4.48141C0.693904 4.45532 0.667964 4.4227 0.642023 4.38356C0.629053 4.33138 0.622568 4.28571 0.622568 4.24658Z"
                    fill="#F26334"/>
                  <path
                    d="M0 19.6869C0 19.7782 0.0259403 19.85 0.077821 19.9022C0.142672 19.9674 0.220493 20 0.311284 20H19.6887H19.7276C19.7536 20 19.7795 19.9935 19.8054 19.9804L19.8444 19.9413C19.8703 19.9413 19.8898 19.9348 19.9027 19.9217V19.9022C19.9157 19.8891 19.9287 19.8695 19.9416 19.8434C19.9546 19.8304 19.9676 19.8108 19.9805 19.7847V19.7456V19.7065C19.9935 19.7065 20 19.6999 20 19.6869C20 19.6738 19.9935 19.6608 19.9805 19.6478V19.6282V19.6086V19.589L18.0156 13.5225C18.0026 13.4964 17.9896 13.4768 17.9767 13.4638L17.9377 13.3855L7.27626 2.66145L7.25681 2.64188C7.25681 2.62883 7.25032 2.62231 7.23735 2.62231L4.92218 0.273973C4.72763 0.0913242 4.49416 0 4.22179 0C3.96239 0 3.73541 0.0913242 3.54086 0.273973L0.291829 3.56164C0.201038 3.65297 0.129702 3.75734 0.077821 3.87476C0.0259403 3.99217 0 4.11611 0 4.24658C0 4.37704 0.0259403 4.50098 0.077821 4.6184C0.129702 4.73581 0.201038 4.84018 0.291829 4.93151L2.56809 7.22114V7.26027C2.58106 7.26027 2.58755 7.2668 2.58755 7.27984L13.2296 18.0431L13.2879 18.1018C13.3139 18.1148 13.3398 18.1213 13.3658 18.1213L17.5486 19.3738H0.311284C0.220493 19.3738 0.142672 19.4064 0.077821 19.4716C0.0259403 19.5369 0 19.6086 0 19.6869ZM3.96887 6.34051L13.7354 16.047L13.3658 17.2994L3.24903 7.04501L3.96887 6.34051ZM14.2023 15.636L4.39689 5.90998L5.9144 4.38356L15.642 14.1683V15.636H14.2023ZM16.0311 13.6791L6.36187 3.93346L7.00389 3.28767L17.1012 13.4247L16.0311 13.6791ZM14.3191 16.2622H15.9533C16.0311 16.2622 16.1025 16.2361 16.1673 16.184C16.2322 16.1187 16.2646 16.0404 16.2646 15.9491V14.2661L17.5097 13.9726L19.2023 19.2172L13.9105 17.6321L14.3191 16.2622ZM0.622568 4.24658C0.622568 4.19439 0.629053 4.14873 0.642023 4.10959L0.719844 3.99217L3.98833 0.72407C4.05318 0.658839 4.131 0.626223 4.22179 0.626223C4.32555 0.613177 4.40986 0.645793 4.47471 0.72407L6.57588 2.83757L2.82101 6.59491L0.719844 4.48141C0.693904 4.45532 0.667964 4.4227 0.642023 4.38356C0.629053 4.33138 0.622568 4.28571 0.622568 4.24658Z"
                    stroke="#F26334" stroke-width="0"/>
                </svg>
                modifier
              </div>

            </div>

          </div>

          <!-- Brand section -->
          <div class="Unity-form-section row ml-1">
            <div class="Unity-form-section-content">
              <div class="Unity-form-subSection row">
                <!--Main-->
                <div class="Unity-form-input col-md-6 col-xs-12">
                  <label for="brand">{{ isBundle ? "Marque(s) de(s) produit(s)" : "Marque principale" }}<span
                    class="Unity-form-input--required"> (Obligatoire)</span></label>
                  <div *ngIf="!isBundle" class="icon-help" popoverTitle="{{constants.inputsInfo.brand.title}}"
                       popover="{{constants.inputsInfo.brand.content}}" placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <p *ngIf="isBundle" class="explanation-text">Si le lot est composé par des produits de marques
                    différentes, merci de les renseigner en les séparant par des slash (/).</p>
                  <input id="brand" data-qa="brand" type="text" maxlength="70"
                         [(ngModel)]="unityForm.productForm.brand"
                         placeholder="{{isBundle?constants.createForm.placeholders.bundleBrand:constants.createForm.placeholders.brand}}"
                         name="brand"
                         #brand="ngModel" required cleanInput *ngIf="!cannotSeeBrand(unityForm.productForm)">
                  <div *ngIf="!cannotSeeBrand(unityForm.productForm)"
                       class="Unity-form-infoText">{{ 70 - unityForm.productForm.brand.length }}/70
                    {{ constants.createForm.helpers.inputTextInfoLength }}
                  </div>

                  <div *ngIf="cannotSeeBrand(unityForm.productForm)">
                    SANS MARQUE
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Product section -->
          <div class="Unity-form-section row ml-1">
            <div class="Unity-form-section-content">
              <div class="Unity-form-subSection row">
                <div class="Unity-form-input col-md-6 col-xs-12">
                  <label [ngClass]="{'first-time': firstTimeTargetMarketCSS}" for="targetMarket">Pays de vente<span
                    class="Unity-form-input--required"> (Obligatoire)</span></label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.targetMarketArray.title}}"
                       popover="{{constants.inputsInfo.targetMarketArray.content}}" placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <ng-selectize required name="targetMarket" [config]="multiSelectConfig"
                                [options]="multiSelectOptions"
                                [placeholder]=unityForm.constants.createForm.placeholders.targetMarket
                                [(ngModel)]="unityForm.productForm.targetMarket"
                                ngDefaultControl></ng-selectize>
                </div>
              </div>

              <div class="Unity-form-subSection row product-name-container">
                <!--Main-->
                <div class="Unity-form-input col-xs-12">                                    <!--Name-->
                  <label for="product-name-item-value-0"
                         [ngClass]="{'first-time': !userSvc.user.haveSeenProductNames}">{{ isBundle ? "Nom de l\'assortiment" : "Nom du produit" }}
                    <span
                      class="Unity-form-input--required">(Obligatoire)</span></label>
                  <div
                    class="icon-help" *ngIf="!isBundle" popoverTitle="{{constants.inputsInfo.name.title}}"
                    popover="{{constants.inputsInfo.name.content}}" placement="right"
                    triggers="mouseenter:mouseleave">
                  </div>
                  <p *ngIf="isBundle" class="explanation-text">Inclure le mot « assortiment » ou « lot », et préciser
                    les noms de tous les produits le composant</p>
                </div>

                <div *ngFor="let productName of unityForm.productForm.productNames;index as index;"
                     class="Unity-form-input col-md-12 col-xs-12 product-name-item"
                     id="product-name-item-{{index}}">
                  <div class="col-md-5 col-xs-12" [ngClass]="isNotNew ? 'Unity-form-input-not-new' : 'Unity-form-input'">
                    <input id="product-name-item-value-{{index}}" maxlength="200"
                           [(ngModel)]="productName.value"
                           name="product-name-item-value-{{index}}"
                           placeholder="{{isBundle?constants.createForm.placeholders.bundleName:constants.createForm.placeholders.name}}"
                           [required]="index == 0" cleanInput (keyup)="makeElementAsRequired(index)">
                    <div class="Unity-form-infoText">{{ 200 - productName.value.length }}/200
                      {{ constants.createForm.helpers.inputTextInfoLength }}
                    </div>
                  </div>

                  <div class="Unity-form-input col-md-3 language-question">
                    <div [ngClass]="{'first-time-show': !userSvc.user.haveSeenProductNames}">Dans quelle langue ?</div>

                  </div>

                  <div class="Unity-form-input col-md-3 col-xs-12">
                    <select class="product-name-item-unit" name="language-product-name-{{index}}"
                            [(ngModel)]="productName.lang"
                            (change)="onChangeLanguageList(); makeElementAsRequired(index)" [required]="index == 0">
                      <option *ngFor="let language of languageList[index]"
                              [ngValue]="language.code">{{ language.frenchName }}
                      </option>
                    </select>
                    <span class="product-name-item-close" *ngIf="index > 0"
                          (click)="removeProductName(productName, index)">x</span>
                  </div>
                </div>

                <div class="Unity-form-input col-md-12 col-xs-12 product-name-add"
                     *ngIf="unityForm.productForm.productNames.length < 3">
                  <span
                    (click)="addProductName()"> Le nom de votre {{ isBundle ? 'assortiment' : 'produit' }} est dans une autre langue ? ajoutez le</span>
                </div>

              </div>
            </div>
          </div>
          <div class="Unity-form-section row ml-1">
            <div class="Unity-form-section-content">
              <div class="Unity-form-subSection row net-content-container">
                <!--Main-->
                <div class="Unity-form-input col-xs-12">
                  <!--Name-->
                  <label>Contenu net <span
                    class="Unity-form-input--required">(Obligatoire)</span></label>
                  <div class="icon-help" *ngIf="!isBundle" popoverTitle="{{constants.inputsInfo.netContents.title}}"
                       popover="{{constants.inputsInfo.netContents.content}}" placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <p *ngIf="isBundle" class="explanation-text">Précisez le nombre de produits dont l’assortiment est
                    composé</p>
                </div>
                <!-- TO UN COMMENT -->
                <div>
                  <div *ngFor="let netContent of unityForm.productForm.netContents;index as index;"
                       class="Unity-form-input col-md-12 col-xs-12 net-content-item"
                       id="net-content-item-{{index}}">
                    <input type="text" class="net-content-item-value" maxlength="70"
                           [pattern]="netContentPattern[index]"
                           name="netContent-{{index}}"
                           [ngClass]="{'error': netContent.hasErrorNetContent()}"
                           [(ngModel)]="netContent.netContent"
                           placeholder="{{constants.createForm.placeholders.netContents}}"
                           [required]="index == 0" cleanInput
                           (keyup)="makeNetContentElementAsRequired(index)"
                           id="net-content-item-input-{{index}}"/>
                    <select *ngIf="!isBundle" class="net-content-item-unit"
                            name="measurementUnitCode-{{index}}"
                            [(ngModel)]="netContent.measurementUnitCode"
                            (change)="onChangeMeasurementUnit(); makeNetContentElementAsRequired(index)"
                            [required]="index == 0">
                      <option *ngFor="let measurementUnit of measurementUnitsList[index]"
                              [ngValue]="measurementUnit.code">{{ measurementUnit.wording }}
                      </option>
                    </select>
                    <span class="net-content-item-close" *ngIf="index > 0"
                          (click)="removeNetContent(netContent, index)">x</span>
                  </div>
                  <div class="Unity-form-input col-md-12 col-xs-12 net-content-add"
                       *ngIf="!isBundle &&canAddNetContent && !isVcs">
                    <span (click)="addNetContent()">Ajouter une autre valeur</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- Complementary Information section -->
          <div class="Unity-form-section row ml-1">
            <!--<div class="Unity-form-section-name">
                Informations complémentaires
            </div> -->
            <div class="Unity-form-section-content section-border less-width">
              <div class="Unity-form-subSection row">
                <!--Images-->
                <div class="Unity-form-input image-section col-xs-12">
                  <label id="image">Images</label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.images.title}}"
                       popover="{{constants.inputsInfo.images.content}}" placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div
                    class="images-format improve-text">{{ isBundle ? constants.createForm.helpers.images.bundleExplaination : constants.createForm.helpers.images.improveText }}
                  </div>

                  <file-upload [limit]="uploadLimit" [(files)]="unityForm.productForm.imagesToUpload"
                               [(productImages)]="unityForm.productForm.imagesAddedByUrl"
                               (filesChange)="unityForm.productForm.vbgCompletionLevel = productsSvc.getVbgLevelCompletion(unityForm.productForm)"
                               [(nbrProductImages)]="nbrProductImages"
                               (productImagesRemoved)="removeImageUrl($event)"
                               (nbrFilesChanged)="nbrFilesChanged($event)">
                  </file-upload>
                </div>
              </div>
            </div>

            <div class="Unity-form-section-content less-width" style="padding-top: 16px;">
              <div class="Unity-form-subSection row" *ngIf="!cannotSeeBrand(unityForm.productForm)">
                <div class="Unity-form-input col-md-6 col-xs-12">
                  <label for="subBrand">Marque secondaire </label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.subBrand.title}}"
                       popover="{{isBundle?constants.inputsInfo.subBrand.bundleContent:constants.inputsInfo.subBrand.content}}"
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <input id="subBrand" type="text" maxlength="70"
                         [(ngModel)]="unityForm.productForm.subBrand"
                         name="subBrand" cleanInput
                         placeholder="{{constants.createForm.placeholders.subBrand}}">
                  <div class="Unity-form-infoText">{{ 70 - unityForm.productForm.subBrand.length }}/70
                    {{ constants.createForm.helpers.inputTextInfoLength }}
                  </div>
                </div>
              </div>


              <div class="Unity-form-subSection row">
                <!--Description-->
                <div class="Unity-form-input col-xs-12">
                  <label
                    for="description">{{ isBundle ? 'Description des produits de l\'assortiment' : 'Description du produit' }}</label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.description.title}}"
                       popover='{{isBundle?constants.inputsInfo.description.content:constants.inputsInfo.description.content.replace("votre produit", "vos produits")}}'
                       placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <textarea id="description" rows="6" maxlength="2000"
                            [(ngModel)]="unityForm.productForm.description" name="description"
                            trim></textarea>
                  <div class="Unity-form-infoText">{{ 2000 - unityForm.productForm.description.length }}
                    /2000
                    {{ constants.createForm.helpers.inputTextInfoLength }}
                  </div>
                </div>
              </div>

              <div class="Unity-form-subSection row">
                <!--Link-->
                <div class="Unity-form-input col-xs-12" [ngClass]="'formError-' + webLinkError.status">
                  <label for="webLink">Lien / URL</label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.webLink.title}}"
                       popover="{{constants.inputsInfo.webLink.content}}" placement="right"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <div class="Unity-form-infoText webLink">
                    {{ constants.createForm.helpers.webLinkInfoText }}
                  </div>
                  <div class="info-block" [hidden]="webLinkError.status !== 'warning'">
                    <div class="info-block-text">{{ constants.createForm.errors.webLinkInvalid }}
                    </div>
                  </div>
                  <input id="webLink" type="url" maxlength="2500"
                         [(ngModel)]="unityForm.productForm.webLink"
                         (blur)="validateURL()" name="weblink" cleanInput
                         placeholder="{{constants.createForm.placeholders.webLink}}">
                  <div [ngClass]="'Unity-form-input--url-status-' + webLinkError.status">
                    <img src="assets/icons/error.svg" alt="X"
                         [hidden]="webLinkError.status !== 'warning'">
                    <img src="assets/icons/ok.svg" alt="OK"
                         [hidden]="webLinkError.status !== 'valid'">
                  </div>
                </div>
              </div>

              <div class="Unity-form-subSection row">
                <!--MPN-->
                <div class="Unity-form-input col-md-6 col-xs-12">
                  <label for="mpn">Référence fabricant ou MPN</label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.mpn.title}}"
                       popover="{{constants.inputsInfo.mpn.content}}" placement="left"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <input class="Unity-form-input" id="mpn" type="text" maxlength="70"
                         [(ngModel)]="unityForm.productForm.mpn" name="mpn" cleanInput>
                  <div class="Unity-form-infoText">
                    {{ 70 - unityForm.productForm.mpn.length }}/70
                    {{ constants.createForm.helpers.inputTextInfoLength }}
                  </div>
                </div>
                <!--SKU-->
                <div class="Unity-form-input col-md-6 col-xs-12">
                  <label for="sku">Code interne ou SKU</label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.sku.title}}"
                       popover="{{constants.inputsInfo.sku.content}}" placement="top"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <input id="sku" type="text" maxlength="70" [(ngModel)]="unityForm.productForm.sku"
                         name="sku"
                         cleanInput>
                  <div class="Unity-form-infoText">{{ 70 - unityForm.productForm.sku.length }}/70
                    {{ constants.createForm.helpers.inputTextInfoLength }}
                  </div>
                </div>
              </div>

              <div class="Unity-form-subSection row" *ngIf="isNotNew">
                <!-- Origin GTIN -->
                <div class="Unity-form-input col-md-6 col-xs-12">
                  <label for="originGtin">GTIN d'origine</label>
                  <div class="icon-help" popoverTitle="{{constants.inputsInfo.originGtin.title}}"
                       popover="{{constants.inputsInfo.originGtin.content}}" placement="left"
                       triggers="mouseenter:mouseleave">
                  </div>
                  <input class="Unity-form-input" id="originGtin" type="text" maxlength="14"
                         [(ngModel)]="unityForm.productForm.originGtin" name="originGtin" cleanInput
                         pattern="^(?:\d{8}|\d{12}|\d{13}|\d{14}|)$">
                  <div class="Unity-form-infoText">
                    {{ 14 - unityForm.productForm.originGtin.length }}/14
                    {{ constants.createForm.helpers.inputTextInfoLength }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="check-atts-container" *ngIf="!isRiskyProduct(this.unityForm.productForm)">
          <attributes-checker [productForm]="this.unityForm.productForm" [isBundle]="isBundle"></attributes-checker>
        </div>
      </div>

      <div class="Unity-footer">
        <div class="Unity-footer-buttons" role="group">
          <button gs1Button [appearance]="'outlined'" (click)="returnBack()">Annuler</button>
          <button gs1Button type="submit"
                  [disabled]="submitting || webLinkError.status === 'warning'">
            <span>Suivant</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
