<div class="import">
    <div class="import-header">
        <div class="modal-title"></div>
    </div>
    <div class="import-body container-fluid">
        <div class="row">
            <div class="import-section-header col-sm-3 col-xs-12">
                <span *ngIf="!productImport.completed">{{constants.import.status.inprogress.title}}</span>
                <span *ngIf="productImport.completed">{{constants.import.status.done.title}}</span>
            </div>
            <div class="import-section-body col-sm-9 col-xs-12">
                <div class="import-progress" *ngIf="!productImport.completed && !productImport.failed">
                    <img class="import-icon" src="assets/icons/ico_import_in_progress.svg" alt="Import en cours">
                    <div class="progress import-progress-bar">
                        <div class="progress-bar" role="progressbar" [style.width]="importCompletionPercentage + '%'">
                            <span>{{importCompletionPercentage}}%</span>
                        </div>
                    </div>
                    <p>{{getTextInProgress()}}</p>
                </div>
                <div class="import-result" *ngIf="productImport.failed">
                    <div class="error-report-text">
                        <p>{{constants.import.status.error.message}}</p>
                    </div>
                </div>
                <div class="import-result" *ngIf="productImport.completed">
                    <div class="import-result-section" *ngIf="productImport.ok > 0">
                        <img class="import-icon" src="assets/icons/ico_import_ok.svg" alt="Import OK">
                        <p class="import-result-count">{{productImport.ok}}{{constants.import.status.done.products.name}}</p>
                        <p>{{importResultText()}}</p>
                        <button class="error-report-button" type="button" (click)="downloadSuccessReport()" *ngIf="showBtnExport()"><span>{{constants.import.status.done.successReport.button}}</span></button>
                    </div>
                    <div class="import-result-section" *ngIf="productImport.errors > 0">
                        <img class="import-icon" src="assets/icons/ico_import_error.svg" alt="Import KO">
                        <p class="import-result-count">{{productImport.errors}}{{constants.import.status.done.products.name}}</p>
                        <p>{{importResultText(true)}}</p>
                        <button class="error-report-button" type="button" (click)="downloadErrorReport()"><span>{{constants.import.status.done.errorreport.button}}</span></button>
                        <a class="error-report-link" data-toggle="collapse" data-target="#errorReportText">{{constants.import.status.done.errorreport.link}}</a>
                        <p class="error-report-text collapse" id="errorReportText">{{constants.import.status.done.errorreport.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="import-footer">
        <div class="import-footer-right" role="group">
            <button class="stepButton" routerLink="/dashboard" *ngIf="productImport.completed || productImport.failed"><span>{{constants.import.button.backToDashboard}}</span></button>
        </div>
    </div>
</div>
