<div class="ProductCreationFinalStep">
  <div class="Unity Consumer defineScrollbar">
    <form (autocomplete)="false" #formProduct="ngForm" ngNativeValidate>
      <div class="Unity-header">
        <div class="modal-title"></div>
      </div>
      <div class="Unity-body">
        <!-- FORM -->
        <div class="Unity-form container-fluid gtinContainer">
          <!-- Introduction section -->
          <div class="Unity-form-section row">
            <div class="Unity-form-section-name col-md-2 col-xs-4">Attribution de votre GTIN</div>
            <div class="col-md-6 col-xs-8 valid-instruction" *ngIf="!showChoice || gtin8choice!==null ">
              Veuillez cliquer sur "Terminer et afficher la fiche produit" pour
              finaliser la création de votre GTIN.
            </div>
          </div>
          <!-- GTIN CHOICE -->
          <div *ngIf="showChoice" class="Unity-form-section row">
            <div class="card col-md-6">
              <p class="question"><span
                class="green">Vous disposez de {{ gtin8AvailableCount }} {{ gtin8AvailableCount > 1 ? 'codes' : 'code' }}
                GTIN-8 {{ gtin8AvailableCount > 1 ? 'disponibles' : 'disponible' }}.</span>
                Souhaitez-vous {{ gtin8AvailableCount > 1 ? 'en utiliser un' : 'l\'utiliser' }} ?</p>
              <div class="choices">
                <div class="choice">
                  <input type="radio" id="1" name="choice" value="Oui" #radio1 (change)="gtin8choice =  true;">
                  <label for="1">Oui</label>
                </div>

                <div class="choice">
                  <input type="radio" id="2" name="choice" value="Non" #radio2
                         (change)="changePrefix(prefixGtin13); gtin8choice = false;">
                  <label for="2">Non</label>
                </div>
              </div>
            </div>
          </div>
          <!-- Codes section -->
          <div *ngIf="!showChoice || gtin8choice !== null" class="Unity-form-section bottomPart row">
            <div class="Unity-form-section-content col-md-6 col-xs-2 bottomContent">
              <div class="Unity-form-subSection row">
                <div class="Unity-form-input col-md-4 col-xs-12">
                  <!--                                    -->
                  <span *ngIf="hasAvailableGtin13 && (unityForm.productForm.cipError.status !== 'valid'  || !cipLoaded) && gtin8choice == false">
                    <loader [color]="'orange'" [size]="'50px'"></loader>
                  </span>
                  <div class="Unity-form-input-gtin">
                    <!--GTIN13-->
                    <div *ngIf="gtin8choice === false || !hasAvailableGtin8">
                      <div class="gtin"
                           *ngIf="!submitting && unityForm.productForm.cipError.status === 'valid' && cipLoaded">{{ gtin13Value() }}
                      </div>
                      <div class="modifyGtinButton">
                        <span class="pencilIcon">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="#F26334"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M0 19.6869C0 19.7782 0.0259403 19.85 0.077821 19.9022C0.142672 19.9674 0.220493 20 0.311284 20H19.6887H19.7276C19.7536 20 19.7795 19.9935 19.8054 19.9804L19.8444 19.9413C19.8703 19.9413 19.8898 19.9348 19.9027 19.9217V19.9022C19.9157 19.8891 19.9287 19.8695 19.9416 19.8434C19.9546 19.8304 19.9676 19.8108 19.9805 19.7847V19.7456V19.7065C19.9935 19.7065 20 19.6999 20 19.6869C20 19.6738 19.9935 19.6608 19.9805 19.6478V19.6282V19.6086V19.589L18.0156 13.5225C18.0026 13.4964 17.9896 13.4768 17.9767 13.4638L17.9377 13.3855L7.27626 2.66145L7.25681 2.64188C7.25681 2.62883 7.25032 2.62231 7.23735 2.62231L4.92218 0.273973C4.72763 0.0913242 4.49416 0 4.22179 0C3.96239 0 3.73541 0.0913242 3.54086 0.273973L0.291829 3.56164C0.201038 3.65297 0.129702 3.75734 0.077821 3.87476C0.0259403 3.99217 0 4.11611 0 4.24658C0 4.37704 0.0259403 4.50098 0.077821 4.6184C0.129702 4.73581 0.201038 4.84018 0.291829 4.93151L2.56809 7.22114V7.26027C2.58106 7.26027 2.58755 7.2668 2.58755 7.27984L13.2296 18.0431L13.2879 18.1018C13.3139 18.1148 13.3398 18.1213 13.3658 18.1213L17.5486 19.3738H0.311284C0.220493 19.3738 0.142672 19.4064 0.077821 19.4716C0.0259403 19.5369 0 19.6086 0 19.6869ZM3.96887 6.34051L13.7354 16.047L13.3658 17.2994L3.24903 7.04501L3.96887 6.34051ZM14.2023 15.636L4.39689 5.90998L5.9144 4.38356L15.642 14.1683V15.636H14.2023ZM16.0311 13.6791L6.36187 3.93346L7.00389 3.28767L17.1012 13.4247L16.0311 13.6791ZM14.3191 16.2622H15.9533C16.0311 16.2622 16.1025 16.2361 16.1673 16.184C16.2322 16.1187 16.2646 16.0404 16.2646 15.9491V14.2661L17.5097 13.9726L19.2023 19.2172L13.9105 17.6321L14.3191 16.2622ZM0.622568 4.24658C0.622568 4.19439 0.629053 4.14873 0.642023 4.10959L0.719844 3.99217L3.98833 0.72407C4.05318 0.658839 4.131 0.626223 4.22179 0.626223C4.32555 0.613177 4.40986 0.645793 4.47471 0.72407L6.57588 2.83757L2.82101 6.59491L0.719844 4.48141C0.693904 4.45532 0.667964 4.4227 0.642023 4.38356C0.629053 4.33138 0.622568 4.28571 0.622568 4.24658Z"
                              fill="#F26334"/>
                              <path
                                d="M0 19.6869C0 19.7782 0.0259403 19.85 0.077821 19.9022C0.142672 19.9674 0.220493 20 0.311284 20H19.6887H19.7276C19.7536 20 19.7795 19.9935 19.8054 19.9804L19.8444 19.9413C19.8703 19.9413 19.8898 19.9348 19.9027 19.9217V19.9022C19.9157 19.8891 19.9287 19.8695 19.9416 19.8434C19.9546 19.8304 19.9676 19.8108 19.9805 19.7847V19.7456V19.7065C19.9935 19.7065 20 19.6999 20 19.6869C20 19.6738 19.9935 19.6608 19.9805 19.6478V19.6282V19.6086V19.589L18.0156 13.5225C18.0026 13.4964 17.9896 13.4768 17.9767 13.4638L17.9377 13.3855L7.27626 2.66145L7.25681 2.64188C7.25681 2.62883 7.25032 2.62231 7.23735 2.62231L4.92218 0.273973C4.72763 0.0913242 4.49416 0 4.22179 0C3.96239 0 3.73541 0.0913242 3.54086 0.273973L0.291829 3.56164C0.201038 3.65297 0.129702 3.75734 0.077821 3.87476C0.0259403 3.99217 0 4.11611 0 4.24658C0 4.37704 0.0259403 4.50098 0.077821 4.6184C0.129702 4.73581 0.201038 4.84018 0.291829 4.93151L2.56809 7.22114V7.26027C2.58106 7.26027 2.58755 7.2668 2.58755 7.27984L13.2296 18.0431L13.2879 18.1018C13.3139 18.1148 13.3398 18.1213 13.3658 18.1213L17.5486 19.3738H0.311284C0.220493 19.3738 0.142672 19.4064 0.077821 19.4716C0.0259403 19.5369 0 19.6086 0 19.6869ZM3.96887 6.34051L13.7354 16.047L13.3658 17.2994L3.24903 7.04501L3.96887 6.34051ZM14.2023 15.636L4.39689 5.90998L5.9144 4.38356L15.642 14.1683V15.636H14.2023ZM16.0311 13.6791L6.36187 3.93346L7.00389 3.28767L17.1012 13.4247L16.0311 13.6791ZM14.3191 16.2622H15.9533C16.0311 16.2622 16.1025 16.2361 16.1673 16.184C16.2322 16.1187 16.2646 16.0404 16.2646 15.9491V14.2661L17.5097 13.9726L19.2023 19.2172L13.9105 17.6321L14.3191 16.2622ZM0.622568 4.24658C0.622568 4.19439 0.629053 4.14873 0.642023 4.10959L0.719844 3.99217L3.98833 0.72407C4.05318 0.658839 4.131 0.626223 4.22179 0.626223C4.32555 0.613177 4.40986 0.645793 4.47471 0.72407L6.57588 2.83757L2.82101 6.59491L0.719844 4.48141C0.693904 4.45532 0.667964 4.4227 0.642023 4.38356C0.629053 4.33138 0.622568 4.28571 0.622568 4.24658Z"
                                stroke="#F26334" stroke-width="0"/>
                          </svg>
                        </span>
                        Édition avancée
                      </div>
                    </div>

                    <!--GTIN8-->
                    <div *ngIf="gtin8choice==true || !hasAvailableGtin13">
                      <div class="gtin" *ngIf="!submitting">{{ gtin8Value() }}</div>
                    </div>

                    <div class="hiddenFormContainer" *ngIf="gtin8choice === false || !hasAvailableGtin8">
                      <div class="row modifyFormContainer">
                        <!--GTIN prefix-->
                        <div class="Unity-form-input-gtin--prefix col-md-5 col-xs-3 text-center">
                          <!--{{unityForm.productForm.gtin.prefix}}-->
                          <select name="prefix" id="prefix"
                                  [(ngModel)]="unityForm.productForm.gtin.prefix"
                                  (ngModelChange)="changePrefix(); prefixGtin13 = unityForm.productForm.gtin.prefix">
                            <option *ngFor="let prefix of unityForm.sortedPrefixes"
                                    [ngValue]="prefix.code">{{ prefix.code }}
                            </option>
                          </select>
                          <loader class="Unity-form-input-gtin--loader"
                                  [hidden]="cipLoaded"></loader>
                        </div>
                        <!--GTIN CIP-->
                        <div class="Unity-form-input-gtin--cip col-md-5 col-xs-3 text-center"
                             [ngClass]="'formError-' + unityForm.productForm.cipError.status">
                          <input id="gtin" type="text"
                                 [(ngModel)]="unityForm.productForm.gtin.cip"
                                 (ngModelChange)="onCIPChange($event)"
                                 [maxLength]="12 - unityForm.productForm.gtin.prefix.length"
                                 name="cip" #cip="ngModel"
                                 pattern="{{cipPattern}}"
                                 autocomplete="off"
                                 [disabled]="unityForm.isNoGtinAvailable"
                                 required>
                          <div class="Unity-form-input-gtin--cipAvailability">
                            <img src="assets/icons/error.svg" alt="X"
                                 [hidden]="unityForm.productForm.cipError.status !== 'warning'">
                            <img src="assets/icons/ok.svg" alt="OK"
                                 [hidden]="unityForm.productForm.cipError.status !== 'valid'">
                          </div>
                          <loader class="Unity-form-input-gtin--loader"
                                  [hidden]="(unityForm.productForm.cipError.status === 'valid' && cipLoaded) || unityForm.isNoGtinAvailable"></loader>
                        </div>
                        <!--GTIN Key-->
                        <div class="Unity-form-input-gtin--key col-md-2 col-xs-2 text-center">
                          {{ unityForm.productForm.gtin.key }}
                        </div>
                      </div>
                      <div class="Unity-form-infoText row">
                        <div class="col-md-5 col-xs-3 text-center">{{ constants.createForm.helpers.prefix }}</div>
                        <div class="col-md-5 col-xs-3 text-center">
                          <div>{{ constants.createForm.helpers.cip }}</div>
                          <div>
                            {{ unityForm.productForm.gtin.cip.length }}
                            /{{ 12 - unityForm.productForm.gtin.prefix.length }}
                            chiffres
                          </div>
                        </div>
                        <div class="col-md-2 col-xs-2 text-center">{{ constants.createForm.helpers.key }}</div>
                      </div>
                    </div>

                    <!-- Visiblity -->
                    <div class="col-md-12  col-xs-2 visibilityContainer" *ngIf="!isRiskyProduct(unityForm.productForm)">
                      <ui-switch name="productVisibility" id="productVisibility"
                                 [checked]="!unityForm.productForm.isPrivate"
                                 (valueChange)="onVisibilityChange($event)">
                      </ui-switch>
                      <div class="Unity-form-input">
                        <label class="productVisibilityLabel" for="productVisibility">Publier ce GTIN
                        </label>
                        <div class="icon-help"
                             matTooltip="Publier ce GTIN rend visible ses informations clés aux adhérents ayant accès aux registres GS1."
                             matTooltipClass="mat-tooltip-right-arrow-left-mid"
                             matTooltipPosition="right">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Unity-form-input col-md-8 col-xs-12 ">
                  <div class="row" *ngIf="!unityForm.productForm.isPrivate && !isRiskyProduct(unityForm.productForm)">
                    <div class="col-md-2">
                      <img src="assets/icons/vbg-checked.svg"
                           [ngClass]="{ 'not-completed' : !isVbgAttributeCompleted()}">
                    </div>
                    <div class="col-md-7">
                      <div class="row">
                        <label class="col-md-12">{{ vbgBadgeTitle() }}</label>
                        <div class="col-md-12 badge-body">{{ vbgBadgeBody() }}</div>
                      </div>
                    </div>
                    <button type="button" class="col-md-3 complete-info complete-info-height"
                            *ngIf="!isVbgAttributeCompleted()" (click)="returnToFirstStep()">
                      Compléter les informations
                    </button>
                  </div>
                  <div class="row" *ngIf="unityForm.productForm.isPrivate">
                    <div class="col-md-12 private-panel">
                      <div class="row ">
                        <label>{{ titlePrivatePanel() }}</label>
                        <div>{{ firstTextPrivatePanel() }}</div>
                        <div>{{ secondTextPrivatePanel() }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row sectoral-section" [ngClass]="{'bdtop': !isRiskyProduct(unityForm.productForm)}"
                       *ngIf="!unityForm.productForm.isPrivate && !isGeneric">
                    <div class="col-md-2">
                      <img src="assets/icons/sectoral-checked.svg" class="sectoral-badge"
                           [ngClass]="{ 'not-completed' : !isSectoralAttributeCompleted()}">
                    </div>
                    <div class="col-md-7">
                      <div class="row">
                        <label class="col-md-12">{{ sectoralBadgeTitle() }}</label>
                        <div class="col-md-12 badge-body" [innerHTML]=sectoralBadgeBody()></div>
                      </div>
                    </div>
                    <button type="button" class="col-md-3 complete-info" *ngIf="!isSectoralAttributeCompleted()"
                            (click)="returnToSectoralStep()" [ngClass]="{'complete-info-height': screenResolution()}">
                      {{ btnSectoralWording() }}
                    </button>
                  </div>

                </div>
              </div>
              <div class="Unity-form-subSection row">
                <!--GTIN info block-->
                <div class=" col-md-6  col-xs-6">
                  <div class="Unity-form-input-gtin--infoBlock"
                       [hidden]="unityForm.productForm.cipError.status !== 'warning' || (showChoice && gtin8choice===true) || (!showChoice && !hasAvailableGtin13)"
                       [ngClass]="unityForm.productForm.cipError.status">
                    {{ unityForm.productForm.cipError.message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Unity-footer">
        <div class="Unity-footer-buttons" role="group">
          <button gs1Button [appearance]="'outlined'" (click)="returnBack(false)">Précédent</button>

          <button gs1Button
                  (click)="updateUser(); sendForm(false, null, unityForm.productForm.adhereToTheSectoralAgreement);"
                  [disabled]="submitting ||(hasAvailableGtin13  && gtin8choice===false && (unityForm.productForm.cipError.status !== 'valid' ||  !cipLoaded)) || (showChoice &&gtin8choice===null) || (unityForm.isNoGtinAvailable && showChoice && gtin8choice===false) || (unityForm.isNoGtinAvailable && !showChoice && hasAvailableGtin13)">
            <!--            <span [hidden]="!submitting && unityForm.productForm.cipError.status === 'valid' &&  cipLoaded"><loader-->
            <!--              [color]="'white'" [top]="'2px'"></loader></span>-->
            Terminer
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<publishing-description #PublishingDescriptionModal></publishing-description>
