<div class="Settings-prefixes-page">
    <div class="Unity-header">
        <div class="col-lg-3 col-md-2 col-md-offset-5 text-center settingsHeader">
            {{constants.settings.prefixes.header.title}}
        </div>
        <div class="col-lg-12 col-md-12" style="border-bottom: solid 1px rgba(242, 242, 242, 1); height: 2em;"></div>
        <div class="col-lg-8 col-md-8 col-md-offset-3 headerList">
            <ul>
                <li>{{constants.settings.prefixes.header.list.first}}</li>
                <li>{{constants.settings.prefixes.header.list.second}}</li>
                <li>{{constants.settings.prefixes.header.list.third}}</li>
                <li>{{constants.settings.prefixes.header.list.fourth}}</li>
            </ul>
        </div>
    </div>
    <div class="prefixes-body">
        <div class="container-fluid">
            <div class="row prefixes-container">
                <div class="col-md-offset-2 col-md-9">
                    <div class="row " *ngIf="prefixes.length > 0">
                        <div class="col-md-12">
                            <div class="row text-center prefixes-header">
                                <div class="col-md-2">
                                    <span>Préfixe(s)</span>
                                    <div class="icon-help"
                                         popoverTitle="{{constants.settings.prefixes.prefixes.title}}"
                                         popover="{{constants.settings.prefixes.prefixes.content}}"
                                         placement="right"
                                         triggers="mouseenter:mouseleave">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <span>Activation</span>
                                    <div class="icon-help"
                                         popoverTitle="{{constants.settings.prefixes.activation.title}}"
                                         popover="{{constants.settings.prefixes.activation.content}}"
                                         placement="right"
                                         triggers="mouseenter:mouseleave">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <span>Reprendre</span>
                                    <div class="icon-help"
                                         popoverTitle="{{constants.settings.prefixes.resumeCodification.title}}"
                                         popover="{{constants.settings.prefixes.resumeCodification.content}}"
                                         placement="right"
                                         triggers="mouseenter:mouseleave">
                                    </div>
                                    <br/>
                                    <span class="sub-header">la codification à partir <br/>d'un GTIN donné</span>
                                </div>
                                <div class="col-md-4" [hidden]="!showHeader()">
                                    <span>Précisez le GTIN à partir duquel</span>
                                    <br/>
                                    <span class="sub-header">vous souhaitez reprendre la<br/>codification. Par défaut, le prochain<br/> GTIN disponible est proposé</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row prefix-item-container" *ngFor="let prefix of prefixes;let idx = index">
                        <div class="col-md-7">
                            <div class="row box text-center">
                                <div class="col-md-4 col-md-offset-8">
                                    <span class="options-description">Reprendre la codification</span>
                                </div>
                                <div class="col-md-3 progress-container">{{prefix.prefix}}</div>
                                <div class="col-md-5">
                                    <ui-switch [(ngModel)]="prefix.active" (ngModelChange)="onValueChange(prefix, idx)">
                                    </ui-switch>
                                </div>
                                <div class="col-md-4 options-radio-container">
                                    <label class="radio-inline">
                                        <input type="radio" name="prefix-resume-from-{{idx}}"
                                               [(ngModel)]="prefix.resumeCodification" [value]="false"
                                               [disabled]="!prefix.active"> Non
                                    </label>
                                    <label class="radio-inline">
                                        <input type="radio" name="prefix-resume-from-{{idx}}"
                                               [(ngModel)]="prefix.resumeCodification" [value]="true"
                                               [disabled]="!prefix.active"> Oui
                                    </label>
                                </div>
                                <div class="col-md-5 col-md-offset-3">
                                    <span class="radio-description" *ngIf="prefix.active">Activé</span>
                                    <span class="radio-description" *ngIf="!prefix.active">Désactivé</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 prefix-item-resume-codification" *ngIf="prefix.resumeCodification">
                            <div class="container-fluid box" *ngIf="prefix.startGtin !== -1"
                                 [ngClass]="{'error-box': prefix.error && prefix.error.box}">
                                <div class="row">
                                    <div class="col-md-4 gtin-prefix">{{prefix.gtin.prefix}}</div>
                                    <div class="col-md-4 gtin-cip">
                                        <input type="text"
                                               pattern="{{ getValidationPattern(prefix.gtin.cipSize)}}"
                                               autocomplete="off"
                                               [(ngModel)]="prefix.gtin.cip"
                                               (ngModelChange)="onCIPChange(prefix)"
                                               [maxLength]="prefix.gtin.cipSize"
                                        />
                                    </div>
                                    <div class="col-md-2 gtin-key">{{ prefix.gtin.key}}</div>
                                    <div class="col-md-2 gtin-state">
                                        <img *ngIf="prefix.gtin.available" src="assets/icons/ok.svg"/>
                                        <img *ngIf="!prefix.gtin.available" src="assets/icons/error.svg"/>
                                    </div>
                                </div>
                                <div class="row gtin-info">
                                    <div class="col-md-4 gtin-prefix-info">Préfixe</div>
                                    <div class="col-md-4 gtin-cip-info">
                                        Plage
                                        d'attribution {{prefix.gtin.cip.length}}/{{prefix.gtin.cipSize}}
                                    </div>
                                    <div class="col-md-2 gtin-key-info">Clé</div>
                                </div>
                                <div class="row gtin-error" *ngIf="prefix.error.message && prefix.error.message !== ''">
                                    <div class="col-md-12">
                                        {{ prefix.error.message}}
                                    </div>
                                </div>
                            </div>
                            <div class="row box gtin-error" *ngIf="prefix.startGtin === -1">
                                <div class="col-md-12">Vous êtes arrivés à la fin de votre plage de GTIN pour ce
                                    préfixe.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="prefixes-footer">

        <div class="completionLevel">

        </div>
        <div class="prefixes-footer-buttons" role="group">
            <button type="button" class="cancelButton" (click)="gotoDashboard()">Annuler</button>
            <button type="submit" class="submitButton" (click)="updatePrefixes()">
                <span>Valider</span>
            </button>
        </div>
    </div>
</div>