<div #unreachableProductModal>
  <h1 mat-dialog-title>Fiche produit inaccessible</h1>
  <div mat-dialog-content>
    <div>Vous devez mettre à jour la catégorie de votre produit afin d’accéder à ses informations.</div>
  </div>
  <div mat-dialog-actions>
    <button gs1Button [thin]="true" [appearance]="'outlined'" [mat-dialog-close]="false" (click)="navigateToDashboard()">Revenir à la liste
    </button>
    <button gs1Button [thin]="true" [mat-dialog-close]="true"cdkFocusInitial (click)="updateCategory(data.productId)">Mettre à jour
    </button>
  </div>
</div>
