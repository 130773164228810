<div class="product-update-category defineScrollbar">
  <div class="row" style="padding-bottom: 100px;">
    <div class="col-md-4 old-category-container">
      <div class="row">
        <div class="col-md-11 title">
          <h1>Choisissez la nouvelle catégorie de votre produit</h1>
        </div>
        <div class="col-md-11 old-category-card">
          <p class="card-title">
            Ancienne catégorie de votre produit
          </p>
          <div>
            {{oldCategory}}
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-4 results-container">
      <ul class="col-md-12" [ngClass]="{'result-scroll': categories.length > 0}">
        <li *ngFor="let category of categories" (click)="showDetails(category)">
          <ul>
            <li [innerHTML]="category.text"></li>
            <li class="result-breadcrumb">{{category.breadcrumbs}}</li>
          </ul>
        </li>
      </ul>
    </div>


    <div class="col-md-4 details-container">
      <div class="col-md-12 details-box defineScrollbar" [hidden]="isPickedCategory === true || !categoryDetails.text">
        <h2 class="col-md-12">{{categoryDetails.text}}</h2>
        <span class="col-md-12 code-category">
                        Code: {{categoryDetails.code}}
                </span>
        <span class="col-md-12" [innerHTML]="categoryDetails.definition"></span>
        <div class="col-md-12 bookmark-category-actions">
          <div class="col-md-6 text-center pull-right">
            <button class="select-btn" (click)="updateProductCategory(categoryDetails)">
              Selectionner
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
