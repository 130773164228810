<bs-modal size="lg" cssClass="customModal" #modal class="validation-modal" [keyboard]="false" [backdrop]="'static'">
  <bs-modal-body class="padding">
    <h2>Vous êtes sur le point de modifier les informations de ce GTIN</h2>
    <p>
      Changer la marque principale, le nom du produit, ou le contenu net de votre produit n’est pas recommandé et
      contraire
      <a href="https://www.gs1.org/1/gtinrules/fr/" target="_blank">
        aux règles de codification GS1
      </a>.
    </p>
    <p>
      Nous vous recommandons d’en créer un nouveau avec les informations mises à jour.
    </p>
  </bs-modal-body>
  <bs-modal-footer>
    <button id="confirmButton" type="button" class="apply-button" (click)="confirm()">
      Continuer quand même
    </button>
    <button id="cancelButton" type="button" class="cancel-button" (click)="close()">
      Annuler la modification
    </button>
  </bs-modal-footer>
</bs-modal>
