<div class='product-creation-choice-category'
     [ngClass]="{'classifications-is-shown':showClassification === true, 'hasBackground': showCategory !== false || showProductReseller !== false}">
    <product-reseller *ngIf="showProductReseller === true && showCategory !== true"
                      (onRedirectToChoiceCategory)="changeShowCategory($event.showCategory, $event.productResellerType)"></product-reseller>
    <product-category *ngIf="showCategory === true && showProductReseller !== true" (onValidateCategory)="changeShowCategory($event.showCategory)"
                      (onSelectCategory)="categorySelected($event.category)"
                      (onNavigateToClassification)="navigateToClassification()"
                      [isBundle]="productResellerType === brandTypes.BUNDLE"
                      [isNotNew]="productResellerType === brandTypes.NOT_NEW"></product-category>
    <classification (onSelectCategory)="categorySelected($event.category)" *ngIf="showClassification === true"
                    class="classification"></classification>
    <product-creation-new-way *ngIf="!showClassification && showCategory === false && showProductReseller === false"
                              [selectedCategory]="selectedCategory"
                              [productResellerType]="productResellerType"
                              #productCreationNewWay
                              (onModifyCategory)="showCategory = true" [productType]="productType"
                              [isSpi]="isSpi" [isWine]="isWine"
                              [isNotNew]="productResellerType === brandTypes.NOT_NEW"
                              [isBundle]="productResellerType === brandTypes.BUNDLE">
    </product-creation-new-way>

</div>

<classification-onboarding-modal
        *ngIf="(userSvc.user.showProductOnBoarding === undefined || userSvc.user.showProductOnBoarding === true) && showProductReseller !== true" [isBundle]="productResellerType === brandTypes.BUNDLE"
        #classificationOnBoardingModal (onCloseFirstModal)="openSecondModal()">
</classification-onboarding-modal>
<classification-second-onboarding-modal *ngIf="showSecondModal === true" #classificationSecondOnBoardingModal
                                        (onCloseSecondModal)="onCloseSecondModal($event.noShow)" [isBundle]="productResellerType === brandTypes.BUNDLE"></classification-second-onboarding-modal>
