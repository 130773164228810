<div class="import-body">
    <div class="row">
        <div class="col-xs-6 text-center fileSection">
            <label class="col-xs-12 "> 1. {{constants.import.newWay.updateProducts.firstSection.title}}</label>
            <div class="col-xs-12 " style="height: 2em"></div>
            <div class="col-xs-9 textSection col-xs-push-2 updateGtin">
                <div class="col-xs-12">
                    <div class="row text-left">
                        <img class="col-xs-2 download-img" (click)="exportAllProducts()" src="assets/icons/download-file.svg">
                        <div class="col-xs-9 textStyle nopadding">
                            <span class="exportMyDataBase" (click)="exportAllProducts()">Exporter tous mes produits</span> ({{productsSvc.totalAllProducts | number | spaceDivider}})
                        </div>
                        <div class="col-xs-12 " style="height: 2em"></div>
                        <div class="col-xs-12 text-center textStyle" style="height: 3em">
                            ou
                        </div>
                        <div class="col-xs-11 textStyle" style=" text-align: justify;">
                            Exportez une sélection de produits depuis le <span class="dashboardTxt" (click)="goToDashboard()">tableau de bord</span> et passez à l’étape suivante.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-5 col-xs-push-1">
            <label class="col-xs-9"> 2. {{constants.import.newWay.updateProducts.secondSection.title}}</label>
            <div class="col-xs-12" style="height: 2em"></div>
            <div class="col-xs-11 textSection updateGtin">
                <div class="row">
                    <div class="col-xs-1">
                        <img class="info-img" src="assets/icons/info-ico.svg">
                    </div>
                    <div class="col-xs-11">
                        {{constants.import.newWay.updateProducts.secondSection.firstText}}
                    </div>
                    <div class="col-xs-12" style="height: 2em"></div>
<!--                    <div class="col-xs-1">-->
<!--                        <img class="info-img" src="assets/icons/warningTriangleGrey.svg">-->
<!--                    </div>-->
<!--                    <div class="col-xs-11">-->
<!--                        {{constants.import.newWay.updateProducts.secondSection.secondText}}-->
<!--                    </div>-->
                    <div *ngIf="!isVcs()">
                        <div class="col-xs-12" style="height: 2em"></div>
                        <div class="col-xs-1">
                            <img class="info-img" src="assets/icons/warningTriangleGrey.svg">
                        </div>
                        <div class="col-xs-11">
                            {{constants.import.newWay.updateProducts.secondSection.thirdText}}
                        </div>
                    </div>
                    <div *ngIf="isVcs()">
                        <div class="col-xs-12" style="height: 2em"></div>
                        <div class="col-xs-1">
                            <img class="info-img" src="assets/icons/warningTriangleGrey.svg">
                        </div>

                        <div class="col-xs-11">
                            <div style="color:#F26334;">
                                {{constants.import.newWay.text.createGtins.secondText.vcs}}
                            </div>
                            <br>
                            <div>
                                {{constants.import.newWay.text.createGtins.secondText.vcsExample}}
                            </div>
                        </div>
                        <div class="col-xs-12" style="height: 2em"></div>
                        <div class="col-xs-1">
                            <img class="info-img" src="assets/icons/info-ico.svg">
                        </div>
                        <div class="col-xs-11">
                            {{constants.import.newWay.text.createGtins.thirdText}}
                        </div>
                        <div class="col-xs-12" style="height: 2em"></div>

                        <div class="col-xs-1">
                            <img class="info-img" src="assets/icons/info-ico.svg">
                        </div>
                        <div class="col-xs-11" style="top:8px;">
                            {{constants.import.newWay.text.createGtins.fourthText}}
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="new-way-import-import-fill-template-content col-xs-10 col-xs-push-2" *ngIf="!isVcs()">

            <div id="moreSectionLink" class="import-fill-template-content-section col-xs-11 text-center">
                <a [ngClass]="{'collapsed': !isMoreSectionOpened}" (click)="toggleMoreSection()">
                    <span>{{constants.import.fill.common.more}}</span><img
                        [src]="isMoreSectionOpened ? '/assets/icons/sortArrowUp.svg' : '/assets/icons/sortArrowDown.svg'">
                </a>
            </div>
            <div id="moreSection" class="import-fill-template-content-more col-xs-11"
                 [ngClass]="{'collapse': !isMoreSectionOpened}">
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.gtin.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.gtin.title}}"
                         popover="{{constants.inputsInfo.gtin.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p [innerHtml]="constants.import.fill.withGtin.gtin.description"></p>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productName.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.name.title}}"
                         popover="{{constants.inputsInfo.name.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p [innerHtml]="constants.import.fill.common.productName.description"></p>
                    <p>{{constants.import.fill.common.productName.tips.title}}</p>
                    <ul>
                        <li *ngFor="let value of constants.import.fill.common.productName.tips.values">
                            {{value}}
                        </li>
                    </ul>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productBrand.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.brand.title}}"
                         popover="{{constants.inputsInfo.brand.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p [innerHtml]="constants.import.fill.common.productBrand.description"></p>
                    <p>{{constants.import.fill.common.productBrand.tips.title}}</p>
                    <ul>
                        <li *ngFor="let value of constants.import.fill.common.productBrand.tips.values">
                            {{value}}
                        </li>
                    </ul>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productSubBrand.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.subBrand.title}}"
                         popover="{{constants.inputsInfo.subBrand.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p>{{constants.import.fill.common.productSubBrand.description}}</p>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productImageUrl.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.images.title}}"
                         popover="{{constants.inputsInfo.images.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p *ngFor="let description of constants.import.fill.common.productImageUrl.description">
                        {{description}}</p>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productDescription.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.description.title}}"
                         popover="{{constants.inputsInfo.description.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p>{{constants.import.fill.common.productDescription.description}}</p>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productWebLink.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.webLink.title}}"
                         popover="{{constants.inputsInfo.webLink.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p *ngFor="let description of constants.import.fill.common.productWebLink.description">
                        {{description}}</p>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productMPN.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.mpn.title}}"
                         popover="{{constants.inputsInfo.mpn.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p>{{constants.import.fill.common.productMPN.description}}</p>
                </div>
                <div class="import-fill-template-content-section">
                    <p class="import-fill-template-content-section-title">
                        {{constants.import.fill.common.productSKU.title}}</p>
                    <div class="icon-help"
                         popoverTitle="{{constants.inputsInfo.sku.title}}"
                         popover="{{constants.inputsInfo.sku.content}}"
                         placement="right"
                         triggers="mouseenter:mouseleave">
                    </div>
                    <p>{{constants.import.fill.common.productSKU.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<product-export-modal></product-export-modal>
