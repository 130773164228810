<bs-modal size="lg" cssClass="customModal" #modal class="qrcode-modal" [keyboard]="false" [backdrop]="'static'">
  <bs-modal-body class="padding">
    <h2>Création du QR Code augmenté GS1</h2>
    <p class="closeBtn" (click)="close()"><i class="bi bi-x-lg"></i></p>
    <div class="wrapper">
      <div class="left-pan">
        <div class="first-section">
          <p><span>1. </span>Insérez l'URL de destination à encoder dans le QR Code.</p>
          <p><span>2. </span>Ajustez la taille du QR Code selon votre besoin.</p>
          <p><font>Vous pouvez le tester en le scannant avec votre téléphone</font></p>
          <p><span>3. </span>Appuyez sur le bouton « Télécharger » et utilisez votre QR Code (téléchargement au format
            SVG uniquement)</p>
        </div>
        <div class="second-section bar">
          <h3 class="padding">Recommandations GS1</h3>
          <div class="padding">
            En cette période de transition, le QR Code augmenté GS1 pourrait ne
            pas être lu en caisse. Par conséquent celui-ci devra être associé à un
            code-barres. Assurez-vous de bien respecter le placement de ces deux
            symboles. <br>
            <a href="https://www.gs1.fr/publication/guide-mise-oeuvre-codes-barres-2d-point-vente-du-commerce-detail"
               target=”_blank”>
              Consulter le guide (page 24)
              <i class="bi bi-arrow-right"></i>
            </a>
          </div>
          <h3>Impression et taille du QR Code</h3>
          <div>
            Pour une qualité optimale d’impression sur votre produit et la bonne
            lecture du QR Code, choisissez sa taille en respectant les limites fixées
            et veillez à ne pas déformer, étirer ou réduire l’image téléchargée : <br>
            <span class="text">
                Taille minimum 13,07 mm - taille maximum 32,7 mm <br>(zone de silence incluse).
              </span>
          </div>
        </div>
      </div>
      <div class="right-pan">
        <div class="loader" *ngIf="showLoader(); else hideLoader"
             gs1Spinner [big]="true" [size]="'80px'" [mainLoader]="true">
        </div>
        <ng-template #hideLoader>
          <div class="content">
            <div class="qr-code">
              <img [src]="imageData" id="qrimage" alt="qr code" *ngIf="formReady && imageData">
            </div>
            <div class="radio-group">
              <div id="notCompleteVCS" *ngIf="isCompleteVcs(); else isNotCompleteVCS">
                <label class="radio">
                  <input #radioDefaultURL type="radio" id="gs1" name="choice" [checked]="!isCustom"
                         (click)="purgeUrl(false);">
                  Utiliser l’URL de la page produit GS1 France
                  <span>En conformité avec la règlementation sectorielle en vigueur</span>
                </label>
                <label class="radio">
                  <input #radioCustomURL type="radio" id="custom" name="choice" [checked]="isCustom"
                         (click)="purgeUrl(true, link);">
                  Utiliser une autre URL
                  <span>Insérez le lien externe de votre choix (site web, page web, etc.)</span>
                </label>
              </div>
              <ng-template #isNotCompleteVCS>
                <div class="isNotCompleteVCS">
                  URL de destination
                </div>
              </ng-template>
              <div>
                <input [ngClass]="{'isNotEmpty': isEmptyUrl === false}" (keyup)="isEmptyUrl = false" type="url" placeholder="https://"
                       name="link" [pattern]="regEx" #link="ngModel" [(ngModel)]="url" (focusout)="onFocusOut(link)"
                       [appForbiddenUrls]="['gs1.fr', 'codeonline.fr']" [hidden]="!isCustom" required>
                <div *ngIf="link.invalid && (link.dirty || link.touched) && isEmptyUrl == false"
                     class="error-input">
                  Merci de renseigner une URL valide
                </div>
              </div>
              <div class="new-dimension-input">
                <label>Taille de téléchargement</label>
                <input [ngClass]="{'dimensions-class': isSizeEmpty() || sizeOutOFRange()}" type="number" #sizeInput="ngModel" name="dimensions" min="13.07" max="32.7" [(ngModel)]="size"
                       value="32.7" step="0.01" required>
                <div class="error-input" *ngIf="isSizeEmpty()">
                  Veuillez renseigner la taille de téléchargement
                </div>
                <div class="error-input" *ngIf="sizeOutOFRange()">
                  Veuillez saisir une taille comprise entre 13,07 et 32,7mm
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-pan">
            <button type="button" class="download-qr-button" (click)="downloadQRCode()"
                    *ngIf="formReady && imageData && this.type && (isValidLink||!this.isCustom) && sizeInput.valid">
              Télécharger
            </button>
            <button type="button" class="download-qr-button disabled"
                    *ngIf="!formReady || !imageData || !this.type || (!isValidLink&&this.isCustom) || sizeInput.invalid">
              Télécharger
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </bs-modal-body>
</bs-modal>
