<!-- <div class="publishingDescriptionModalContainer"> -->
    <bs-modal #modal class="publishingDescriptionModal" [animation]="true" [keyboard]="true" [backdrop]="true" [size]="'lg'" [cssClass]="'publishing-description-modal-content'">
        <bs-modal-header cssClass="publishingDescriptionModalHeader" [showDismiss]="true">À quoi correspond la publication ?</bs-modal-header>
        <bs-modal-body>
            <div class="publishingDescriptionModalBody">
                <div class="intro">
                    Publier vos informations produits, c'est :
                </div>
                <div class="points">
                    - Mettre à disposition vos informations produits à nos 2 millions de membres dans le monde via les services de GS1 pour faciliter leur référencement
                </div>
                <!--
                    ON ATTEND QUE LE MOTEUR DE RECHERCHE SOIT EN PROD
                <div class="points">
                    - Rendre visible vos produits sur le web avec le moteur de recherche de CodedOnline (<a href="https://www.codeonline.fr/search" target="_blank">www.codeonline.fr/search</a>)
                </div> -->
                <div class="points">
                    - Rendre visible vos produits sur le web avec le moteur de recherche de CodeOnline (à venir)
                </div>
                <div class="attention">
                    <span style="font-weight: bold; text-decoration: underline;">Attention</span>: La fonctionnalité de publication n'envoie pas automatiquement vos informations produits à vos clients.
                </div>
                <div class="footer">
                    Vous êtes toujours responsable de la déclaration des informations liées à vos produits. CodeOnline permet simplement de faciliter l'accès à vos informations produits pour améliorer les processus de référencement,
                        de qualité et fluidifier les interactions avec vos différents partenaires commerciaux.
                </div>
            </div>
        </bs-modal-body>
        <!-- <modal-footer>
        </modal-footer> -->
    </bs-modal>
<!-- </div> -->
