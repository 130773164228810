<div class="alert-box" [className]="cssClass" id="warningBox">
  <div class="title">
    <img *ngIf="withIcon" src="assets/icons/info-circle.svg" alt="titre">
    <span>{{this.title}}</span>
  </div>

  <div class="body" >
    <ng-content></ng-content>
  </div>

</div>
