<div class='product-edition-choice-category' [ngClass]="{'classifications-is-shown': showClassification === true}">
  <product-category *ngIf="showCategory === true"
                    (onNavigateToClassification)="navigateToClassification()"
                    (onSelectCategory)="categorySelected($event.category)"></product-category>
  <classification (onSelectCategory)="categorySelected($event.category)"
                  *ngIf="showClassification === true" class="classification"></classification>
  <div class="defineScrollbar" id="defineScrollbar"
       [ngClass]="{'unity-consumer-edition-generic': isGeneric, 'Unity Consumer': isVcs || isFood}"
       *ngIf="unityForm && showClassification !== true && showCategory !== true">
    <form class="form" (ngSubmit)="submitForm()" #formProduct="ngForm" ngNativeValidate>
      <unity-consumer-edition-generic
        *ngIf="!showClassification && showCategory === false && this.product"
        [product]="product"
        [unityForm]="unityForm"
        (onModifyCategory)="modifyCategory()" [isVcs]="isVcs">
      </unity-consumer-edition-generic>
      <div class="row section-btn-complete-sectoral"
           *ngIf="!featureToggleSvc.isWhalesUser() && this.showAdhereSectoralBtn">
        <div></div>
        <div class="btn" (click)="adhereToTheSectoralAgreement()">
          {{btnCompletionWording()}}
        </div>
      </div>

      <div *ngIf="featureToggleSvc.isWhalesUser && isVcs" class="col-md-12 col-xs-12 line"></div>
      <unity-consumer-edition-food class="to-scroll-to"
                                   *ngIf=" !showClassification &&  showCategory === false && isFood === true && this.product"
                                   [unityForm]="unityForm"
                                   [product]="product" [submitEvent]="submitSubject.asObservable()">
      </unity-consumer-edition-food>
      <unity-consumer-edition-vcs class="to-scroll-to" #unityConsumerEditionVcsComponent
                                  *ngIf="!this.isBundle && !showClassification &&  showCategory === false && isVcs === true && this.product && featureToggleSvc.isWhalesUser()"
                                  [unityForm]="unityForm"
                                  [isSpi]="isSpi"
                                  [isWine]="isWine"
                                  [product]="product"
                                  [submitEvent]="submitSubject.asObservable()"></unity-consumer-edition-vcs>

      <unity-consumer-edition-vcs-old class="to-scroll-to"
                                      *ngIf="!this.isBundle && !showClassification &&  showCategory === false && isVcs === true && this.product && !featureToggleSvc.isWhalesUser()"
                                      [unityForm]="unityForm"
                                      [isSpi]="isSpi"
                                      [isWine]="isWine"
                                      [product]="product"
                                      [submitEvent]="submitSubject.asObservable()"></unity-consumer-edition-vcs-old>
      <div class="footer">
        <div class="footer-buttons" role="group">
          <button gs1Button [appearance]="'outlined'" (click)="unityForm.cancelCreation()">Fermer</button>
          <button type="submit" gs1Button
                  [class.disabled]="this.unityForm.submitting || this.webLinkError.status === 'warning' ||
                             (isVcs === true  && unityConsumerEditionVcsComponent?.hasSetAllRegulatoryAttribute() === false && unityForm.productForm.adhereToRegulatoryAgreement === true) ||
                              (isVcs === true && !unityConsumerEditionVcsComponent?.hasSetAllSectoralAttributes() && unityForm.productForm.adhereToTheSectoralAgreement === true)">
            <span>Valider</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>


<confirmation-modal [isDeleteDialog]="true" #confirmationModal [cancelBtnLabel]="'Annuler'" [submitBtnLabel]="'Valider'"
                    [title]="constants.deleteProduct.title" [body]="constants.deleteProduct.message"
                    (onConfirm)="unityForm.deleteProduct()"></confirmation-modal>
<publishing-description #PublishingDescriptionModal></publishing-description>
<app-validation-edition-product (confirmEvent)="validEdition($event)"></app-validation-edition-product>
