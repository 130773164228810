import {debounceTime} from 'rxjs/operators';
import {
  AfterViewChecked,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subject} from 'rxjs';

import {CompanySvc, NotificationsSvc, UserSvc} from '../../../shared/services/index';
import {BarcodeGenerationSvc, ProductsSvc} from '../../services/index';
import {DashboardConst, Product, ProductResellerTypes} from '../../models/index';
import {DashboardHeaderSvc} from '../../services/dashboard-header.service';
import {ProductExportModal} from '../product-export-modal/product-export-modal.component';
import {ProductImportModal} from '../product-import-modal/product-import-modal.component';
import {FeatureToggleSvc} from '../../../shared/services/feature-toggle.service';
import {SharedDashboardSvc} from '../../services/sharedDashboardSvc';
import {ImportTypes} from '../../../shared/models/index';
import * as $ from 'jquery';

import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'dashboard-products',
  styleUrls: ['./dashboard-products.less'],
  templateUrl: './dashboard-products.tpl.html'
})
export class DashboardProducts implements OnInit, AfterViewChecked {


  public constants = DashboardConst;
  public allSelected: boolean = false;
  public actionInProgress: boolean = false;
  public nbProductsSelected: number = 0; // value only used for the html
  public searchText: string = '';
  public copyGtinToolTip: string = this.constants.copyGtinLabel;
  public clientSpaceUrl = environment.GCP_PAGE;
  public import: any;

  private previousSearchText: string = '';
  private searchProductStream = new Subject<string>();
  // For the infinite scroll
  private noMoreProductsToLoad: boolean = false;
  private loadingNextProducts: boolean = false;
  private offset: number = 0;
  private length: number = 100;
  @ViewChild(ProductExportModal, {static: true})
  private productExportModal: ProductExportModal;

  @ViewChild(ProductImportModal, {static: true})
  private productImportModal: ProductImportModal;

  // UNCOMMENT THIS WHEN ACTIVATING THE "NEW FEATURE INFORMATION MODAL FOR FIRST TIME LOGIN"
  // @ViewChild(NewFeatureInformationModalComponent, {static: true})
  // private newFeatureInformationModal: NewFeatureInformationModalComponent;

  constructor(public productsSvc: ProductsSvc,
              public companySvc: CompanySvc,
              public featureToggleSvc: FeatureToggleSvc,
              public userSvc: UserSvc,
              private router: Router,
              private route: ActivatedRoute,
              private headerSvc: DashboardHeaderSvc,
              private barcodeGenerationSvc: BarcodeGenerationSvc,
              private notifSvc: NotificationsSvc,
              private sharedDasboardSvc: SharedDashboardSvc
  ) {

    this.searchProductStream.pipe(
      debounceTime(400))
      .subscribe((text) => {
        this.searchProducts(text);
      });
  }

  public ngAfterViewChecked(): void {
    if (this.productsSvc.totalProducts >= this.companySvc.company.codesAvailable && !this.hasGtin8AvailableForCreation()) {
      $('#button-basic').prop('disabled', true);
    } else {
      $('#button-basic').prop('disabled', false);
      if (this.productsSvc.totalProducts >= this.companySvc.company.codesAvailable) {
        $('#create-many-gtin').addClass('disabled');
      }
    }
  }

  public ngOnInit() {
    this.headerSvc.setHeaderData(this.constants.headers.dashboard.title, '');
    this.productsSvc.resetMetadata();
    this.displayloader();
    this.getProducts();
    this.route.queryParams.subscribe((params: Params) => {
      if (params['actionInProgress']) {
        this.actionInProgress = true;

        setTimeout(() => {
          this.getProducts();
          this.actionInProgress = false;
        }, 1500);
        setTimeout(() => this.getProducts(), 3000);
      }
    });
  }
  public hasGtin8AvailableForCreation(): boolean {
    if (this.companySvc.company.newGtin8Available === 0) {
      return false;
    }
    return this.companySvc.company.newGtin8Available > this.productsSvc.totalNewGtin8Products;
  }

  public getProducts() {
    this.productsSvc.getCustomerProducts(0, 100, true)
      .subscribe(
        result => {
          this.productsSvc.productsLoaded = true;
          this.noMoreProductsToLoad = this.productsSvc.sortConf.field === '' ? this.productsSvc.lastEvaluatedKey === undefined : result.products.length === 0;
          this.productsSvc.myProducts = result.products;
        },
        err => console.error('Could not retrieve the products: ', err)
      );

      this.productsSvc.getNewGtin8Products().subscribe(
        result => {
          this.productsSvc.totalNewGtin8Products = result.total;
          this.productsSvc.myNewGtin8Products = result.products;
        }
      );

      this.productsSvc.getCountProductsBasedOnAvailablePrefix().subscribe(
        result => {
          this.productsSvc.totalProducts = result;
        });

    this.productsSvc.getCountProductsWithOldCodeGpc().subscribe(
      result => {
        this.productsSvc.totalProductsWithOldGpcCode = result;
      }
    );
  }

  public getProductName(products) {
    products.forEach((item) => {
      if (!_.isEmpty(item.productNames)) {
        item.name =  _.get(_.first(item.productNames), 'value', '');
      }
      if (item.productResellerType === ProductResellerTypes.NOT_NEW){
        item.name = this.constants.tags.not_new + item.name;
      }
    });
    return _.isEmpty(products) ? [] : products;
  }

  public selectProduct(product: Product, value?: boolean) {
    if (product.hasOwnProperty('selected')) {
      product.selected = value !== undefined ? value : !product.selected;
    } else {
      product.selected = value !== undefined ? value : true;
    }
    this.nbProductsSelected = _.filter(this.productsSvc.myProducts, {selected: true}).length;
  }

  public selectAllProducts(value: boolean) {
    this.allSelected = value;
    this.productsSvc.myProducts.map(product => this.selectProduct(product, value));
    this.nbProductsSelected = value ? this.productsSvc.myProducts.length : 0;
  }

  public getSelectedProducts(): Product[] {
    return _.filter(this.productsSvc.myProducts, {selected: true});
  }

  public createProduct() {
    this.sharedDasboardSvc.toggle();
  }

  public createMultipleProducts() {
    if (this.featureToggleSvc.canVcsImport()) {
      this.downloadImportTemplate();
      return;
    }

    this.importProducts();
    return;
  }

  public createGtins() {
    if (this.featureToggleSvc.canSeeNewImportWay()) {
      this.router.navigate(['../import/template', ImportTypes.CREATE_GTINS], {relativeTo: this.route});
      return;
    }

    this.createMultipleProducts();
  }

  public ImportMyGtins() {
    this.router.navigate(['../import/template', ImportTypes.IMPORT_MY_GTINS], {relativeTo: this.route});
    return;
  }

  public editSelectedProduct(product: Product) {
    let productToEdit = product;

    if (productToEdit === undefined) {
      const selectedProducts = this.getSelectedProducts();
      if (selectedProducts.length !== 1) {
        return;
      }
      productToEdit = selectedProducts[0];
    }

    let routeName = productToEdit.completionLevel === 4 ? 'edition/packing' : 'edition/consumer';

    if (this.featureToggleSvc.canSeeVcs()) {
      routeName = productToEdit.completionLevel === 4 ? 'editionvcs/packing' : 'editionvcs/consumer';
    }
    this.router.navigate([routeName, productToEdit.id], {relativeTo: this.route.parent});
  }

  public viewSelectedProduct(product: Product) {

    let productToEdit = product;

    if (productToEdit === undefined) {
      const selectedProducts = this.getSelectedProducts();
      if (selectedProducts.length !== 1) {
        return;
      }
      productToEdit = selectedProducts[0];
    }
    if (!this.featureToggleSvc.canSeeModeView()) {
      this.editSelectedProduct(product);
      return;
    }
    const routeName = './product/view';

    this.router.navigate([routeName, productToEdit.id], {relativeTo: this.route.parent});
  }

  public generateCode() {
    const selectedProducts = this.getSelectedProducts();
    if (selectedProducts.length !== 1) {
      return;
    }

    this.barcodeGenerationSvc.generateBarcode(selectedProducts[0].id);
  }

  public flagImportant(product: Product) {
    const isImportant = product.important ? !product.important : true;
    this.productsSvc.updateProductImportant(product.id, isImportant)
      .subscribe(
        updatedProduct => product.important = updatedProduct.important,
        err => console.error(err)
      );
  }

  public exportSelectedProducts() {
    const selectedProducts = this.getSelectedProducts();
    this.productExportModal.show(selectedProducts);
  }

  public importProducts() {
    if (!this.companySvc.company.id) {
      return this.notifSvc.companyMissing();
    }
    if (this.featureToggleSvc.canSeeNewImportWay()) {
      this.router.navigate(['../import/template', ImportTypes.IMPORT_MY_GTINS], {relativeTo: this.route});

      return;
    }
    this.router.navigate(['../import/upload'], {relativeTo: this.route});
  }

  public downloadImportTemplate() {
    if (!this.companySvc.company.id) {
      return this.notifSvc.companyMissing();
    }
    this.router.navigate(['../import/template', ImportTypes.DEFAULT], {relativeTo: this.route});
  }

  public importProductsModal() {
    this.productImportModal.show();
  }

  public onSearchChange(text: string) {
    this.searchProductStream.next(text);
  }

  public searchProducts(text: string) {
    if (text === this.previousSearchText) {
      return;
    }

    this.previousSearchText = text;

    this.selectAllProducts(false);

    if (_.isEmpty(text)) {
      this.getProducts();
      return;
    }

    this.productsSvc.searchProducts(text)
      .subscribe(
        () => document.getElementById('DashboardProducts-list-content')!.scrollTop = 0,
        err => err
      );
  }

  public getNextProducts() {
    if (!this.loadingNextProducts && !this.noMoreProductsToLoad && this.searchText.length === 0) {
      this.loadingNextProducts = true;
      this.offset += this.length;
      this.productsSvc.getCustomerProducts(this.offset, this.length)
        .subscribe(
          result => {
            result.products.map(product => this.productsSvc.myProducts.push(product));
            this.noMoreProductsToLoad = this.productsSvc.sortConf.field === '' ? this.productsSvc.lastEvaluatedKey === undefined : result.products.length === 0;
            this.loadingNextProducts = false;
          },
          error => this.loadingNextProducts = false
        );
    }
  }

  public sortList(field: string) {
    this.offset = 0;
    this.noMoreProductsToLoad = false;

    this.selectAllProducts(false);

    return this.productsSvc.sortList(field)
      .subscribe(
        () => document.getElementById('DashboardProducts-list-content')!.scrollTop = 0,
        err => err
      );
  }

  public hasCodeLimitAlmostFull() {
    return this.productsSvc.productsLoaded && this.productsSvc.totalProducts / this.companySvc.company.codesAvailable >= 0.9 && this.productsSvc.totalProducts < this.companySvc.company.codesAvailable;
  }

  public hasCodeLimitFull() {
    return this.productsSvc.productsLoaded && this.productsSvc.totalProducts >= this.companySvc.company.codesAvailable;
  }
  public updateProducts() {
    if (this.productsSvc.myProducts.length < 1) {
      return;
    }
    this.router.navigate(['dashboard/import/template/', ImportTypes.UPDATE_DATA_BASE]);
    return;
  }

  public clickCopyGtin(event, button: HTMLButtonElement) {
    this.copyGtinToolTip = this.constants.copiedGtinLabel;
    setTimeout(() => {
      button.blur();
      button.focus();
    }, 100);
    setTimeout(() => {
      this.copyGtinToolTip = this.constants.copyGtinLabel;
      button.blur();
    }, 1000);
    event.stopPropagation();
  }

  public onCloseExportAction() {
    this.selectAllProducts(false);
  }

  public async openUpdateGpcPopup() {
    this.productExportModal.show([], true);
  }

  public updateCategory(e: MouseEvent, product) {
    if (!product.oldCategory) return;
    e.stopPropagation();
    const routeName = './product/update-category';
    this.router.navigate([routeName, product.id], {relativeTo: this.route.parent});
  }
  public redirectToImport(){
    this.productsSvc.getImportProduct().subscribe((imp) => {
      if (!imp) {
        this.import = imp;
        return;
      }
      const routeName = './dashboard/import/status/';
      this.router.navigate([routeName, imp.id], {queryParams: {lineCount: imp.lineCount, fileName: imp.fileName, importType: imp.importType}});
      return;
    });
  }
  private displayloader() {
    this.import = null;
    this.productsSvc.getImportProduct().subscribe((imp) => {this.import = imp; });
  }
}
